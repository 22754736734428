/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/
.applicable-activities-list-table {
  margin-top: 0.625rem;

  .is-sortable:hover {
    cursor: pointer;
  }

  .is-sortable .icon {
    margin-left: 0.5rem;
  }

  .is-sortable svg {
    fill: var(--rootkit__palette--gray300);
    stroke: var(--rootkit__palette--gray300);
  }
}

/* makes last column (options menu) always in view */
.applicable-activities-list-table .rootkit__table__header:last-child,
.applicable-activities-list-table .rootkit__table__cell:last-child {
  position: sticky;
  right: 0;
  z-index: 4;
  background-color: var(--rootkit__palette--white);
  min-width: 5rem;
}

/* align menu button with right edge of table */
.applicable-activities__menu-button {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.applicable-activities__menu {
  margin: 1rem;
  padding: 0;
}

/* removes bullet from li styling */
.applicable-activities__menu-item-wrapper {
  @include rootkit__list-reset;
  padding: 0.5rem 1rem;
  color: var(--rootkit__palette--gray600);

  &:hover {
    cursor: pointer;
    background-color: var(--rootkit__palette--gray100);
  }
}

/* prep the menu item to display icon alongside text */
.applicable-activities__menu-item {
  align-items: center;
  display: inline-flex;
}

.applicable-activities__menu-item-wrapper--disabled {
  color: var(--rootkit__palette--gray400);

  &:hover {
    cursor: not-allowed;
    background-color: unset;
  }
}

.applicable-activities__menu-item-icon {
  display: inline-flex;
  margin-right: 0.625rem;
}

.applicable-activities__menu-item-icon--pending-authorization {
  fill: var(--rootkit__palette--yellow700);
}

.applicable-activities__menu-item-text {
  display: inline-flex;
}

.applicable-activities__menu-item-text--disabled {
  color: var(--rootkit__palette--gray400);
}

/* this must be applied AFTER any cursor styling so as not to override cursor not allowed */
.applicable-activities__menu-item--pointer-events-none {
  pointer-events: none;
}

.cells__authorization {
  line-height: 1.75rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.cells__authorization-icon {
  display: inline-flex;
  margin-right: 0.375rem;
}

.cells__authorization-text {
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;

  &:hover {
    cursor: default;
  }
}

.cells__authorization-icon--pending-authorization {
  fill: var(--rootkit__palette--yellow700);
}
