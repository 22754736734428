.details-page--report {
  .detail-header {
    .report-date-range {
      margin: 0;
    }
  }

  p {
    margin-top: 0;
    line-height: 24px;
  }
}

.report-sub-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .report-download {
    align-self: flex-end;
    margin-bottom: map-get($spacer-map, 2);
  }
}

.report-title {
  display: flex;
  width: 60%;

  .report-logo {
    width: 120px;
    height: 120px;
  }

  .report-title-text {
    margin-left: 24px;

    .title {
      margin-bottom: 0;
    }

    .sub-text {
      margin-top: 0;
    }
  }
}

.report-accordion {
  border: solid 1px var(--rootkit__palette--gray200);
  box-shadow: none;

  .accordion-card-primary {
    background-color: var(--rootkit__palette--gray200);
    padding: 0 12px;
  }

  .accordion-card-secondary {
    .accordion-section {
      padding: 0 40px;
    }
  }

  .accordion-card.target {
    .accordion-card-primary {
      background-color: var(--rootkit__palette--white);
      padding: 12px;
    }
  }

  .accordion-card-meta {
    box-shadow: none;
  }

  &.is-expanded {
    box-shadow: none;
  }
}

.exec-summary-body {
  display: flex;
  flex-direction: column;

  .stat-header {
    margin-bottom: 0;
  }

  .stat-description {
    color: $rootkit__color-grey;
  }

  .target-snapshot {
    margin-bottom: map-get($spacer-map, 2);

    @include desktopM {
      max-width: 50%;
    }

    .stat-boxes {
      display: flex;

      .stat-box {
        flex: 1;
      }
    }
  }

  .prioritization-matrix-snapshot {
    .risk-matrix {
      .risk-matrix-x-label,
      .risk-matrix-y-label {
        background-color: transparent;
      }

      .risk-matrix-spacer,
      .risk-matrix-column-labels,
      .risk-matrix-row-labels {
        background-color: var(--rootkit__palette--white);
      }
    }
  }
}

@media (min-width: #{$breakpoint-xl}) {
  .exec-summary-addl--flex {
    display: flex;

    > div {
      width: 50%;
    }

    > div:first-child {
      margin-right: 40px;
    }
  }
}

.details-page--report .new-target-banner {
  width: 50px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: $rootkit__color-red;
  background-color: $rootkit__color-light-red;
}

@import './report-components/attack-surface-info.styles';
@import './report-components/exec-summary-info.styles';
@import './report-components/glossary.styles';
@import './report-components/high-priority-target-info.styles';
@import './report-components/prioritization-matrix.styles.scss';
@import './report-components/report-link.styles.scss';
@import './report-components/stats-delta.styles';
@import './report-components/stats-summary.styles';
@import './report-components/target-age-matrix.styles.scss';
@import './report-components/peer-stats.styles.scss';
@import './report-components/activities/activities.styles.scss';
@import './print.styles.scss';
