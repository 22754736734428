.stat-delta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: map-get($spacer-map, 1);
  min-width: 60px;
  height: 30px;
  border-radius: 2px;

  &.up {
    background-color: $rootkit__palette--red100;
    color: $rootkit__color-red;
    border: 1px solid $rootkit__palette--red100;

    .icon {
      svg {
        stroke: $rootkit__color-red;
        fill: $rootkit__color-red;

        path {
          // @TODO: need to remove inline stroke from the arrow icon in rootkit
          stroke: $rootkit__color-red;
        }
      }
    }
  }

  &.down {
    background-color: $rootkit__color-light-green;
    color: $rootkit__color-green;
    border: 1px solid $rootkit__color-light-green;

    .icon {
      svg {
        stroke: $rootkit__color-green;
        fill: $rootkit__color-green;

        path {
          // @TODO: need to remove inline stroke from the arrow icon in rootkit
          stroke: $rootkit__color-green;
        }
      }
    }
  }

  &.no-change {
    background-color: var(--rootkit__palette--gray100);
    border: 1px solid var(--rootkit__palette--gray300);
    color: var(--rootkit__palette--gray500);
  }
}

.stat-delta-description {
  max-width: 350px;
  color: $rootkit__color-grey--light;
}
