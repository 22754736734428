.target-thumbnail-container {
  position: relative;
  max-width: fit-content;
}

.target-thumbnail {
  width: 82px;
  border: 1px solid var(--rootkit__palette--gray200);

  &:hover {
    cursor: pointer;
  }

  &.target-thumbnail--no-image {
    position: relative;
  }
}

.badge {
  font-size: 12px;
  line-height: 12px;
  color: var(--rootkit__palette--white);
  background-color: $rootkit__color-red;
  height: 20px;
  width: fit-content;
  min-width: 20px;
  border-radius: 100px;
  text-align: center;
  padding: 4px;
  position: absolute;
  top: -8px;
  right: -10px;
}

.empty-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 50px;
  background-color: var(--rootkit__palette--gray200);

  .empty-image-message {
    font-size: 0.5rem;
  }
}
