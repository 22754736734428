.navigation-org-name {
  h2 {
    margin: 0;
    font-size: 20px;
  }

  .loader {
    margin-top: -2px;
  }

  .inner {
    display: block;
    margin: 10px 16px 4px;
  }
}

.navigation-org-name-collapsed {
  display: none;
}

.org-switcher {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.org-picker-label {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  color: $rootkit__color-blue;
}

.org-switcher__control {
  width: 100%;
  background-color: $rootkit__color-grey--dark !important;
  border: 0 !important;
  padding: 0 14px;

  &.org-switcher__control--is-focused {
    box-shadow: none;
  }
}

.org-switcher__input,
.org-switcher__single-value {
  color: var(--rootkit__palette--white) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  cursor: pointer;

  input {
    color: var(--rootkit__palette--white) !important;
    font-family: $rootkit__font--heading;
  }
}

.org-switcher__value-container {
  padding: 0;
}

.org-switcher__menu {
  background-color: $rootkit__color-grey--darker !important;
  border-radius: 0 !important;
}

.org-switcher__option {
  padding: 8px 16px;
  background-color: $rootkit__color-grey--darker !important;
  font-size: 14px;
  opacity: 0.3;

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 1;
  }
}

.org-switcher__option--is-focused {
  opacity: 1;
}

.org-switcher__indicators {
  cursor: pointer;
}

.org-switcher__indicator {
  color: var(--rootkit__palette--white) !important;
}

.org-switcher__indicator-separator {
  display: none;
}
