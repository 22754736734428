@use '@randori/rootkit/src/styles/mixins/drop-shadow';

.modal-inspect-pane {
  --header-height: 100px;

  @include drop-shadow.rootkit__drop-shadow();

  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: var(--rootkit__palette--gray100);
  z-index: 999;

  .entity-detail__primary > .rootkit__grid-container__wrapper {
    margin-bottom: 3.5rem;
  }

  .entity-navigation {
    border: none;
  }
}

.modal-inspect-pane__navigation__config {
  flex: 0 0 var(--header-height);
  border-bottom: 1px solid var(--rootkit__palette--gray300);
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .button-group {
    width: initial;
  }

  .button-group:last-child {
    margin-left: auto;
  }

  .modal-inspect-pane--l-right & {
    padding-left: 0.5rem;
  }
}

.modal-inspect-pane__navigation__content {
  position: relative;
  flex: 1 1 auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 0.5rem;
  border-right: 1px solid var(--rootkit__palette--gray300);
}

.entity-pane__actions {
  display: flex;
  padding: 0 1rem;

  margin-left: auto;

  .entity-detail__action-list {
    @include rootkit__list-reset;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .entity-detail__action-list__item {
    margin-left: 0.5rem;
  }

  .modal-inspect-pane--l-right & {
    padding: 0 0 0 1rem;
  }
}

.modal-inspect-pane--l-bottom {
  bottom: 0;
  width: 100vw;
  height: 40rem;
  max-height: 50vh;

  border-top: 1px solid var(--rootkit__palette--gray300);
}

.modal-inspect-pane--l-right {
  top: 0;
  right: 0;
  width: 50vw;
  min-width: 650px;
  height: 100vh;

  .entity-navigation__label,
  .entity-navigation__item--nested {
    display: none;
  }

  .entity-navigation svg.entity-detail--nav-icon {
    margin-right: 0;
  }

  @media only screen and (max-width: 650px) {
    min-width: 100vw;
  }

  .attack-actions-header,
  .runbook-action {
    .accordion-card-cell {
      flex: 1 0 50px;

      &:first-child {
        flex: 3 0 50px;
        overflow: hidden;
      }
    }
  }
}

.modal-inspect-pane__primary {
  display: flex;
  justify-content: space-between;

  .modal-inspect-pane--l-bottom & {
    flex-direction: row-reverse;

    .modal-inspect-pane__navigation__config {
      flex: 0 0 325px;
      border-right: 1px solid var(--rootkit__palette--gray300);
    }
  }

  .modal-inspect-pane--l-right & {
    .entity-pane__title__text-wrapper {
      width: 100%;
    }

    .entity-pane__actions {
      margin-left: auto;
    }
  }
}

.modal-inspect-pane__secondary {
  display: flex;
  overflow: auto;

  .modal-inspect-pane--l-bottom & {
    .modal-inspect-pane__navigation__content {
      flex: 0 0 325px;
      border-right: 1px solid var(--rootkit__palette--gray300);
    }
  }

  .modal-inspect-pane--l-right & {
    .modal-inspect-pane__navigation__content {
      flex: 0 0 86px;
    }
  }
}

.modal-inspect-pane__header {
  border-bottom: 1px solid var(--rootkit__palette--gray300);
  display: flex;
  flex: 1 0 var(--header-height);
  padding: 1rem 0;
}

.modal-inspect-pane__content {
  position: relative;
  flex: 1 1 auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1.5rem;
  background-color: var(--rootkit__palette--white);
}

.entity-pane__title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
}

.entity-pane__title__thumbnail {
  margin-right: 1rem;

  .target-thumbnail {
    width: 82px;
    border: 1px solid rgba(var(--rootkit__palette--gray400), 0.5);
  }
}

.entity_pane__title__name {
  @include rootkit__typography--h3;

  color: var(--rootkit__palette--gray700);
  margin: 0;
}

.entity_pane__title__id {
  @include rootkit__typography--caption;

  margin: 0.25rem 0;
  color: var(--rootkit__palette--gray500);

  &:hover {
    cursor: pointer;
  }
}

.entity-pane__title-id--no-link {
  &:hover {
    cursor: unset;
  }
}

.entity-pane__actions-list {
  @include rootkit__list-reset;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.inspect-pane__config__actions {
  > .render-popper {
    margin-right: 0.5rem;
  }
}
