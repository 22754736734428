.entity-table-description {
  font-size: 12px;
  line-height: 1.33;
  color: $rootkit__color-grey--light;
  padding: 10px 0 0;

  &-inner {
    padding-bottom: 20px;
  }

  p {
    margin: 0;
  }
}
