.runbook-desc-section-label {
  @include entity-info-item-label;

  margin-right: 0.5rem;
}

.runbook-section-label {
  @include entity-info-item-label;

  display: block;
}

.runbook-links-label {
  @extend .runbook-section-label;
}

.action-dst-list-item-label {
  @include entity-info-item-label;

  margin-right: 0.5rem;
}

.runbook-meta {
  .icon svg {
    fill: $rootkit__color-grey--light;
    stroke: $rootkit__color-grey--light;
  }
}

.runbook {
  @include entity;

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
      stroke: $rootkit__color-grey--light;
    }
  }
}

// @TODO: Move this back into rootkit when we're revising *all* cards
// @see: https://css-tricks.com/flexbox-truncated-text/

.runbook .accordion-card-cell {
  // @TODO: Add min-width: 0 to rootkit component
  min-width: 0;
  white-space: normal;
  word-wrap: break-word;
  display: flex;
}

.runbook-header .accordion-card-cell {
  // @TODO: Add min-width: 0 to rootkit component
  min-width: 0;
}

.runbook-info {
  @include entity-info;
}

.runbook-info-item {
  a {
    @include link-reset;
  }
}

.runbook-desc,
.runbook-objective,
.runbook-perspective {
  .runbook-info-item {
    @include entity-info-item(1);
  }
}

.runbook-originations {
  flex-direction: column;
}

.runbook-destinations {
  flex-direction: column;
}

.runbook-links {
  margin-bottom: 2rem;
}

.runbook-desc-section-copy {
  width: 100%;
}

.mitre-techs {
  .mitre-techs-list {
    @include rootkit__list-reset;
  }

  .mitre-techs-list-item {
    display: inline-block;
    margin-right: 0.75rem;
  }

  .mitre-tech-link {
    color: $rootkit__color-grey;

    &::after {
      margin: 0 0 0 3px;
      background-size: 10px 10px;
      background-position-y: 2px;
      width: 10px;
    }
  }
}

.runbook-action {
  .entity-src {
    width: max-content;
  }
}

// attack-action
// ---------------------------------------------------------------------------

.runbook-actions-heading {
  margin: 0 24px 12px;
  padding: 12px 0;
  border-bottom: 1px solid $rootkit__color-blue--lighter;

  h2 {
    margin: 0 24px;
  }
}

.attack-action-section {
  margin: 0 24px;

  &:first-child {
    padding-top: 24px;
  }

  &:last-child {
    padding-bottom: 24px;
  }
}

.attack-action-section-label {
  @include entity-info-item-label;
}

.action-src-list {
  @include rootkit__list-reset;

  a {
    @include link-reset;
  }

  > li {
    > span {
      font-weight: 700;
    }
  }
}

.attack-action-desc {
  p {
    margin: 0;
  }
}

.attack-action-desc,
.attack-action-src,
.attack-action-dst {
  margin-bottom: 2rem;

  .attack-action-section-label {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.action-dst-list {
  @include rootkit__list-reset;

  a {
    @include link-reset;
  }

  > li {
    > span {
      font-weight: 700;
    }
  }
}

.runbook-actions-pager {
  margin: 0 24px 9px;
}

.src-container,
.dst-container {
  align-items: flex-end;
}

.dst-list,
.src-list {
  @include rootkit__list-reset;

  min-width: 0;

  > li {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.dst-addl-popper,
.src-addl-popper {
}

.dst-addl,
.src-addl {
  flex-shrink: 0;
  padding-left: 0.5rem;
  font-weight: 700;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.name-container {
  flex-direction: column;
}

.runbook-name {
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  a:link,
  a:visited {
    color: initial;
  }
}

.runbook-instance-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.r-popper {
  .dst-addl-label,
  .src-addl-label {
    font-size: 14px;
    margin-bottom: 0.75rem;
  }

  .dst-addl-list,
  .src-addl-list {
    @include rootkit__list-reset;

    font-size: 12px;
  }

  li {
    line-height: 1.5rem;
  }
}

.attack-actions-header {
}

.runbook-action-bar {
  // @TODO: Make an action-bar module
  @extend .service-action-bar;
}

.runbook-action-bar-label {
  @extend .service-action-bar-label;
}

.runbook-action-bar-actions {
  @extend .service-action-bar-actions;
}

.attack-action-action-bar-wrapper {
  background-color: $rootkit__color-grey--lighter;
  padding: 9px 24px 24px;
}

.attack-action-action-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  padding: 12px 24px;
  background-color: var(--rootkit__palette--white);
}

.attack-action-action-bar-label {
  @extend .service-action-bar-label;
}

.attack-action-action-bar-actions {
  @extend .service-action-bar-actions;
}

.runbook-randori-notes {
  .textarea {
  }

  textarea {
    resize: none;
  }
}
