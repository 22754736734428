.categories-tags-container {
  position: relative;
  width: 100%;
  /* TODO: Carbon - colors ditch opacity and directly access carbon blue palette from dark to light here? */
  /* updated to use rgba values equivalent to Carbon blue 60 */
  --opacity-level-0: rgba(15, 98, 254);
  --opacity-level-1: rgba(15, 98, 254, 0.8);
  --opacity-level-2: rgba(15, 98, 254, 0.7);
  --opacity-level-3: rgba(15, 98, 254, 0.6);
  --opacity-level-4: rgba(15, 98, 254, 0.5);
  --opacity-level-5: rgba(15, 98, 254, 0.4);
  --opacity-level-6: rgba(15, 98, 254, 0.3);
  --opacity-level-7: rgba(15, 98, 254, 0.2);
  --opacity-level-8: rgba(15, 98, 254, 0.15);
  --opacity-level-9: rgba(15, 98, 254, 0.1);
}

.categories-tags-header {
  position: absolute;
  display: flex;
  padding-bottom: 1rem;

  > h2 {
    margin: 0;
  }

  .info-popper {
    margin: 0 6px;
    z-index: 1;
  }
}

ul.category-tabs {
  margin-bottom: 2rem;
  padding-right: 15px;
  justify-content: flex-end;

  > li {
    height: 36px;
    overflow: hidden;
    padding: 0 10px;
    background: var(--rootkit__palette--gray100);
    color: $rootkit__color-grey--darker;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > li[aria-selected='true'] {
    background: var(--rootkit__palette--white);
  }
}

.graph-table-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.table-container {
  width: 80%;
  height: 200px;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    color: $rootkit__color-grey--light;
    font-family: $rootkit__font--heading;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
  }

  a {
    font-family: $rootkit__font--heading;
    color: $rootkit__color-grey--darker;
    text-decoration: underline;
  }

  th,
  td {
    text-align: center;
    width: 20%;
    border: none;
  }

  .legend-field {
    width: 25px;
    display: flex;
    justify-content: center;
    margin: 11px 0 0 10px;
  }

  .legend {
    width: 17px;
    height: 17px;
    border: 1px solid $rootkit__color-grey--light;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .tag-name {
    text-align: left;
    width: 35%;
    padding-left: 5px;
    text-decoration: none;
    font-weight: bold;
    word-break: break-all;
  }

  tr {
    height: 40px;
  }

  tr:nth-child(2n) {
    background: $rootkit__color-grey--lighter;
  }
}

.categories-tags-tab-container,
.categories-tech-tab-container {
  height: 295px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .pager {
    padding: 0;
  }

  .pager-list {
    justify-content: flex-end;
  }
}

.pie-chart-placeholder {
  height: 200px;
  min-width: 200px;
  background-color: $rootkit__color-grey--lighter;
  margin-right: 40px;
  text-align: center;
  line-height: 200px;
}

.opacity-level-0 {
  background-color: var(--opacity-level-0);
}

.opacity-level-1 {
  background-color: var(--opacity-level-1);
}

.opacity-level-2 {
  background-color: var(--opacity-level-2);
}

.opacity-level-3 {
  background-color: var(--opacity-level-3);
}

.opacity-level-4 {
  background-color: var(--opacity-level-4);
}

.opacity-level-5 {
  background-color: var(--opacity-level-5);
}

.opacity-level-6 {
  background-color: var(--opacity-level-6);
}

.opacity-level-7 {
  background-color: var(--opacity-level-7);
}

.opacity-level-8 {
  background-color: var(--opacity-level-8);
}

.opacity-level-9 {
  background-color: var(--opacity-level-9);
}

.categories-tags-pie-chart {
  margin-right: 45px;
  max-height: 200px;
}

.tags-empty-state {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 300px;
  /* Note: Carbon - leaving as is in order to account for opacity */
  background-color: rgba($color: #fff, $alpha: 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    width: 50%;
    min-width: 525px;
  }
}
