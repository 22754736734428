.perspective-field__label {
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;

  > .icon {
    margin-left: 0.5rem;
  }
}

.perspective-field__desc {
  color: $rootkit__color-grey;
  margin-top: 0;
}

.perspective-field__select {
  width: 100%;

  .icon:last-child {
    margin-left: auto;
  }
}

.perspective-field__select__dropdown {
  max-height: fit-content;
}

.perspective-field__create {
  display: flex;

  > .input {
    flex: 1;
  }

  > .button {
    margin-left: 0.5rem;
  }
}

.perspective-field__select__trigger {
  width: 100%;

  &--error {
    color: $rootkit__color-red;
    border-color: $rootkit__color-red;
    background-color: rgba(218, 2, 27, 0.15);
  }
}

.perspective-field__name--error {
  color: $rootkit__color-red;
}
