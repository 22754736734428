.tech-tab-table {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 80%;
  height: 200px;
  margin: 15px 0;
}

.tech-category-item {
  padding: 0 15px;
  height: 40px;
  width: 45%;
  min-width: 200px;

  &:nth-child(5n),
  &:nth-child(5n + 1),
  &:nth-child(5n + 3) {
    background: $rootkit__color-grey--lighter;
  }

  &.full-width {
    width: 100%;
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $rootkit__font--heading;
    color: $rootkit__color-grey--darker;
    text-decoration: none;
    height: 100%;
  }

  .legend {
    margin-right: 10px;
  }

  .tag-name {
    flex: 3 1 0;
  }

  .tech-value {
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.5px;
    align-self: flex-end;
    margin: 0;
  }
}
