.external-link {
  @include r-link($underline: false);

  &::after {
    display: inline-block;
    position: relative;
    content: '';
    background-image: url('../static/images/svg-out/external-link.svg');
    background-size: 20px 20px;
    height: 1em;
    width: 20px;
    margin: 0 10px;
    background-repeat: no-repeat;
    background-position-y: 0.5em;
  }

  &.no-icon {
    &::after {
      display: none;
    }
  }
}
