.impact-score-label {
  display: flex;
  align-items: center;

  .impact-score {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    // margin-right: 5px;
  }

  &.none {
    .impact-score {
      background-color: $randori-no-impact;
    }
  }

  &.low {
    .impact-score {
      background-color: $randori-low-impact;
    }
  }

  &.medium {
    .impact-score {
      background-color: $randori-medium-impact;
    }
  }

  &.high {
    .impact-score {
      background-color: $randori-high-impact;
    }
  }

  .impact-score-text {
    &.None {
      opacity: 0.5;
    }
  }
}

.impact-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.impact-info:not(:last-child) {
  margin-right: 0.5rem;
}

.impact-info-factor--low {
  transform: rotate(-180deg);

  path {
    fill: $rootkit__color-grey;
  }
}

.impact-info-factor--medium {
  path {
    fill: $rootkit__color-orange;
  }
}

.impact-info-factor--high {
  path {
    fill: $rootkit__color-red;
  }
}

.impact-info-content--none,
.impact-info-content-score--none {
  color: var(--rootkit__palette--gray700);
}

.impact-info-content--low,
.impact-info-content-score--low {
  color: var(--rootkit__palette--gray700);
}

.impact-info-content--medium,
.impact-info-content-score--medium {
  color: $randori-medium-impact;
}

.impact-info-content--high,
.impact-info-content-score--high {
  color: $randori-high-impact;
}

.impact-dropdown-trigger {
  line-height: initial;
}

.impact-dropdown-content {
  padding: 16px;
}

.impact-dropdown-trigger .button-children {
  display: flex;
  align-items: center;

  .icon-spinner {
    width: 16px !important;
    height: 16px !important;
  }
}

.impact-dropdown-trigger-label {
  height: 16px;
}

.impact-options {
  @include rootkit__list-reset;
}

.impact-option {
  padding: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &.is-active {
    background-color: $rootkit__color-grey--lighter;
  }
}

.impact-option-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.impact-option--none {
  .impact-option-indicator {
    background-color: $randori-no-impact;
  }
}

.impact-option--low {
  .impact-option-indicator {
    background-color: $randori-low-impact;
  }
}

.impact-option--medium {
  .impact-option-indicator {
    background-color: $randori-medium-impact;
  }
}

.impact-option--high {
  .impact-option-indicator {
    background-color: $randori-high-impact;
  }
}

.impact-options-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.impact-options-actions > button:not(:last-child) {
  margin-right: 0.5rem;
}
