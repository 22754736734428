@use '@randori/rootkit/src/styles/functions/color' as rcolor;

:root {
  --toastify-text-color-light: var(--rootkit__palette--white);
}

$auth-box-width: 900px;

.flex-column {
  flex-flow: column;
}

.flex-row {
  flex-flow: row;
}

.center-content {
  justify-content: center;
  align-items: center;
}

.button-icon {
  padding: 4px 10px;

  .icon {
    margin-top: 3px;
  }
}

.auth-link {
  cursor: pointer;
  color: var(--rootkit__palette--white);
  text-decoration: none;
  background-color: inherit;

  &:focus,
  &:hover {
    color: rcolor.lightness(var(--rootkit__palette--white), 1.1);
    text-decoration: underline;
  }
}

.logout-link {
  cursor: pointer;
}

.form-checkbox--labeled {
  display: flex;
  margin-bottom: 2rem;

  label {
    flex: 1;
  }
}

.modal-trigger {
  cursor: pointer;
  text-decoration: underline;
}

.box-height--500 {
  overflow: hidden;
}

.bg--white {
  background: var(--rootkit__palette--white);
}

.l-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.auth-box {
  width: $auth-box-width;
  height: 600px;
}

.auth-growler-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 10px;
  width: 100%;
}

.box-link {
  border-top: 1px solid $rootkit__color-blue--lighter;
  text-align: center;

  > a {
    @include r-link;

    display: block;
    padding: 20px 20px 0;
    width: 100%;
    font-size: 16px;
  }
}

.notifications-badge {
  position: relative;

  .notifications-badge-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -6px;
    top: -6px;
    background: $rootkit__color-red;
    border-radius: 7px;
    font-size: 10px;
  }
}

.auth-growler {
  top: 10px;
  max-width: $auth-box-width;

  .growler {
    text-align: left;
  }
}

.modal-dialog {
  z-index: $z-foreground + 1;
}

.modal-overlay {
  z-index: $z-foreground - 1;
}

.dropdown-content {
  .button {
    width: 100%;
  }
}

// @TODO: Move input--disabled cursor style to @randori/rootkit
.input--disabled {
  &:hover {
    cursor: not-allowed !important;
  }

  input {
    &:hover {
      cursor: not-allowed !important;
    }
  }

  label {
    &:hover {
      cursor: not-allowed !important;
    }
  }
}

.pill {
  white-space: nowrap;
}

.loader--small {
  display: inline;

  // @TODO: Add `size` props to <Loader /> in rootkit
  > .icon {
    width: 14px !important;
    height: 14px !important;
  }
}

// @TODO: This should be a encapsulated component
//
// This appears in many of our entity lists
.characteristics-overflow-value {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.characteristics-overflow-values {
  @extend .characteristics-overflow-value;
}

.column-tags {
  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.input-leftIcon--search--small {
  top: 55% !important;
}

.entity-name,
.runbook-name {
  font-size: 0.875rem;
  overflow: hidden;
}

.dropdown-new-option {
  .button--tertiary:hover {
    background-color: var(--rootkit__palette--gray100);
    border-color: var(--rootkit__palette--gray100);
  }
}

.modal-dialog__actions {
  .button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .pager {
    padding: 0;
    margin: 0 auto;
  }
}

.modal-slideout__actions {
  .button:last-of-type {
    margin-right: 0;
  }
}

.modal-inspect-pane__table-overflow-context {
  overflow-x: auto;
}

.modal-inspect-pane__table {
  .rootkit__table__cell.icon-only {
    min-width: var(--min-cell-width);
    text-align: center;
  }
}
