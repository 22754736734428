.nav-popper {
  position: relative;

  // height: 36px;
  // padding: 10px;
  // border-radius: 5px;
  // border: 1px solid;
  background-color: var(--rootkit__palette--white);
  z-index: $z-foreground;
}
