// ---------------------------------------------------------------------------

.api-tokens-form {
  margin: 1rem 0;

  .button-group {
    margin-top: 1rem;
  }
}

.token-roles-section {
  padding-top: 10px;
  padding-bottom: 5px;
}

.token-roles-select-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// ---------------------------------------------------------------------------

.copy-token {
  // display: block
}

.copy-token-leader {
}

.copy-token-display-wrapper {
  margin: 1rem 0;
}

.copy-token-display {
  opacity: 0.5;
}

.copy-token-feedback {
  display: flex;
  margin: 0;
  margin-left: auto;

  .icon {
    margin-left: 0.5rem;
  }
}

.copy-token-action {
}

.delete-api-header {
  display: flex;
  align-items: center;
  padding: 2rem;
  padding-top: 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid $rootkit__color-blue--lighter;

  .delete-api-title {
    display: flex;
    align-items: center;
    margin: 0;

    .icon-ribbon,
    .icon-cascade,
    .icon-trash {
      margin-right: 10px;

      svg path {
        stroke: $rootkit__color-grey--darker;
      }
    }

    .entity-type {
      font-weight: 300;
    }
  }
}

.delete-api-body {
  position: relative;
  padding: 0 2rem;
  margin-top: 10px;

  .delete-api-options {
    margin-top: 2rem;

    .button-group button {
      width: 50%;

      .button-flex {
        flex-direction: column;
      }
    }
  }
}

.delete-api-footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-top: 2rem;
  border-top: 1px solid $rootkit__color-blue--lighter;

  .button-group {
    width: auto;
  }
}

.delete-api-footer-delete {
  justify-content: flex-end;
}
