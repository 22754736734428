$source-pane-padding: 50px;

.button-dropdown--error {
  color: $rootkit__color-red;
  border-color: $rootkit__color-red;
  background-color: rgba(218, 2, 27, 0.15);
}

.perspective-dropdown {
  width: 100%;
  max-height: 200px;
}

.perspective-helptext {
  color: $rootkit__color-grey;
  font-size: 12px;
  margin: 10px 0;
}

.create-source-pane__icon {
  margin-left: -30px;
  margin-right: 10px;
}

.create-new-perspective {
  display: inline-flex;
  justify-content: space-between;
}

.create-new-perspective__input {
  margin-right: 16px;
  display: inline-flex;
  flex: 1;
}

.create-source-pane {
  width: 600px;
  overflow-y: hidden;

  .slideout-header {
    padding: 30px $source-pane-padding 0;
  }

  .slideout-body {
    display: flex;
    flex-direction: column;
    padding: 0 0 15px !important;
    overflow-y: hidden;

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .source-pane__title-row {
      padding: 0 15px 15px $source-pane-padding;
      border-bottom: 1px solid $rootkit__color-blue--lighter;
      display: flex;
      align-items: center;

      .title-text {
        margin: 0;
      }
    }

    .pane-body {
      flex: 1;
      overflow-y: auto;

      .icon-status--none svg {
        stroke: none;
        fill: $rootkit__color-red;
        opacity: 1;
      }

      .label-row {
        display: flex;
        align-items: center;
        height: 30px;

        .icon {
          margin-left: -30px;
          margin-right: 15px;
        }
      }

      .source-name {
        padding: 15px $source-pane-padding;
        border-bottom: 1px solid $rootkit__color-blue--lighter;
      }

      .source-creation-guide {
        display: flex;
        padding: 15px $source-pane-padding;
      }

      .source-implant-type,
      .source-os-type,
      .source-architecture,
      .source-perspective,
      .source-new-perspective {
        display: flex;
        flex-direction: column;
        padding: 15px $source-pane-padding;

        .dropdown-new {
          margin: 4px 0;

          .dropdown-new-content {
            width: 100%;

            .dropdown-new-options {
              padding: 0 0 15px;

              .dropdown-new-option {
                padding: 0.5em 15px;

                .input-radio {
                  margin: 0;
                }
              }
            }

            .list-divider {
              padding-top: 15px;
              position: relative;
              z-index: 1;

              &::before {
                border-top: 1px solid $rootkit__color-blue--lighter;
                content: '';
                margin: 0 auto;
                position: absolute;
                top: 70%;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                z-index: -1;
              }

              .line-text {
                color: $rootkit__color-grey;
                background: var(--rootkit__palette--white);
                padding: 0;
                margin-left: 15px;
              }
            }

            .option-title {
              padding: 20px 15px 10px;
              line-height: 1.5em;
              font-size: 12px;
              color: $rootkit__color-grey;
              text-transform: uppercase;
            }
          }

          .button {
            width: 100%;

            .button-flex {
              justify-content: space-between;
            }
          }
        }
      }

      .source-note {
        padding: 15px $source-pane-padding;
        border-bottom: 1px solid $rootkit__color-blue--lighter;

        .source-note-helptext {
          color: $rootkit__color-grey;
          font-size: 12px;
        }

        textarea {
          resize: vertical;
        }
      }
    }

    .source-footer {
      padding-top: 15px;
      border-top: 1px solid $rootkit__color-blue--lighter;

      .button-group {
        justify-content: flex-end;
      }
    }
  }
}

.source-field__name--error {
  color: $rootkit__color-red;
}

.source-field__dns-override--error {
  display: block;
  color: $rootkit__color-red;
}

.source-field__dns-override {
  display: block;
}

.source__external-link {
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  white-space: nowrap;

  &.external-link::after {
    background-size: 14px 14px;
    width: 14px;
    background-position-y: 0;
  }
}
