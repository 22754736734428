.bar-status {
  &--handled {
    color: $rootkit__color-orange;
  }

  &--pending {
    color: $rootkit__color-grey;
  }

  &--approved {
    color: $rootkit__color-green;
  }

  &--denied {
    color: $rootkit__color-red;
  }
}
