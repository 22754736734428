.mitre-leader__header {
  color: var(--rootkit__palette--gray500);

  padding: 0 24px;
}

.mitre-leader__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  width: calc(100% - 48px);
  background-color: var(--rootkit__palette--white);

  border: 1px solid var(--rootkit__palette--gray300);
  margin: 0 24px 24px 24px;
}

@media screen and (min-width: 1775px) {
  .mitre-leader__container {
    grid-template-columns: repeat(14, 1fr);
  }
}

@media screen and (max-width: 1775px) {
  .mitre-leader__container {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (max-width: 1075px) {
  .mitre-leader__container {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

.mitre-leader__tactic-category-container--color {
  text-align: center;
  padding: 5px;
  border-top: 5px solid var(--rootkit__palette--blue500);
  background-color: var(--rootkit__palette--blue100);
  word-wrap: normal;
  height: 50px;
  border-bottom: 1px solid var(--rootkit__palette--gray300);

  display: flex;
  align-items: center;
}

.mitre-leader__tactic-category-label {
  margin: 0px auto;
  max-width: 90px;
  line-height: 0.9rem;
}

.mitre-leader__tactic-category-value {
  text-align: center;
  margin: 5px;
}

.mitre-leader__tactic-category-container--active {
  box-shadow: 0 0 8px 0 rgba(12, 0, 51, 0.2);
  z-index: 2;

  .mitre-leader__tactic-category-container--color {
    background-color: var(--rootkit__palette--blue200);
  }

  .mitre-leader__tactic-category-label {
    font-weight: bold;
  }
}

.mitre-leader__tactic-category-container {
  flex: 1 0 50px;
  min-width: 100px;
  background-color: var(--rootkit__palette--white);

  cursor: pointer;

  &:nth-child(n + 2) {
    border-left: 1px solid var(--rootkit__palette--gray300);
  }

  &:nth-child(n + 3) .mitre-leader__tactic-category-container--color {
    border-top: 5px solid var(--rootkit__palette--red500);
    background-color: var(--rootkit__palette--red100);
  }

  &:hover {
    background-color: var(--rootkit__palette--gray100);

    .mitre-leader__tactic-category-container--color {
      background-color: var(--rootkit__palette--blue200);
    }

    &:nth-child(n + 3) .mitre-leader__tactic-category-container--color {
      background-color: var(--rootkit__palette--red200);
    }
  }
}

.mitre-leader__tactic-category-container:nth-child(
    n + 3
  ).mitre-leader__tactic-category-container--active {
  .mitre-leader__tactic-category-container--color {
    background-color: var(--rootkit__palette--red200);
  }
}
