.dashboard-favorite-saved-views {
  position: relative;

  .info-popper {
    align-self: flex-end;
  }

  .randori-favicon {
    width: 60px;
    height: 60px;
    background-image: url('../static/images/svg-out/randori-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .pane-tabs {
    padding-right: 15px;
  }

  .pane-tabs li {
    font-size: 14px;
    line-height: 36px;
    height: 36px;
    color: $rootkit__color-grey--darker;
    text-align: center;
    padding: 0 10px;
    background: var(--rootkit__palette--gray100);
    flex: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .pane-tabs li[aria-selected='true'] {
    background: var(--rootkit__palette--white);
  }

  .button {
    position: relative;
  }
}

.dashboard-favorite-saved-views-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.dashboard-favorite-saved-header {
  margin: 15px 0 25px;
}

.favorite-saved-views-list {
  padding: 20px 0;
  width: 90%;

  .empty-state {
    color: $rootkit__color-grey;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.5px;
    height: 100%;
  }
}

.favorite-saved-view-item-container {
  display: flex;

  .info-popper {
    align-self: center;
    margin: -5px;
    position: absolute;
    right: 8px;
  }
}

.favorite-saved-views-item {
  display: flex;
  justify-content: space-between;
  padding: 0 33px;
  line-height: 38px;
  font-size: 16px;
  border-radius: 3px;
  color: $rootkit__color-grey--darker;
  text-decoration: none;
  width: 100%;
  border-bottom: 1px solid rgba(211, 215, 219, 0.5);

  .favorite-saved-views-name {
    font-weight: 700;
    letter-spacing: 0.5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;
  }

  &.special-view {
  }

  .icon-spinner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
  }

  svg {
    height: 20px;
  }
}

.dashboard-favorite-saved-footer {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dashboard-favorite-saved-view-button {
  color: $rootkit__color-grey;
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.dashboard-favorite-saved-view-link {
  color: $rootkit__color-grey--light;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
}

.favorite-saved-view-image {
  margin: 20px 0;
  height: 60%;
  background-image: url('../static/images/svg-out/favorite-saved-view.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
}

.info-tooltip .special-view-tooltip-link {
  margin-right: -23px;
  margin-left: 4px;
}
