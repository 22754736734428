.add-network {
  --separator-color: var(--rootkit__palette--gray200);

  display: flex;
  flex-direction: column;
  height: 100%;

  label {
    text-transform: capitalize;
  }
}

.add-network__header {
  padding-bottom: 2rem;
}

.add-network__title {
  margin: 0 0 1rem 0;
  text-transform: capitalize;
}

.add-network__desc {
  margin: 0;
}

.add-network__body {
  flex: 1;
}

.add-network__form-section {
  padding: 2rem 0;

  border-top: 1px solid var(--separator-color);
}

.add-network__form__network-field__label {
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;
}

.add-network__form__network-field__desc {
  margin-top: 0;

  color: $rootkit__color-grey;
}

.add-network__form-actions {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 0;

  border-top: 1px solid var(--separator-color);

  .button--primary {
    margin-left: 0.5rem;
  }
}

.add-network__form__network-field__row {
  margin-bottom: 0.5rem;
}

.add-network__form__network-field__row_error {
  color: $rootkit__color-grey;
}

.add-network__form__network-field__actions {
  display: flex;
  justify-content: flex-end;
}
