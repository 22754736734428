@use '@carbon/react/scss/type';
@use '@carbon/react/scss/colors';

.dc-pill {
  @include type.type-style('label-02');

  display: inline-block;
  padding: 0.25rem 0.75rem;
  margin-left: 2px;

  background: colors.$gray-20;
  text-transform: uppercase;

  // hyphens: manual;
  white-space: nowrap;

  &:first-child {
    margin-left: 0px;
  }
}

.dc-pill__group {
  display: inline-flex;
}
