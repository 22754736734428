.runbook-src-dst {
  display: flex;
}

.runbook-src-dst__label {
}

.runbook-src-dst__values {
}

.runbook-src-dst__list {
  @include rootkit__list-reset;
}

.runbook-src-dst__list-item {
}

.modal-dialog.edit-runbook {
  min-height: 50vh;
  min-width: 50vw;

  .button-group {
    margin-top: 1rem;
    justify-content: flex-end;
  }
}
