.entity-relationship-selector {
  .dropdown-new-options {
    padding: 0 0 15px;

    .dropdown-new-option {
      padding: 0.5em 0;

      .input-radio label::after {
        top: 3px;
        left: 3px;
      }
    }
  }
}

.parent-selector {
  .accordion-card {
    margin: 0;
  }

  .accordion-card-meta--nested {
    box-shadow: none;
    padding: 12px;
  }

  .dropdown-new-options {
    padding: 0 0 15px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    .dropdown-new-option {
      padding: 0.5em 15px;
      width: 33%;

      .input-radio label::after {
        top: 3px;
        left: 3px;
      }

      &:nth-child(odd) {
        background-color: var(--rootkit__palette--white);
      }
    }
  }

  .parent-select-pane {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .parent-select-tabs {
      position: absolute;
      right: 0;
      bottom: -12px;

      .parent-select-tab {
        padding: 0.5rem 1rem;
        background-color: var(--rootkit__palette--gray300);
        border-radius: 0;
        border: none;
        color: $rootkit__color-grey--light;

        &:first-child {
          margin-right: 2px;
        }

        &--is-active {
          background-color: var(--rootkit__palette--gray100);
          color: var(--rootkit__palette--gray600);
        }
      }
    }
  }
}

.add-affiliation-modal {
  width: 90%;
  height: 90vh;
  padding: 0;
  max-width: 100%;
  max-height: 100vh;
  top: 5%;

  label {
    font-weight: 500;
  }

  .modal-close {
    right: 1rem;
    top: 1rem;
  }

  .modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .affiliation-workflow {
      width: 80%;
      margin-top: 20px;

      .affiliation-entity-picker {
        padding-top: 15px;

        .dropdown-new-options {
          padding: 0;

          .dropdown-new-option {
            .input-radio label::after {
              left: 3px;
            }
          }
        }
      }

      .affiliation-org {
        display: flex;
        flex-direction: column;
        padding: 15px 0;

        .affiliation-org-dropdown-btn {
          display: flex;
          align-items: center;
          cursor: pointer;
          border: 1px solid $rootkit__color-grey--lighter;
          border-radius: 3px;
          background: var(--rootkit__palette--white);
          padding: 0.5rem 1rem;
          margin-top: 0.5rem;

          .affiliation-org__control {
            width: 100%;
            border: 0;

            &.affiliation-org__control--is-focused {
              box-shadow: none;
            }
          }

          .affiliation-org__input,
          .affiliation-org__single-value {
            overflow: hidden;
            text-overflow: ellipsis;
            width: 95%;
            cursor: pointer;

            input {
              font-family: $rootkit__color-grey--darker;
            }
          }

          .affiliation-org__value-container {
            padding: 0;
          }

          .affiliation-org__menu {
            .affiliation-org__option {
              &:active,
              &:visited,
              &:hover,
              &:focus {
                color: var(--rootkit__palette--white);
                background-color: $rootkit__color-grey--darker;
              }
            }

            .affiliation-org__option--is-focused {
              color: $rootkit__color-grey--darker;
            }

            .affiliation-org__option--is-selected {
              background-color: $rootkit__color-grey--darker;
            }
          }

          .affiliation-org__indicators {
            cursor: pointer;

            .affiliation-org__indicator {
              color: $rootkit__color-grey--darker;
            }

            .affiliation-org__indicator-separator {
              display: none;
            }
          }

          .org-picker-label {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 10px;
            color: $rootkit__color-grey--darker;
          }
        }

        .dropdown-new {
          margin: 4px 0;

          .dropdown-new-content {
            width: 100%;
            max-height: 50vh;
            overflow-y: auto;

            .dropdown-new-options {
              padding: 0 0 15px;

              .dropdown-new-option {
                padding: 0.5em 15px;
              }
            }
          }

          .button {
            width: 100%;

            .button-flex {
              justify-content: space-between;
            }
          }
        }
      }

      .affiliation-entity {
        padding: 15px 0;
      }

      .affiliation-parent {
        padding: 15px 0;

        .affiliation-parent--help-text {
          width: 60%;
        }
      }
    }
  }

  .add-afiiliation-title {
    margin-left: 10px;
  }
}
