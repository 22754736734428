.randori-attack {
}

.contact-supp {
  font-size: 18px;

  a {
    @include r-link(var(--rootkit__palette--white));
  }
}

.attack-page-leader {
  grid-column: no-bleed;
  display: flex;
  justify-content: space-between;
  padding: 0 20px 20px 30px;
  min-width: 0;
  min-height: 0;
  background: var(--rootkit__palette--gray100);
  border-bottom: 1px solid var(--rootkit__palette--gray200);
}

.attack-page-content {
  grid-column: no-bleed;
  min-width: 0;
  min-height: 0;

  p {
    margin: 0;
    margin-bottom: 1.5rem;
    padding: 0;
  }
}

.attack-page-content--inner {
  margin: 24px;
  border: 1px solid var(--rootkit__palette--gray300);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.attack-page-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 24px;
  text-align: center;

  p {
    max-width: 700px;
  }

  &:last-child {
    padding-bottom: 48px;
  }
}

.attack-page-section--top {
  padding: 48px 0;
  margin-bottom: 36px;
  background-color: $rootkit__color-grey--darker;
  color: var(--rootkit__palette--white);
  text-align: center;
}

.attack-page-section-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 24px;

  .attack-page-section--top & {
    font-size: 36px;
  }
}
