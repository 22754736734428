@mixin targets--last-cell {
}

// @TODO: Move this back into rootkit when we're revising *all* cards
// @see: https://css-tricks.com/flexbox-truncated-text/

.target .accordion-card-cell {
  // @TODO: Add min-width: 0 to rootkit component
  min-width: 0;
  white-space: normal;
  word-wrap: break-word;
  padding-right: 2px;
}

.accordion-card-after {
  padding: 0;
}

.target {
  @include entity;
  @include targets--last-cell;

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
      stroke: $rootkit__color-grey--light;
    }
  }

  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 0;
  }

  /* updated hex code to Carbon green-60 */
  &--authorization__authorized {
    background: var(--rootkit__palette--white);
    background: linear-gradient(
      90deg,
      #198038 0%,
      #198038 3px,
      rgba(255, 255, 255, 0.1) 4px
    );
  }
}

.target-info {
  @include entity-info;
}

.target-info-item {
  @include entity-info-item;
}

.target-tags {
  .target-info-item {
    @include entity-info-item(2.5);
  }
}

.target-info--desc,
.target-info--notes {
  .target-info-item {
    @include entity-info-item(1);
  }
}

.target-info-item-label {
  @include entity-info-item-label;
}

.target-info-item-value {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.target-service-info {
  @include entity-info;
}

.target-service-info-left-item {
  flex: 1;
  border-right: 1px solid $rootkit__color-grey--lighter;
  padding-right: 24px;

  .tt-graph-container {
    width: 100%;

    .tt-graph-head {
      background-color: inherit;
      padding: 0;
    }

    .tt-graph-body {
      padding: 0;

      .bar-graph {
        padding: var(--bar-graph-padding) 0;
      }
    }
  }
}

.target-service-info-right-item {
  width: 60%;
  padding-left: 24px;

  .target-info {
    border-bottom: 0;
  }
}

.target-detections-heading {
  margin: 0 24px 12px;
  padding: 12px 0;
  border-bottom: 1px solid var(--rootkit__palette--gray200);

  h2 {
    margin: 0 24px;
  }
}

.target-artifact-section {
  margin: 1rem 0;

  &:first-child {
    padding-top: 24px;
  }

  &:last-child {
    padding-bottom: 24px;
  }

  a.button {
    &:hover {
      text-decoration: none;
    }
  }
}

.target-artifact-section-label {
  @include entity-info-item-label;
}

.detections-pager {
  margin: 0 24px 9px;
}

.path-list {
  max-width: 100%;
  padding: 0;
}

.path-list-item {
  @include rootkit__list-reset;

  &.additional-results-message {
    font-style: italic;
    margin-top: 5px;
    opacity: 0.7;
  }
}

.dc-schema-string,
.dc-schema-url {
  display: block;
  padding: 6px 0;
}

.dc-schema-ip {
  @include r-link($underline: false);
  text-transform: none;
}

.new-target-banner {
  display: none;
}

.target-header .accordion-card-cell {
  // @TODO: Add min-width: 0 to rootkit component
  min-width: 0;
  padding-right: 0;
}

.target .target-path {
  white-space: normal;
  word-wrap: break-word;

  .external-link {
    white-space: normal;
    word-wrap: break-word;
  }
}

.target .target-identifier-wrapper {
  white-space: normal;
  word-wrap: break-word;

  .target-id {
    white-space: normal !important;
    word-wrap: break-word;
  }
}

.target .target-location-wrapper {
  white-space: normal;
  word-wrap: break-word;

  .u-nowrap {
    white-space: normal;
    word-wrap: break-word;
  }
}

.target .target-name {
  white-space: normal;
  word-wrap: break-word;

  > span {
    white-space: normal !important;
    word-wrap: break-word;
  }
}

.target .target-identifier {
  white-space: normal;
  word-wrap: break-word;

  > span {
    white-space: normal;
    word-wrap: break-word;
  }
}

.target .target-service-link {
  white-space: normal;
  word-wrap: break-word;
}

.target .priority-pill {
  display: flex;
  height: 2rem;
  align-items: center;
}

.accordion-card-row {
  .column-label {
    display: none;
  }
}

.accordion-card-column {
  .accordion-card-cell {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .column-label {
    font-weight: 700;
    color: $rootkit__color-grey;
    width: 120px;
    margin-right: 1rem;
  }
}

.target-header .accordion-card-cell:last-child {
  flex: 0 0 30px;
}

.target .accordion-card-cell:last-child {
  flex: 0 0 40px;
}

.target-screenshot-modal {
  .modal-dialog__content {
    display: flex;
  }

  .carousel {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow {
    display: inline-block;
    position: absolute;
    width: 20px;
    cursor: pointer;

    path {
      stroke: $rootkit__color_red;
    }

    &:nth-child(1) {
      left: 10px;
    }

    &:nth-child(3) {
      right: 0;
    }
  }
}

.target-header .accordion-card-cell:nth-child(3) {
  min-width: 74px;
}

.target .accordion-card-cell:nth-child(3) {
  min-width: 74px;
}
