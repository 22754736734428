.detection {
  @include entity;
  @include targets--last-cell;

  .accordion-card-cell {
    min-width: 0;
    white-space: normal;
    word-wrap: break-word;
  }

  .accordion-card-cell a {
    @include link-reset;
  }

  .accordion-card-primary {
    > .accordion-card-row {
      margin: 0 16px;
      overflow: hidden;
    }
  }

  .card-actions {
    .dropdown-new-option {
      .button > .button-flex {
        width: 160px;
      }
    }
  }

  .entity-name {
    .external-link::after {
      background-size: 10px 10px;
      height: 1.2rem;
    }
  }

  .target-thumbnail {
    width: 82px;
    border: 1px solid rgba($rootkit__color-grey--light, 0.5);

    &:hover {
      cursor: pointer;
    }
  }

  .empty-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    background-color: var(--rootkit__palette--gray200);

    .empty-image-message {
      font-size: 0.5rem;
    }
  }
}

.detection-view-item {
  margin-bottom: 15px;

  .entity-sorts-active {
    font-size: 14px;

    &:first-child {
      padding-left: 0;
    }
  }
}

.detections-header .accordion-card-cell {
  min-width: 0;
  padding-right: 0;
}

.detections-header {
  overflow: hidden;
}

.top-level-detections-header--location {
  @extend .u-nowrap;

  position: relative;

  > .info-popper {
    position: absolute;
    top: 0;
  }
}

.detection-empty-toggle {
  width: 40px;
}
