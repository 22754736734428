.reset-error-boundary {
  font-family: $rootkit__font--heading;
  grid-column: full-bleed-start/full-bleed-end;

  .reset-error-heading {
    display: flex;

    .reset-warning-icon {
      margin-right: 10px;
    }
  }

  .go-back-link {
    @include r-link;
  }

  &.padding {
    padding: 20px;
  }
}
