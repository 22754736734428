.unknown-assets {
  position: relative;
  margin-top: 2rem;
  display: flex;
}

.unknown-assets-graph {
  margin-right: 48px;
  flex: 0 1 200px;
}

.unknown-assets-table {
  flex: 1 0 400px;
}

.unknown-assets-table-header {
  display: flex;
}

.unknown-assets-table-th {
  flex: 1 0 50px;
  padding: 12px 0;
  color: $rootkit__color-grey--light;
  font-family: $rootkit__font--heading;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  white-space: nowrap;
  text-align: center;
}

.unknown-asset {
  display: flex;

  &:nth-child(2n) {
    background: $rootkit__color-grey--lighter;
  }

  &:last-child {
    border-bottom: 1px solid $rootkit__color-grey--lighter;
  }
}

.unknown-assets-table-cell {
  flex: 1 0 50px;
  padding: 12px 0;
  text-align: center;
}

.unknown-assets-table-th:first-child,
.unknown-assets-table-cell:first-child {
  flex: 1 0 120px;
  text-align: left;
  padding-left: 32px;
}

.unknown-percentage {
  fill: $rootkit__color-red;
}

.unknown-asset-link {
  @include r-link($rootkit__color-red);
}

.known-asset-link {
  @include r-link(var(--rootkit__palette--black));
}

.unknown-asset-row-label {
  font-weight: 700;
}

.unknown-assets-cta {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Note: Carbon colors update - split out rgba(#fff, 0.85) into two properties in order to account for opacity while using carbon color */
  background-color: var(--rootkit__palette--white);
  opacity: 85%;
}

.unknown-assets-cta-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  p {
    @include rootkit__typography--h6;

    text-align: center;
  }

  a {
    @include r-link($rootkit__color-red);
  }
}
