$policy-pane-padding: 50px;

.button-dropdown--error {
  color: $rootkit__color-red;
  border-color: $rootkit__color-red;
  background-color: rgba(218, 2, 27, 0.15);
}

.create-policy-pane {
  width: 600px;
  overflow-y: hidden;

  .slideout-header {
    padding: 30px $policy-pane-padding 0;
  }

  .slideout-body {
    display: flex;
    flex-direction: column;
    padding: 0 0 15px !important;
    overflow-y: hidden;

    form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .pane-title-row {
      padding: 0 15px 15px $policy-pane-padding;
      border-bottom: 1px solid $rootkit__color-blue--lighter;
      display: flex;
      align-items: center;

      .panet-title {
        margin: 0;
      }

      .icon-policy {
        margin-left: -30px;
        margin-right: 10px;
      }
    }

    .pane-body {
      flex: 1;
      overflow-y: auto;

      .icon-status--none svg {
        stroke: none;
        fill: $rootkit__color-red;
        opacity: 1;
      }

      .label-row {
        display: flex;
        align-items: center;
        height: 30px;

        .icon {
          margin-left: -30px;
          margin-right: 15px;
        }
      }

      .policy-name {
        padding: 15px $policy-pane-padding;
        border-bottom: 1px solid $rootkit__color-blue--lighter;
      }

      .policy-section {
        border-bottom: 1px solid $rootkit__color-blue--lighter;
      }

      .policy-row {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        padding-bottom: 10px;
      }

      .policy-all-day-toggle {
        margin-top: 30px;
      }

      .policy-delete-schedule {
        margin-top: 15px;
      }

      .policy-add-action {
        padding-top: 15px;
        align-self: flex-start;
      }

      .policy-schedule-row {
        padding-top: 15px;
      }

      .policy-day-select {
        width: 205px;
      }

      .policy-dropdown-select {
        width: 475;
      }

      .policy-dropdown-select ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      .policy-entity-type {
        display: flex;
        flex-direction: column;
        padding: 15px $policy-pane-padding;

        .dropdown-new {
          margin: 4px 0;

          .dropdown-new-content {
            width: 100%;

            .dropdown-new-options {
              padding: 0 0 15px;

              .dropdown-new-option {
                padding: 0.5em 15px;

                .input-radio {
                  margin: 0;
                }
              }
            }

            .list-divider {
              padding-top: 15px;
              position: relative;
              z-index: 1;

              &::before {
                border-top: 1px solid $rootkit__color-blue--lighter;
                content: '';
                margin: 0 auto;
                position: absolute;
                top: 70%;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                z-index: -1;
              }

              .line-text {
                color: $rootkit__color-grey;
                background: var(--rootkit__palette--white);
                padding: 0;
                margin-left: 15px;
              }
            }

            .option-title {
              padding: 20px 15px 10px;
              line-height: 1.5em;
              font-size: 12px;
              color: $rootkit__color-grey;
              text-transform: uppercase;
            }
          }

          .button {
            width: 100%;

            .button-flex {
              justify-content: space-between;
            }
          }
        }
      }

      .policy-filters {
        padding: 15px $policy-pane-padding;
        border-bottom: 1px solid $rootkit__color-blue--lighter;
      }

      .rule-block {
        z-index: auto;
      }

      .policy-actions {
        display: flex;
        flex-direction: column;
        padding: 15px $policy-pane-padding;
        border-bottom: 1px solid $rootkit__color-blue--lighter;

        .policy-actions-label-row {
          display: flex;
          justify-content: space-between;

          .button svg {
            fill: none;
          }
        }

        .dropdown-new {
          margin: 4px 0;

          .dropdown-new-content {
            width: 50%;
          }

          .button {
            &.policy-actions-btn {
              width: 50%;
              overflow: hidden;
            }

            &.remove-policy-action-btn {
              margin-left: 8px;
            }

            svg {
              fill: none;
            }

            .button-flex {
              justify-content: space-between;
            }
          }
        }

        .tags-picker {
          .tags-list {
            list-style: none;
            margin: 0;
            padding: 0;
            overflow: auto;
            white-space: nowrap;

            li {
              margin: 20px 0;
            }
          }

          .tags-picker-footer {
            display: flex;
            justify-content: flex-end;
            padding: 10px;
            border-top: 1px solid $rootkit__color-blue--lighter;
          }
        }
      }

      .policy-note {
        padding: 15px $policy-pane-padding;
        border-bottom: 1px solid $rootkit__color-blue--lighter;

        .policy-note-helptext {
          color: $rootkit__color-grey;
          font-size: 12px;
        }

        textarea {
          resize: vertical;
        }
      }

      .policy-matching-entities {
        padding: 15px $policy-pane-padding;
        border-bottom: 1px solid $rootkit__color-blue--lighter;

        .policy-matching-entities-helptext {
          color: $rootkit__color-grey;
          font-size: 12px;
        }

        .policy-matches {
          display: flex;

          .policy-match {
            display: flex;
            margin: 15px 0;
            width: 50%;
          }
        }
      }

      .policy-warning {
        padding: 15px $policy-pane-padding;

        .policy-warning-helptext {
          color: $rootkit__color-red;
          font-size: 12px;
        }
      }
    }

    .policy-footer {
      padding-top: 15px;
      border-top: 1px solid $rootkit__color-blue--lighter;

      .button-group {
        justify-content: flex-end;
      }
    }
  }
}

.tab-filter-container {
  margin-top: 30px;
}

.authorization-policy_limited-filters-cta {
  margin: 15px 50px;
  padding: 1rem;
  display: flex;

  background: var(--rootkit__palette--gray600);
  border-left: 3px solid var(--rootkit__palette--yellow600);
  color: var(--rootkit__palette--white);
}

.authorization-policy_limited-filters-cta_icon {
}

.authorization-policy_limited-filters-cta_message {
  margin-left: 1rem;
}

.exception-policy-dropdown {
  display: flex;
  flex-direction: column;
  padding: 5px $policy-pane-padding;
}
