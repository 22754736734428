@mixin entity-sorts-hoc {
  .entity-table-title {
    color: var(--rootkit__palette--white);
  }

  .entity-filter-trigger,
  .entity-sort-trigger {
    color: var(--rootkit__palette--white);

    .icon svg {
      fill: var(--rootkit__palette--white);
      stroke: var(--rootkit__palette--white);
    }

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  .entity-sorts {
    border-bottom: none;

    .entity-sorts-active {
      .sort-option {
        color: var(--rootkit__palette--white);
      }

      .icon {
        svg {
          path,
          circle {
            stroke: var(--rootkit__palette--white);
          }
        }
      }

      .button--force-active {
        .icon svg {
          path {
            stroke: var(--rootkit__palette--white) !important;
          }
        }
      }
    }
  }

  .filter-display {
    .filter-display-text-label,
    .filter-display-text {
      color: var(--rootkit__palette--white);
    }
  }
}

.source-tabs {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--rootkit__palette--white);
}

.library-tabs {
  position: sticky;
  top: 0;
  left: 0;
  padding-left: 53px;
  z-index: 10;
  background-color: var(--rootkit__palette--white);
}

.entity-summary--source {
  z-index: 1;
}

/* force headers to be sticky beneath tabs */
.entity-summary--source thead tr th {
  position: sticky !important;
  top: 40.4px !important;
}

.entity-summary-leader-no-float {
  // display: none;
  @include ff-grid-hax;

  grid-column: no-bleed;
}

.entity-summary-leader-top {
  @extend .entity-summary-leader-no-float;

  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background-color: var(--rootkit__palette--white);
}

.entity-summary-leader {
  @extend .entity-summary-leader-top;

  grid-column: no-bleed;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  background-color: var(--rootkit__palette--white);
  -webkit-box-shadow: 0 7px 7px -5px rgba(0, 0, 0, 0.65);
  box-shadow: 0 7px 7px -5px rgba(0, 0, 0, 0.65);
}

$entity-header-charts-ease-time: 0.25s;

.entity-leader-recon-header-hidden {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height $entity-header-charts-ease-time ease-in,
    opacity $entity-header-charts-ease-time ease-in;
}

.entity-leader-recon-header-shown {
  overflow: hidden;

  padding: 0 1.25rem 0.625rem 1.875rem;
  margin-bottom: 10px;
  // If you edit this height, edit: entity-table-page-view.tsx (CHART_HEIGHT) as well
  max-height: 180px;
  opacity: 1;
  transition: max-height $entity-header-charts-ease-time ease-in,
    opacity 0.25s ease-in;
}

.entity-summary-column-header {
  font-size: 12px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.entity-summary-loader {
  min-height: 150px;
  margin: 20px 0;
}

.entity-summary-actions {
  padding: 0 20px;
  align-items: center;
  background-color: var(--rootkit__palette--gray100);
}

.entity-summary-panel-actions {
  &__inner {
    display: flex;
  }

  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  .empty-policy-trigger {
    margin-right: 15px;
  }

  .entity-summary-fts-input {
    margin-right: 15px;
    width: 18rem;

    input {
      font-size: 0.875rem;
      border-color: $rootkit__color-grey--light;
      margin: 0;
    }

    ::input-placeholder {
      opacity: 0.4;
    }
  }

  .dropdown-new-option {
    .button {
      color: $rootkit__color-grey;

      .icon svg {
        fill: none;
        stroke: $rootkit__color-grey;
      }
    }

    a.button {
      display: flex;
      align-items: center;
    }
  }
}

.entity-summary-container {
  @include ff-grid-hax;
  @include rootkit__typography--body2;

  grid-column: no-bleed;
  padding: 0 20px;

  .accordion-card-header {
    .accordion-card-cell {
      .sortable {
        @extend .u-nowrap;

        cursor: pointer;
      }

      .sub-column {
        font-weight: 400;
      }
    }
  }
}

.entity-col-popper-child {
  height: 20px;
  min-width: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.characteristics-popper-values {
  white-space: pre-wrap;
}

.recon-targets-list {
  @include rootkit__list-reset;
}

.recon-targets-list-item {
  margin-bottom: 20px;

  &:first-child {
    margin-top: 20px;
  }
}

.entity-summary-pager {
  display: flex;
  align-items: center;
  padding: 20px 0;

  .pager-list-item {
    margin: 0 20px;
  }

  .entity-summary--target & {
    padding: 20px 0;
  }

  @include desktopXL {
    .entity-summary--target & {
      padding: 20px 0;
    }
  }
}

.page-size {
  .page-size-label {
    font-weight: 700;
    margin-right: 0.5rem;
  }

  .page-size-options {
    @include rootkit__list-reset;
  }

  .page-size-option {
    padding: 5px;
    width: 100%;

    &:hover {
      cursor: pointer;
      background: var(--rootkit__palette--gray200);
    }
  }
}

.entity-summary-header {
  width: 100%;
  margin: 20px 0;
  display: flex;
  align-items: center;

  .summary-pie-chart {
    flex: 1;
    height: 150px;
    width: auto;

    .latest-findings-graph {
      width: 100%;
      height: 100%;
    }
  }

  .summary-breakdown {
    flex: 6;
    margin-left: 40px;

    .entity-summary-by {
      background-color: transparent;
      justify-content: flex-end;
      margin-bottom: 10px;
    }

    .breakdown {
      display: flex;

      .breakdown-item {
        border: 1px solid var(--rootkit__palette--gray200);
        border-top: 5px solid;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 100px;
        align-items: center;
        padding: 20px;
        text-decoration: none;
        color: inherit;
        background-color: var(--rootkit__palette--white);
        transition: background-color 0.25s ease-out;

        &.is-active {
          box-shadow: 0 5px 30px var(--rootkit__palette--gray200),
            0 3px 3px var(--rootkit__palette--gray200);
          z-index: 2;
        }

        &:hover {
          background-color: var(--rootkit__palette--gray200);
        }

        .breakdown-item--primary {
          text-align: center;
          font-size: 2em;
          margin: 0;
          margin-bottom: 10px;
        }

        .breakdown-item--secondary {
          text-align: center;
          margin: 0;
        }
      }
    }

    .impact-score-label {
      display: flex;
      align-items: center;
      justify-content: center;

      .impact-score {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
      }

      &.none {
        .impact-score {
          background-color: $randori-no-impact;
        }
      }

      &.low {
        .impact-score {
          background-color: $randori-low-impact;
        }
      }

      &.medium {
        .impact-score {
          background-color: $randori-medium-impact;
        }
      }

      &.high {
        .impact-score {
          background-color: $randori-high-impact;
        }
      }

      .impact-score-text {
        margin: 0;

        &.None {
          opacity: 0.5;
        }
      }
    }

    .status-label {
      display: flex;
      align-items: center;
      justify-content: center;

      .status-label-value {
        margin: 0 0 0 5px;

        &.none {
          opacity: 0.5;
        }
      }
    }

    .tt-label {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .priority-label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.entity-summary-leader--head {
  display: flex;
  flex-direction: column;
  background-color: var(--rootkit__palette--gray100);

  .entity-summary-leader-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem 0.625rem 1.875rem;

    > .entity-table-title {
      margin: 0;
      padding: 0;
    }
  }

  > .entity-filters {
    width: 100%;
    margin: 10px 0 0;
  }

  > .entity-sorts {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--rootkit__palette--gray200);
  }
}

.entity-summary--blueprint {
  @include entity-sorts-hoc;

  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: var(--rootkit__palette--white);
    margin: 0;
  }

  .entity-summary-leader--head {
    background-color: var(--rootkit__palette--gray700);
  }

  .entity-filters-label,
  .entity-sorts-label {
    color: var(--rootkit__palette--white);
  }

  .entity-filter-dropdowns .compound-button {
    color: var(--rootkit__palette--white);
  }
}

.entity-summary--globalArtifact {
  @include entity-sorts-hoc;

  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: var(--rootkit__palette--white);
    margin: 0;
  }

  .entity-summary-leader--head {
    background-color: var(--rootkit__palette--gray700);
  }

  .entity-filters-label,
  .entity-sorts-label {
    color: var(--rootkit__palette--white);
  }

  .entity-filter-dropdowns .compound-button {
    color: var(--rootkit__palette--white);
  }
}

.entity-summary--bar {
  @include entity-sorts-hoc;

  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: var(--rootkit__palette--white);
    margin: 0;
  }

  .entity-summary-leader--head {
    background-color: var(--rootkit__palette--gray700);
  }

  .entity-filters-label,
  .entity-sorts-label {
    color: var(--rootkit__palette--white);
  }

  .entity-filter-dropdowns .compound-button {
    color: var(--rootkit__palette--white);
  }
}

.entity-summary--hostname {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--ip {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--artifact {
  .entity-sorts {
    border-bottom: none;
  }
}

.entity-summary--network {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--policy {
  .entity-sorts {
    border-bottom: none;
  }

  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--savedViews {
  .entity-sorts {
    border-bottom: none;
  }

  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--topLevelDetection {
  .entity-sorts {
    border-bottom: none;
  }

  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--service {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--social {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--runbook {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--implant {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--redirector {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary--target {
  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-summary__description {
  padding: 0 1.25rem 0 1.875rem;
  border-bottom: 1px solid var(--rootkit__palette--gray200);
}

.entity-summary__description--source {
  padding: 0 1.25rem 0 1.875rem;
  border-top: 1px solid var(--rootkit__palette--gray200);
  border-bottom: 1px solid var(--rootkit__palette--gray200);
}

.entity-summary--characteristicRule,
.entity-summary--globalHostname,
.entity-summary--globalIps,
.entity-summary--globalNetwork,
.entity-summary--globalService,
.entity-summary--organization,
.entity-summary--peer,
.entity-summary--poc,
.entity-summary--serviceRule,
.entity-summary--serviceSuggestion,
.entity-summary--term {
  @include entity-sorts-hoc;

  .entity-filters {
    display: flex;
    align-items: center;
  }

  .entity-filters-label {
    font-size: 16px;
    color: var(--rootkit__palette--white);
    margin: 0;
  }

  .entity-summary-leader--head {
    background-color: var(--rootkit__palette--gray700);
  }

  .entity-filters-label,
  .entity-sorts-display .entity-sorts-active .sort-str,
  .entity-sorts-label {
    color: var(--rootkit__palette--white);
  }

  .entity-filter-dropdowns .compound-button {
    color: var(--rootkit__palette--white);
  }
}

.entity-summary--table {
  > .entity-summary-leader {
    grid-column: no-bleed;
    grid-row: leader;

    position: initial;
    left: initial;
    right: initial;
    top: initial;
    z-index: initial;
    box-shadow: none;
  }

  > .entity-summary-primary {
    grid-column: full-bleed;
    grid-row: primary;

    overflow: auto;
  }
}

.entity-summary__pager {
  padding: 20px;
  display: flex;
  align-items: center;

  .page-size {
    margin-right: 1rem;
  }
}

.add-internal-network {
  margin-left: 15px;
}
