.entity-detail__actions {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 24px 1rem 40px;
  border-top: 1px solid var(--rootkit__palette--gray300);

  .entity-detail__action-list {
    @include rootkit__list-reset;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .entity-detail__action-list__item {
    margin-left: 0.5rem;
  }
}

.entity-detail__edit-dns {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 42px;
}

.entity-detail__edit-dns--text,
.entity-detail__edit-dns--input {
  min-width: 100px;
}

.entity-detail__edit-dns--edit-button {
  padding: 0.5rem;
}

.entity-detail__edit-dns--button-group {
  display: inline-flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  width: 100%;
}

.entity-detail__actions__meta-data {
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;

    .entity-detail__action-list {
      justify-content: flex-start;
      padding-top: 1rem;
    }

    .entity-detail__action-list__item:first-child {
      margin-left: 0;
    }
  }
}

.entity-detail__meta-data {
  flex: 0 1 100%;
}

.entity-detail__meta-data__item {
  display: flex;
  width: 100%;
  align-items: baseline;
}

.entity-detail__meta-data__term {
  padding-right: 0.5em;
  color: var(--rootkit__palette--gray500);
}

.entity-detail__meta-data__term--first {
  flex: 0 0 90px;
}

.entity-section__content--two-columns {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1 0 350px;
    margin: 0 32px 24px 0;
  }
}

.entity-detail__title {
  padding: 1rem 24px 1rem 40px;
  display: flex;
  align-items: flex-end;

  > * {
    padding-right: 24px;
  }

  > *:last-child {
    padding-right: 0;
  }
}

.entity-navigation {
  background-color: var(--rootkit__palette--gray100);
  border-right: 1px solid var(--rootkit__palette--gray300);
  border-top: 1px solid var(--rootkit__palette--gray300);
  padding: 12px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: 100%;

  ol {
    @include rootkit__list-reset;
    flex: 0 1 90%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  svg.entity-detail--nav-icon {
    margin-right: 0.8rem;
  }
}

.entity-detail--nav-icon-wrapper {
  display: flex;
  align-items: center;
}

.entity-detail-grid {
  display: grid;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  grid-template-areas:
    'head head'
    'nav  primary';

  // @TODO: implement in the grid system in rootkit and account for mobile.
  --entity-detail-grid__primary--min-content-width: 570px;

  grid-template-columns: 18rem minmax(
      var(--entity-detail-grid__primary--min-content-width),
      1fr
    );
  grid-template-rows: auto 1fr;
  overflow-x: auto;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 4rem minmax(
        var(--entity-detail-grid__primary--min-content-width),
        1fr
      );

    .entity-navigation__label,
    .entity-navigation__item--nested {
      display: none;
    }

    svg.entity-detail--nav-icon {
      margin-right: 0;
    }
  }

  header {
    grid-area: head;
    background: var(--rootkit__palette--gray100);

    .target-thumbnail-container > img {
      height: 50px;
    }
  }

  section {
    grid-area: primary;
    color: var(--rootkit__palette--gray700);
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5rem;
  }

  p {
    color: var(--rootkit__palette--gray500);
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5rem;
  }

  .policy-action-display {
    color: var(--rootkit__palette--gray500);
  }

  .characteristics-list p {
    margin-bottom: 1.5rem;
  }

  .entity-detail__primary {
    overflow-y: auto;
    border-top: 1px solid var(--rootkit__palette--gray300);
    background-color: var(--rootkit__palette--white);

    // help minimize jump in content once rendered
    .loader,
    .comment-group-loading {
      min-height: calc(100vh - 600px);
    }

    // helps prevent erroneous active nav update on content load
    > *:last-child {
      min-height: calc(100vh - 600px);
    }
  }

  .entity-detail__primary > .rootkit__grid-container__wrapper {
    margin-bottom: 3.5rem;
  }

  .entity-detail__primary > .rootkit__grid-container__wrapper:last-child {
    margin-bottom: 7rem;
  }

  // @TODO: Sections in rootkit needs an addlClasses prop.
  .rootkit__section__content {
    padding-bottom: 1rem;

    &.rootkit__section__content--is-collapsed {
      padding-bottom: 0;
    }
  }

  .rootkit__section__label {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .hdyft {
    padding: 16px 35px;
  }

  .rootkit__table {
    width: calc(100% - 55px);
    margin: auto;
    margin-top: 16px;
  }

  .network-related-ips--empty-state,
  .entity-targets-view__empty-state {
    text-align: center;
    font-family: var(--rootkit__font--body);
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--rootkit__palette--gray700);
  }

  .detected-ips {
    border: none;
    padding: 0 28px;

    h2 {
      display: none;
    }
  }

  .entity-sorts-display,
  .entity-sorts-then,
  .randori-author {
    font-size: 0.875rem;
  }

  .pill {
    padding-left: 8px;
    margin-left: 8px;
  }

  .target-artifact-section {
    margin: 24px 16px;
  }
}

.entity-detail__title__name {
  &.rootkit__typography--h1 {
    margin: 0;
  }

  .external-link:link {
    color: inherit;
  }

  .external-link::after {
    background-position-y: 1.7rem;
    background-image: url('../static/images/svg-out/external-link-dark.svg');
  }
}

p.entity-detail__title__id {
  margin: 0;
  margin-bottom: 3px;
}

// @TODO: create a class for this behavior
//
// This is already duplicated around too many times.
.entity-detail__title__thumbnail {
  .target-thumbnail-container {
    position: relative;
    max-width: fit-content;
  }

  .target-thumbnail {
    width: 82px;
    border: 1px solid rgba($rootkit__color-grey--light, 0.5);

    &:hover {
      cursor: pointer;
    }

    &.target-thumbnail--no-image {
      position: relative;
    }
  }

  .badge {
    font-size: 12px;
    line-height: 12px;
    color: var(--rootkit__palette--white);
    background-color: $rootkit__color-red;
    height: 20px;
    width: fit-content;
    min-width: 20px;
    border-radius: 100px;
    text-align: center;
    padding: 4px;
    position: absolute;
    top: -8px;
    right: -10px;
  }

  .empty-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    background-color: var(--rootkit__palette--gray200);

    .empty-image-message {
      font-size: 0.5rem;
    }
  }
}

.detail-border {
  width: 100%;
  border-bottom: 1px solid var(--rootkit__palette--gray300);
}

.entity-section__header {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 28px 0;
  border-bottom: 2px solid var(--rootkit__palette--gray300);

  svg.entity-section__header--icon {
    margin: 0 18px 0 0;
  }
}

.entity-section__header--icon-and-label {
  display: inline-flex;
  align-items: baseline;
}

.entity-section__header--label-and-tooltip {
  display: flex;
  align-items: center;
}

.entity-section__header--label {
  display: flex;
  align-items: center;
  margin: 0;
  white-space: nowrap;
}

.entity-section__header--tooltip {
  margin-left: 0.625rem;
}

.entity-detail__button-with-carbon-icon--right {
  .button-children {
    margin-right: 0.5rem;
  }
}

.entity-navigation__item {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 44px;
  padding: 12px;

  svg.entity-detail--nav-icon {
    stroke: var(--rootkit__palette--gray400);
  }

  &:hover {
    cursor: pointer;
    background: var(--rootkit__palette--gray200);
  }

  &:active {
    background: var(--rootkit__palette--gray300);
  }
}

.entity-navigation__item--nested {
  padding-left: 56px;

  .rootkit__typography--h6 {
    font-weight: 300;
  }
}

.entity-navigation__item--nested.entity-navigation__item--is-active {
  background: color-mix(in srgb, var(--rootkit__palette--gray200) 65%, white);

  .rootkit__typography--h6 {
    font-weight: 300;
  }
}

.entity-navigation__item--is-active {
  background: var(--rootkit__palette--gray200);

  .entity-navigation__label {
    font-weight: bold;
  }

  /* stylelint-disable-next-line */
  .rootkit__typography--h6 {
    font-weight: 700;
  }

  svg.entity-detail--nav-icon {
    stroke: var(--rootkit__palette--gray700);
  }
}

#entity-navigation {
  display: none;
}

.rootkit__section__content > div {
  padding-left: 5px;
}

.description-list__item--reset-margins {
  margin: 0;
  line-height: 1.5rem;
}

.reset-top-margin {
  margin-top: 0;
}

.impact > div,
.status > div {
  margin-bottom: 1rem;
}

p.entity-detail__link--scroll-to {
  text-decoration: underline;
  margin: 8px 0;

  &:hover {
    color: var(--rootkit__palette--gray700);
    cursor: pointer;
  }
}

.rootkit__section__content .policy-matches {
  padding-bottom: 28px;
  padding-top: 28px;
}

@mixin section-table-reset {
  .entity-detail-grid & {
    margin: initial;
    width: 100%;
  }
}

.entity-detail-guidance {
  ol,
  ul {
    color: var(--rootkit__palette--gray500);
  }

  li {
    line-height: 1.5rem;
  }

  /* stylelint-disable-next-line */
  p {
    margin-bottom: 1rem;
  }

  .rootkit__section__content {
    h2:not(:first-child) {
      margin-top: 6rem;
    }

    h3 {
      margin-top: 4rem;
    }

    h4 {
      margin-top: 2rem;
    }
  }
}

.guidance-section__actions {
  display: flex;
  margin-left: auto;
  align-items: center;

  /* stylelint-disable-next-line */
  > * {
    margin-left: 1rem;
  }
}

.entity-identifier {
  &:hover {
    cursor: pointer;
  }
}

/* stylelint-disable-next-line */
.entity-detail__action-list__item {
  .add-tag-dropdown-content {
    right: 0;
  }
}

.entity-detail__title__text-wrapper {
  display: flex;
  flex-direction: column;
}

.entity-pane__title__name-wrapper {
  > div {
    word-break: break-all;
  }
}

.entity-detail__title__type {
  color: var(--rootkit__palette--gray400);
}

.navigation-edit--drag-drop-container {
  height: 100%;
}

.navigation-edit--item {
  background-color: var(--rootkit__palette--white);
  border: 1px solid var(--rootkit__palette--gray700);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
}

// we need to override some css in the modal because the transform property messes with the drag and drop
.navigation-edit--modal {
  transform: none;
  height: 80vh;
  top: 10vh;
  width: 50vw;
  left: 25vw;
}

.entity-navigation__configure-order {
  background-color: var(--rootkit__palette--gray100);
  border-top: 1px solid var(--rootkit__palette--gray200);
  margin-top: 1rem;
}

@import './common-sections/artifacts-v2/entity-details__artifacts.styles';
@import './common-sections/artifacts/entity-details__artifacts.styles';
@import './entity-detail-legacy.styles';
@import './implant/entity-detail--implant.styles';
@import './runbook/entity-detail--runbook.styles';
@import './saved-view/entity-detail--saved-view.styles';
@import './target/entity-detail--target.styles';
