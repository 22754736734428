.dropdown-action-list {
  .dropdown-content {
    padding: 0;
  }

  ul {
    @include rootkit__list-reset;

    > li {
      padding: 0.5rem;
      margin: 1rem;

      &:hover {
        cursor: pointer;
        background: var(--rootkit__palette--gray200);
      }
    }
  }
}
