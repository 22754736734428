@use '@randori/rootkit/src/styles/mixins/drop-shadow';

.modal-guidance-pane {
  --header-height: 80px;

  @include drop-shadow.rootkit__drop-shadow();

  display: flex;
  position: fixed;
  background-color: var(--rootkit__palette--gray100);
  z-index: 999;

  .entity-detail__primary > .rootkit__grid-container__wrapper {
    margin-bottom: 3.5rem;
  }

  .entity-navigation {
    border: none;
  }

  .pager {
    padding: 1rem 0;
  }
  .entity-pane__title__name {
    margin: 0;
  }
}

.modal-guidance-pane--l-bottom {
  bottom: 0;
  flex-direction: row-reverse;
  width: 100vw;
  height: 40rem;
  max-height: 50vh;
  border-top: 1px solid var(--rootkit__palette--gray300);

  .button-group {
    margin-left: 0;
  }
}

.modal-guidance-pane--l-right {
  top: 0;
  right: 0;
  width: 40rem;
  max-width: 50vw;
  height: 100vh;

  .button-group {
    width: initial;
  }

  .pager {
    margin-left: auto;
  }

  .share-button {
    margin: 0 1rem;
  }
}

.modal-guidance-pane__primary {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.modal-guidance-pane__secondary {
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--rootkit__palette--gray300);

  .modal-guidance-pane--l-bottom & {
    flex: 0 0 315px;
  }
}

.modal-guidance-pane__header {
  border-bottom: 1px solid var(--rootkit__palette--gray300);
  display: flex;
  flex: 0 0 var(--header-height);
  padding: 1rem 0;

  .modal-guidance-pane--l-right & {
    flex-direction: column;
  }
}

.modal-guidance-pane__navigation__config {
  flex: 0 0 var(--header-height);
  border-bottom: 1px solid var(--rootkit__palette--gray300);
  padding: 0.75rem;
  display: flex;
  align-items: center;

  .button-group {
    width: initial;
  }
}

.modal-guidance-pane__navigation__content {
  position: relative;
  flex: 1 1 auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 0.5rem;
}

.modal-guidance-pane__content {
  position: relative;
  flex: 1 1 auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1.5rem;
  background-color: var(--rootkit__palette--white);
}

.guidance-pane__title {
  @extend .entity-pane__title;

  align-items: center;
  justify-content: center;
}

.guidance-pane__actions {
  @extend .entity-pane__actions;

  align-items: center;

  .modal-guidance-pane--l-right & {
    padding: 0.5rem;
    margin-left: auto;

    .button-group {
      width: initial;
    }
  }
}

.guidance-pane__actions__share {
  margin-right: 0.5rem;
}

.guidance-pane__category {
  color: var(--rootkit__palette--gray400);
  margin-right: 1rem;
}

.guidance-pane__config__actions {
  > .render-popper {
    margin-right: 0.5rem;
  }

  .modal-guidance-pane--l-right & {
    .render-popper:last-child {
      margin-right: 0;
    }
  }
}

.modal-guidance-pane__text-wrapper {
  max-width: 55rem;
  margin: 0 auto;
}

.hide-guidance-pane {
  margin: 0 1rem;
  color: $rootkit__color-grey--light;

  input[type='checkbox'] {
    height: 1.5rem;
  }

  label {
    line-height: 1.5rem;
    height: 1.5rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  label::before {
    top: 0.25rem;
  }

  label::after {
    top: 0.5rem;
  }
}
