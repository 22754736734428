/* stylelint-disable no-descending-specificity */
.details-page--report {
  .summary-count {
    a {
      @include r-link($rootkit__color-grey, false);

      h2 {
        color: $rootkit__color-grey;
      }
    }
  }

  .stat-box {
    a {
      @include r-link($rootkit__color-grey, false);

      color: $rootkit__color-grey;
    }

    &.high-priority {
      a {
        color: var(--rootkit__palette--white);
      }
    }
  }

  .entity-stat {
    a {
      @include r-link($rootkit__color-grey, false);
    }
  }

  .characteristics-stat {
    a {
      @include r-link($rootkit__color-grey, false);
    }
  }

  .stats-blocks {
    a {
      @include r-link($rootkit__color-grey, false);

      > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .active-entity {
    a {
      @include r-link($rootkit__color-grey, false);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
/* stylelint-enable no-descending-specificity */
