.hosts {
  .external-link {
    &::after {
      height: 20px;
      margin: 0 0 0 5px;
      background-position: initial;
    }
  }
}

.detail-tt-meta {
  @include ff-grid-hax;

  grid-column: secondary;
  grid-row: 1/2;
  background: $rootkit__color-red;
  border-color: $rootkit__color-red;
  text-align: center;
  padding: 10px;

  @include desktopSm {
    grid-row: 1/1;
  }
}

.detail-section-header {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
}

.detail-meta {
  width: 100%;
}

.detail-header-section {
  margin-top: 2rem;
}

.detail-title {
  display: inline-block;
  margin: 0;
  margin-top: 2rem;
  overflow: hidden;

  > a, // .external-link
  > span {
    max-width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.detail-header-actions {
  margin-left: auto;

  .dropdown-content {
    right: 0;
  }
}

.detail-actions {
  .dropdown-new {
    margin-right: 0.5em;
  }

  button {
    .icon svg {
      fill: none;
    }

    &:disabled {
      .icon svg {
        stroke: var(--rootkit__palette--gray300);
      }
    }
  }
}

.detail-header-item-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  .hostname-expanded {
    .hostname-info {
      margin-bottom: 1rem;
      padding-bottom: 1rem;

      &:first-child {
        border-top: 1px solid $rootkit__color-blue--lighter;
        margin-top: 1rem;
        padding-top: 1rem;
      }

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }

  .ip-expanded {
    .ip-info {
      margin-bottom: 1rem;
      padding-bottom: 1rem;

      &:first-child {
        border-top: 1px solid $rootkit__color-blue--lighter;
        margin-top: 1rem;
        padding-top: 1rem;
      }

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }

  .network-expanded {
    .network-info {
      margin-bottom: 1rem;
      padding-bottom: 1rem;

      &:first-child {
        border-top: 1px solid $rootkit__color-blue--lighter;
        margin-top: 1rem;
        padding-top: 1rem;
      }

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }

  .service-expanded {
    .service-info {
      margin-bottom: 1rem;
      padding-bottom: 1rem;

      &:first-child {
        border-top: 1px solid $rootkit__color-blue--lighter;
        margin-top: 1rem;
        padding-top: 1rem;
      }

      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }
}

.detail-header-item {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;

  .icon-date {
    margin-right: 0.3rem;
  }
}

.detail-header-item.entity-pills {
  margin-left: 10px;

  .pill {
    margin-right: 10px;
  }
}

.detail-header-item-title {
  align-items: center;
  display: flex;
  margin: 0 10px;

  // font-weight: bold;

  .icon {
    margin-right: 10px;
  }

  &:first-child {
    margin-left: 0;
  }
}

// Box
// ---------------------------------------------------------------------------

.detail-section-left,
.detail-section-right {
  padding-right: 20px;

  > .box {
    margin-bottom: 20px;
  }
}

.details-page--ip {
  .leader {
    z-index: 2;

    @include ff-grid-hax;

    position: relative;
    min-height: 170px;
    background-color: var(--rootkit__palette--gray100);
    border-bottom: 1px solid $rootkit__color-blue--lighter;
    margin-bottom: 20px;
    padding: 0 20px;

    @include desktopXL {
      padding: 40px calc(10% + 20px);
    }

    @extend .detail-section-header;
  }

  .follower {
    @extend %l-page;

    grid-gap: 20px;
    grid-column: full-bleed-start / full-bleed-stop;

    @include desktopXL {
      grid-template-columns:
        [full-bleed-start] 10% [no-bleed-start secondary-start] repeat(4, 1fr)
        [secondary-end primary-start] repeat(8, 1fr)
        [primary-end no-bleed-end] 10% [full-bleed-end];
    }

    .l-full {
      @include ff-grid-hax;

      grid-column: 2/14;
    }
  }

  .detail-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $z-background;
    overflow: hidden;
  }

  .detail-actions,
  .detail-meta {
    z-index: $z-background;
  }

  .detail-action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 2px;
    background-color: $rootkit__color-grey--lighter;

    h3 {
      margin: 0;
    }

    .detail-actions {
      display: flex;
      justify-content: flex-end;

      .dropdown-content {
        right: 0;
      }
    }
  }

  .l-first {
    @include ff-grid-hax;

    grid-column: secondary;
  }

  .l-second {
    @include ff-grid-hax;

    grid-column: primary;
  }

  .attack,
  .hosts,
  .ports,
  .targets-table,
  .target-temptation {
    margin-bottom: 20px;
  }
}

.details-page--hostname {
  .leader {
    z-index: 2;

    @include ff-grid-hax;

    min-height: 170px;
    margin-bottom: 20px;
    padding: 0 20px;
    background-color: var(--rootkit__palette--gray100);
    border-bottom: 1px solid $rootkit__color-blue--lighter;

    @include desktopXL {
      padding: 40px calc(10% + 20px);
    }

    @extend .detail-section-header;
  }

  .follower {
    @extend %l-page;

    grid-gap: 20px;
    grid-column: full-bleed-start / full-bleed-stop;

    @include desktopXL {
      grid-template-columns:
        [full-bleed-start] 10% [no-bleed-start secondary-start] repeat(4, 1fr)
        [secondary-end primary-start] repeat(8, 1fr)
        [primary-end no-bleed-end] 10% [full-bleed-end];
    }

    .l-full {
      @include ff-grid-hax;

      grid-column: 2/14;
    }
  }

  .l-first {
    @include ff-grid-hax;

    grid-column: secondary;
  }

  .l-second {
    @include ff-grid-hax;

    grid-column: primary;
  }

  .target-temptation,
  .attack,
  .ips-table {
    margin-bottom: 20px;
  }

  .detail-action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 2px;
    background-color: $rootkit__color-grey--lighter;

    h3 {
      margin: 0;
    }

    .detail-actions {
      display: flex;
      justify-content: flex-end;

      .dropdown-content {
        right: 0;
      }
    }
  }
}

.details-page--network {
  .leader {
    z-index: 2;

    @include ff-grid-hax;

    min-height: 170px;
    margin-bottom: 20px;
    padding: 0 20px;
    background-color: var(--rootkit__palette--gray100);
    border-bottom: 1px solid $rootkit__color-blue--lighter;

    @include desktopXL {
      padding: 40px calc(10% + 20px);
    }

    @extend .detail-section-header;
  }

  .follower {
    @extend %l-page;

    grid-gap: 20px;
    grid-column: full-bleed-start / full-bleed-stop;

    @include desktopXL {
      grid-template-columns:
        [full-bleed-start] 10% [no-bleed-start secondary-start] repeat(4, 1fr)
        [secondary-end primary-start] repeat(8, 1fr)
        [primary-end no-bleed-end] 10% [full-bleed-end];
    }

    .l-full {
      @include ff-grid-hax;

      grid-column: no-bleed-start/no-bleed-end;
    }
  }

  .detail-action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 2px;
    background-color: $rootkit__color-grey--lighter;

    h3 {
      margin: 0;
    }

    .detail-actions {
      display: flex;
      justify-content: flex-end;

      .dropdown-content {
        right: 0;
      }
    }
  }

  .l-first {
    @include ff-grid-hax;

    grid-column: no-bleed-start/no-bleed-end;
    grid-row: 2/2;
  }

  .l-second {
    @include ff-grid-hax;

    grid-column: no-bleed-start/no-bleed-end;
    grid-row: 3/3;
  }

  .meta,
  .ips-table {
    margin-bottom: 20px;
  }

  .detail-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.details-page--report {
  .report-summary-label {
    h3 {
      color: $rootkit__color-black;
    }
  }

  .detail-header {
    z-index: 2;
    min-height: 170px;
    padding: 0 map-get($spacer-map, 3);
    margin-bottom: map-get($spacer-map, 3);
    background-color: var(--rootkit__palette--gray100);
    border-bottom: 1px solid $rootkit__color-blue--lighter;

    @extend .detail-section-header;
  }

  .detail-body {
    margin: 0 map-get($spacer-map, 3);
  }
}

.details-page--service {
  .service-temptation-card {
    grid-column: 1 / span 14;
  }

  .ips-table {
    grid-column: 1 / span 14;
    margin: 0 24px 9px;
  }
  .leader {
    z-index: 2;

    @include ff-grid-hax;

    position: relative;
    min-height: 170px;
    background-color: var(--rootkit__palette--gray100);
    border-bottom: 1px solid $rootkit__color-blue--lighter;
    margin-bottom: 20px;
    padding: 0 20px;

    @extend .detail-section-header;
  }

  .follower {
    @extend %l-page;

    grid-gap: 20px;
    grid-column: full-bleed-start / full-bleed-stop;

    @include desktopXL {
      grid-template-columns:
        [full-bleed-start] 10% [no-bleed-start secondary-start] repeat(4, 1fr)
        [secondary-end primary-start] repeat(8, 1fr)
        [primary-end no-bleed-end] 10% [full-bleed-end];
    }

    .l-full {
      @include ff-grid-hax;

      grid-column: no-bleed;
      margin-bottom: 20px;
    }
  }

  .detail-header-item--version {
    opacity: 0.5;

    // font-size: 20px;
  }

  .detail-meta {
    .detail-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .detail-action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 2px;
    background-color: $rootkit__color-grey--lighter;

    h3 {
      margin: 0;
    }

    .detail-actions {
      display: flex;
      justify-content: flex-end;
      z-index: $z-background;

      .dropdown-content {
        right: 0;
      }
    }
  }

  .l-first {
    @include ff-grid-hax;

    grid-column: secondary;
  }

  .l-second {
    @include ff-grid-hax;

    grid-column: primary;
  }

  .attack,
  .info,
  .ports,
  .ips-table,
  .target-temptation {
    margin-bottom: 20px;
  }
}

.detail-card--target-temptation-radar {
  max-width: 450px;
  margin: auto;
}

.detail-card--target-temptation-content {
  margin-top: 10px;
}

.service-desc-trigger {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  padding: 0.5rem 0 0.25rem;
  cursor: pointer;
}

.service-desc-caret {
  display: flex;
  transform-origin: center;
  transform: rotate(0deg);
  transition: all 0.3s ease 0s;

  .icon {
    margin: auto;
  }

  .service-desc--is-collapsed & {
    transition: all 0.3s ease 0s;
    transform: rotate(-90deg);
  }
}

.service-desc-content {
  p {
    margin: 0.25rem 0 0.5rem;
  }
}
