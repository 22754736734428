@mixin policies--last-cell {
  .accordion-card-cell {
    // @TODO: This isn't quite right with overflow-manager
    &:last-child {
      flex: 0 1 240px;
    }
  }
}

.policies-header {
  @include policies--last-cell;
}

.policy {
  @include entity;
  @include policies--last-cell;

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
    }
  }

  .overflow {
    display: inline;
  }
}

.policies--empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;

  > p {
    text-align: center;
    max-width: 320px;
  }
}

.policy-info-item {
  @include entity-info-item;
}

.policy-info {
  @include entity-info;

  &--meta {
    .policy-info-item {
      @include entity-info-item(3);
    }
  }

  &--rules {
    flex-direction: column;

    .policy-info-item {
      @include entity-info-item(1);

      margin-bottom: 1rem;
    }
  }

  &--notes {
    .policy-info-item {
      @include entity-info-item(1);
    }
  }

  &--matches {
    .policy-info-item {
      @include entity-info-item(1);
    }
  }
}

.policy-info-item-label {
  @include entity-info-item-label;
}

.policy-matches {
  @include rootkit__list-reset;

  display: flex;
  flex-wrap: wrap;
}

.policy-match {
  margin: 0 1rem 1rem 0;
  width: calc((100% / 3) - 1rem);
}

.policy-match-label {
  display: inline-block;
  margin-right: 0.5rem;
}

.policy-match-value {
  display: inline-block;
}

.policy-actions-display {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: $rootkit__color-grey;
}

.policy-action-display {
  display: inline-block;
  margin-right: 0.25rem;
}

.policy-action-display-label {
  // font-weight: bold;
  display: inline-block;
  margin-right: 0.25rem;
  text-transform: capitalize;
}

.policy-action-display-value {
  display: inline-block;
}

.policy-action-display-and {
  display: inline-block;
  margin: 0 0 0 0.25rem;
  text-transform: uppercase;
  color: $rootkit__color-red;
}

.matching-entity-link {
  &::after {
    background-size: 10px 10px;
    height: 10px;
    width: 10px;
    margin: 0 4px;
    background-position-y: initial;
  }
}
