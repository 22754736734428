.tt-graph-container {
  display: flex;
  flex-direction: column;
}

.tt-graph-head {
  margin-bottom: 1rem;
}

.tt-graph-label {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
}

.tt-graph-label--low {
}

.tt-graph-label--medium {
}

.tt-graph-label--high {
}

.tt-graph-label-value--low {
  color: $rootkit__color-grey;
}

.tt-graph-label-value--medium {
  color: $rootkit__color-orange;
  font-weight: 700;
}

.tt-graph-label-value--high,
.tt-graph-label-value--critical {
  color: $rootkit__color-red;
  font-weight: 700;
}

.factor {
  margin-bottom: 1rem;
}

.factor--high {
  .bar {
    background-color: $rootkit__color-red;
  }
}

.factor--low {
  .bar {
    background-color: $rootkit__color-grey;
  }
}

.factor--medium {
  .bar {
    background-color: $rootkit__color-orange;
  }
}

.factor-graph {
  margin-bottom: 0.5rem;

  --bar-graph-height: 5px;
}

.factor-graph > .bar-graph {
  --bar-graph-padding: 0;

  padding-left: 0;
  padding-right: 0;
}

.factor-graph .bar {
  margin-right: 0 !important;
  height: var(--bar-graph-height);
}

.factor-graph .bar-remainder {
  background-color: $rootkit__color-grey--light;
  height: var(--bar-graph-height);
  opacity: 0.3;
}

.factor-graph .bar-graph {
  height: var(--bar-graph-height);
}

.factor-graph .bar-wrapper {
  height: var(--bar-graph-height);
}

.factor-meta {
  position: relative;
  display: flex;
  align-items: baseline;
  padding-right: 8px;
}

.factor-trigger {
  display: inline-block;
  transition: all 0.3s ease 0s;

  &--is-collapsed {
    transform: rotate(-90deg);
  }

  &:hover {
    cursor: pointer;
  }
}

.factor-label {
  display: inline-block;
  margin: 0;
  margin-right: 0.5rem;
  color: var(--rootkit__palette--gray500);
}

.factor-def {
  display: inline-block;
  font-style: italic;
  color: var(--rootkit__palette--gray500);
  margin-right: auto;
}

.factor-desc {
  margin-bottom: 0.5rem;
  color: var(--rootkit__palette--gray500);
}

.tt-tooltip {
  padding: 0;
  z-index: $z-foreground + 3;

  .tt-graph-head {
    background-color: var(--rootkit__palette--gray100);
    padding: 0.5rem;
  }

  .tt-graph-body {
    padding: 0 0.5rem;
  }

  .info-popper {
    display: none;
  }
}

.tt-tooltip-tt--low {
  color: $rootkit__color-grey;
}

.tt-tooltip-tt--medium {
  color: $rootkit__color-orange;
}

.tt-tooltip-tt--high {
  color: $rootkit__color-red;
}

.tt-tooltip-tt--critical {
  color: $rootkit__color-red;
}

.temptation-info {
  color: var(--rootkit__palette--gray700);
}

.temptation-info:not(:last-child) {
  margin-right: 0.5rem;
}

.temptation-info-factor--in_review {
  svg {
    transform: rotate(-180deg);
  }

  path {
    fill: $rootkit__color-grey;
  }
}

.temptation-info-factor--low {
  svg {
    transform: rotate(-180deg);
  }

  path {
    fill: $rootkit__color-grey;
  }
}

.temptation-info-factor--medium {
  path {
    fill: $rootkit__color-orange;
  }
}

.temptation-info-factor--high {
  path {
    fill: $rootkit__color-red;
  }
}

.temptation-info-factor--critical {
  path {
    fill: $rootkit__color-red;
  }
}

.temptation-info-content {
  .external-link {
    @include r-link;

    display: block;
    margin-top: 0.5rem;
  }
}
