.target-name {
  padding: 6px 0;

  .target-service-link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.target-id {
  cursor: pointer;
  color: var(--rootkit__palette--gray500);

  &:hover {
    color: var(--rootkit__palette--gray700);
  }
}
