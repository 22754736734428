.entity-detail__artifacts__item--modal {
  overflow: auto;

  pre {
    margin: 1rem 0;
    padding: 10px 16px;
    background-color: var(--rootkit__palette--gray100);
    font-size: 0.75rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
  }
}

.entity-detail__artifacts-container--activity-instance {
  display: grid;
  grid-template-areas:
    'artifact details'
    'artifact buttons';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 288px 84px;
  grid-gap: 24px;
  margin: 24px 16px 64px;

  &:last-child {
    margin-bottom: 0;
  }

  /* add some spacing between the download button and the view artifact button */
  .entity-detail__artifacts__buttons--download {
    margin-bottom: 0.5rem;
  }

  .entity-detail__artifacts__buttons--hide {
    display: none;
  }

  .entity-detail__artifacts__buttons {
    grid-area: buttons;
    width: min-content;
    height: min-content;
  }

  .entity-detail__artifacts__details {
    grid-area: details;
    margin: 0;
  }

  .entity-detail__artifacts__item--activity-instance {
    grid-area: artifact;
    overflow: auto;
    height: 100%;

    &.entity-detail__artifacts__item--background {
      background-color: var(--rootkit__palette--gray100);
    }

    pre {
      white-space: pre-wrap;
      font-size: 0.75rem;
      line-height: 1.5rem;
      padding: 10px 16px;
      margin: 0;
    }

    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1200px) {
    grid-template-areas:
      'artifact'
      'buttons'
      'details';
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0, 288px) 84px 288px;

    &.entity-detail__artifacts-container--screenshot {
      grid-template-rows: minmax(0, 288px) 84px 288px;
    }
  }
}

.entity-detail__artifacts-container--is_not_renderable {
  display: block;
}

.modal-inspect-pane--l-right {
  .entity-detail__artifacts-container--activity-instance {
    @media only screen and (max-width: 1500px) {
      grid-template-areas:
        'artifact'
        'buttons'
        'details';
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, 288px) 84px 288px;

      &.entity-detail__artifacts-container--screenshot {
        grid-template-rows: minmax(0, 288px) 84px 288px;
      }
    }
  }
}

/* custom div which mimics <pre> tag styling to avoid unintentionally executing a script */
div.preformatted {
  font-family: monospace;
  background-color: var(--rootkit__palette--gray100);
  white-space: pre-wrap;
  font-size: 0.75rem;
  line-height: 1.5rem;
  padding: 10px 16px;
  margin: 1rem 0;
}
