@use '@randori/rootkit/src/styles/mixins/button';

$global-menu-bg: var(--rootkit__palette--gray600);

@mixin global-nav-section {
  @include rootkit__list-reset;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    border-bottom-color: transparent;
  }

  > li {
    padding: 1rem 0;
  }
}

.global-nav-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 9.5px 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.app-navigation {
  @include global-nav-section;
}

.user-navigation {
  @include global-nav-section;
}

.global-nav-button {
  @include button.rootkit__button(
    $border: transparent,
    $background: $global-menu-bg,
    $color: var(--rootkit__palette--white),
    $hoverBorder: var(--rootkit__palette--gray700),
    $hoverBackground: var(--rootkit__palette--gray700),
    $hoverColor: var(--rootkit__palette--white),
    $activeBorder: var(--rootkit__palette--gray700),
    $activeBackground: var(--rootkit__palette--gray700),
    $activeColor: var(--rootkit__palette--white),
    $disabledBorder: transparent,
    $disabledBackground: $global-menu-bg,
    $disabledColor: var(--rootkit__palette--white),
    $progress: transparent,
    $progressColor: transparent
  );

  opacity: 0.5;

  &:focus {
    outline: 0;
  }

  &--is-active {
    opacity: 1;
    border-color: var(--rootkit__palette--gray700);
    background-color: var(--rootkit__palette--gray700);
    color: var(--rootkit__palette--white);
  }

  .hoc-link svg {
    fill: transparent !important;
  }

  .icon-rat svg {
    fill: transparent;
  }

  &:hover {
    .icon-rat svg {
      fill: transparent;
    }
  }
}

.global-nav-link {
  @include r-link(var(--rootkit__palette--white), false);

  opacity: 0.5;
  display: flex;
  align-items: center;
  height: 2.5rem;
  justify-content: flex-start;
  padding: 0 10px;
  border-radius: 3px;
  background: none;

  &--is-active {
    opacity: 1;
    background: var(--rootkit__palette--gray700);
  }
}

// desktop
// ---------------------------------------------------------------------------

.global-navigation-desktop {
  width: 50px;
  background: $global-menu-bg;

  .button {
    padding: 0.5rem;
  }

  .icon {
    margin: 0;

    &:first-child {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }
}

// mobile
// ---------------------------------------------------------------------------

.global-navigation-mobile {
  background: $global-menu-bg;

  ul {
    @include rootkit__list-reset;

    padding: 0 30px 0 70px;
  }

  li {
    margin: 0.5rem 0;
    padding: 0;
    font-family: $rootkit__font--heading;
    letter-spacing: 0.2px;
    cursor: pointer;
    border-radius: 0;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .global-nav-logo {
    border-bottom: none;
  }

  .global-navigation-mobile-header {
    color: var(--rootkit__palette--white);
    display: flex;
    align-items: center;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.55);
    height: 60px;

    .icon svg {
      fill: var(--rootkit__palette--white);
      stroke: var(--rootkit__palette--white);
    }
  }

  .global-navigation-mobile-contents {
    display: none;

    &--is-open {
      display: block;
    }
  }

  .state {
    display: flex;
    justify-content: space-around;
    width: 30px;
  }
}

.carbon-colors {
  .global-nav-button {
    @include button.rootkit__button(
      $border: transparent,
      $background: transparent,
      $color: var(--rootkit__palette--white),
      $hoverBorder: var(--rootkit__palette--gray700),
      $hoverBackground: var(--rootkit__palette--gray700),
      $hoverColor: var(--rootkit__palette--white),
      $activeBorder: var(--rootkit__palette--gray700),
      $activeBackground: var(--rootkit__palette--gray700),
      $activeColor: var(--rootkit__palette--white),
      $disabledBorder: transparent,
      $disabledBackground: $global-menu-bg,
      $disabledColor: var(--rootkit__palette--white),
      $progress: transparent,
      $progressColor: transparent
    );

    opacity: 0.5;

    &:focus {
      outline: 0;
    }

    &--is-active {
      opacity: 1;
      border-color: var(--rootkit__palette--gray700);
      background-color: var(--rootkit__palette--gray700);
      color: var(--rootkit__palette--white);
    }

    .hoc-link svg {
      fill: transparent !important;
    }

    .icon-rat svg {
      fill: transparent;
    }

    &:hover {
      .icon-rat svg {
        fill: transparent;
      }
    }
  }
}

.logo-path {
  width: 100%;
  height: 100%;
  /* updated to Carbon hex codes for red-70 and red-60 */
  background: conic-gradient(
      from 61deg at 46.86% 51.14%,
      #da1e28 -61.88deg,
      #a2191f 29.91deg,
      #da1e28 298.12deg,
      #a2191f 389.91deg
    ),
    var(--rootkit__palette--red600);
}
