.tt-top-targets,
.tt-top-targets-related-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $rootkit__color-red;
  padding: 25px;
  text-decoration: none;
  color: var(--rootkit__palette--white);
  flex-grow: 3;

  > h2 {
    margin: 0;
    padding: 0;
  }
}

.tt-top-targets-label {
  font-family: $rootkit__font--heading;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.5px;
}

.tt-top-targets-count {
  font-family: $rootkit__font--heading;
  font-weight: 400;
  font-size: 48px;
  line-height: 65px;
}

.tt-top-targets-delta {
  font-size: 12px;
  font-weight: normal;

  .icon {
    margin-right: 0.5rem;
  }
}

.tt-top-targets-related-item {
  border-left: solid 1px var(--rootkit__palette--gray200);
  color: $rootkit__color-red;
  background-color: var(--rootkit__palette--white);

  a {
    @include r-link($rootkit__color-red, false);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    text-decoration: none;
  }
}

.tt-related-count {
  line-height: 1.7em;
  font-size: 18px;
  font-family: $rootkit__font--heading;
  font-weight: 600;
}

.tt-related-label {
  font-size: 12px;
}
