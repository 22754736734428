.bar {
  .accordion-card-cell {
    overflow-wrap: break-word;
  }

  // &--pending {
  // }

  &--approved {
    border-left: 1px solid $rootkit__color-green;
    background: linear-gradient(
      90deg,
      $rootkit__color-green,
      $rootkit__color-green 2px,
      transparent 2px
    );
  }

  &--denied {
    border-left: 1px solid $rootkit__color-red;
    background: linear-gradient(
      90deg,
      $rootkit__color-red,
      $rootkit__color-red 2px,
      transparent 2px
    );
  }

  &--handled {
    border-left: 1px solid $rootkit__color-orange;
    background: linear-gradient(
      90deg,
      $rootkit__color-orange,
      $rootkit__color-orange 2px,
      transparent 2px
    );
  }
}

.bar-expanded {
  margin: 0 24px 24px;
  padding-top: 24px;
  border-top: 1px solid $rootkit__color-blue--lighter;
}

.bar-id {
  cursor: pointer;
  color: var(--rootkit__palette--gray500);

  &:hover {
    color: var(--rootkit__palette--gray700);
  }
}

.bar-desc-section-label {
  @include entity-info-item-label;
}

.bar-meta {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $rootkit__color-blue--lighter;

  .icon svg {
    fill: $rootkit__color-grey--light;
    stroke: $rootkit__color-grey--light;
  }
}

.bar-links-label {
  @extend .bar-desc-section-label;

  width: 100%;
}

.bar-links {
  display: flex;
  margin-bottom: 12px;

  > div:first-child {
    width: 32px;
    margin-right: 8px;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
  }
}

.bar-desc-section-copy {
  width: 100%;
}

// actions
// ---------------------------------------------------------------------------

.bar-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  padding-top: 24px;
  padding-bottom: 12px;
  border-top: 1px solid $rootkit__color-blue--lighter;
}

// pre
// ---------------------------------------------------------------------------

.bar-pre {
  padding: 1rem;
  white-space: pre-wrap;
  border: solid 1px $rootkit__color-blue--lighter;
  border-radius: 3px;
  background: rgba($rootkit__color-grey--light, 0.3);
}
