.u-bold {
  font-weight: bold;
}

.u-muted {
  color: $rootkit__color-grey;
}

.u-text-centered {
  text-align: center;
}

.u-nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
