.tag-list {
  .click-tag {
    background-color: var(--rootkit__palette--white);
  }

  input[type='text'] {
    &:focus {
      outline: 0;
    }
  }

  .invalid {
    input[type='text'] {
      border-color: $rootkit__color-red;
    }
  }
}
