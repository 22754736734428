.tt-list .box-list-item {
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
}

.tt-list {
  > li {
    flex-grow: 1;
    padding: 20px;
  }

  a {
    @include r-link;
  }
}

.tt-list-item--first {
  flex: 1 1 auto;
}
