.social-entity {
  @include entity;

  .accordion-card-cell {
    min-width: 0;
    white-space: normal;
    word-wrap: break-word;
  }

  .accordion-card-row {
    justify-content: normal;
  }

  .accordion-card--nested {
    .accordion-card-primary {
      // justify-content: flex-end;
      padding: 0 12px;
    }
  }

  .social-entity-avatar {
    img {
      vertical-align: middle;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .social-info {
    @include entity-info;
  }

  .social-info-item {
    @include entity-info-item;
  }

  .social-info-item-label {
    @include entity-info-item-label;
  }

  .social-tags {
    .social-info-item {
      @include entity-info-item(2.5);
    }
  }

  /* updated hex code to Carbon green-60 */
  &.social--authorization__authorized {
    background: var(--rootkit__palette--white);
    background: linear-gradient(
      90deg,
      #198038 0%,
      #198038 3px,
      rgba(255, 255, 255, 0.1) 4px
    );
  }
}
