.status-count-trigger {
  width: min-content;
}

.icon-accepted {
  .icon svg {
    fill: $rootkit__color-blue;
  }
}

.status-pill-child {
  height: 20px;
  min-width: 50px;
  display: flex;
  align-items: center;

  &.accepted {
    .icon svg {
      fill: $rootkit__color-grey;
    }
  }

  .icon {
    margin-right: 5px;
  }
}
