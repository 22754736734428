@use '@carbon/react/scss/type';
@use '@randori/rootkit/src/styles/variables';

.dash-implants {
  @include ff-grid-hax;

  background-color: variables.$rootkit__color-grey--darker;
  border-radius: 3px 0 0 3px;
  text-align: center;
  border-right: 0;
  display: flex;
  flex-direction: column;
  padding: 25px;
  flex: 0.17 0.17 0;

  &:hover {
    background-color: variables.$rootkit__color-grey--dark;
  }
}

.l-page--dashboard {
  .card--pad {
    padding: 15px;
  }
}

.dash-activity.card--pad {
  padding: 0;
}

.dashboard-top-row-widgets {
  grid-column: 2/10;
  grid-row: 1/2;
  width: 100%;

  .card--pad {
    padding: 0;
    border-top: none;
  }
}

.dash-tt-list {
  @include ff-grid-hax;

  grid-column: 2/6;

  > .card {
    height: 100%;
  }
}

.dash-items-of-interest {
  @include ff-grid-hax;

  grid-column: 2/6;
  grid-row: span 2;
}

.dash-user-tags {
  @include ff-grid-hax;

  grid-column: 6/10;
  grid-row: span 2;

  &--fullwidth {
    grid-column: 2/14;
  }
}

.dash-graph {
  @include ff-grid-hax;

  grid-column: 2/10;
  grid-row: 2;
}

.dash-categories {
  @include ff-grid-hax;

  grid-column: 2/10;
  height: 380px;
}

.dash-graph-title {
  margin: 0;
  padding: 0;
  padding-right: 10px;
}

.dash-tt-unknown {
  position: absolute;
  padding: 4px 10px;
  bottom: 30px;
  left: 30px;
  font-size: 14px;
  background: var(--rootkit__palette--white);
  border: 1px solid variables.$rootkit__color-blue--lighter;
  border-radius: 14px;
}

// tt-list
// ---------------------------------------------------------------------------

.dash-tt-list .box {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 0;
  min-height: 360px;

  > .tt-list.box-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .box-link {
    display: flex;
    align-items: center;
    height: 60px;

    a {
      padding: 0;
      font-size: 14px;
    }
  }
}

.target-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 2;

  > div {
    margin-left: 1rem;
  }

  > div:first-child {
    margin-left: 0;
  }
}

.target-ip-count {
  font-size: 12px;
}

.target-temptation {
}

// ---------------------------------------------------------------------------

.dash-graph-container {
  position: relative;

  // display: flex;
  // flex: 6;

  height: 200px;

  .area-graph {
    // margin: 1rem;
  }

  .loader {
    margin: auto;

    // margin-top: 20%;
  }
}

.graph {
  position: relative;

  // height: 100%;
  // width: 100%;

  // display: flex;
  // flex-direction: column;
}

.tt-unknown {
  @include r-link;
}

.dash-latest-findings {
  @include ff-grid-hax;

  grid-column: 2/14;
  grid-row: span 2;
  display: flex;
  flex-direction: column;

  .latest-findings-action-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      margin: 0;
    }

    .latest-findings-actions {
      display: flex;
    }

    .latest-findings-by {
      margin-right: 10px;
    }
  }

  .latest-findings-graphs {
    display: flex;

    .latest-findings-graph {
      flex: 1;
      height: 200px;
      border: 1px solid variables.$rootkit__color-blue--lighter;
      padding: 10px;
    }
  }
}

.dash-priorities {
  @include ff-grid-hax;

  grid-column: 10/14;
  grid-row: span 2;
}

.dashboard-favorite-saved-views {
  @include ff-grid-hax;

  grid-column: 10/14;
  grid-row: span 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dash-activity {
  @include ff-grid-hax;

  grid-column: 2/10;
  grid-row: 3;
  padding: 0;
}

.dashboard-section__header {
  .carbon & {
    @include type.font-weight('semibold');
  }
}
