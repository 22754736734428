.count {
}

.count-trigger {
  width: min-content;

  &:hover {
    color: $rootkit__color-red;
  }
}

.count--trigger--no-data {
  height: 100%;
  width: 100%;
  text-align: center;
}

.count-tooltip {
  max-width: 20rem;
  font-family: $rootkit__font--body;

  ul {
    max-width: 100%;
    padding: 0;
  }

  li {
    @include rootkit__list-reset;

    padding-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-of-type {
      padding: 0;
    }

    &.additional-results-message {
      font-style: italic;
      margin-top: 5px;
      opacity: 0.7;
    }
  }
}
