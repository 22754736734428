.my-tags-container {
  .all-count {
    color: $rootkit__color-grey--light;
  }

  .table-container {
    width: 100%;
  }

  th,
  td {
    text-align: center;
    width: 16%;
    border: none;
  }

  .table-divider {
    width: 1px;
    background-color: var(--rootkit__palette--gray200);
    height: 240px;
    margin: 15px 20px 0;
  }
}
