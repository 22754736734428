.__json-pretty__ {
  @include rootkit__typography--body2;

  font-family: monospace;
  color: $rootkit__color-grey;
  overflow: auto;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

.__json-pretty__ .__json-key__ {
  color: $rootkit__color-red;
}

.__json-pretty__ .__json-value__ {
  color: var(--rootkit__palette--orange500);
}

.__json-pretty__ .__json-string__ {
  color: var(--rootkit__palette--blue500);
}

.__json-pretty__ .__json-boolean__ {
  color: $rootkit__color-grey--light;
}

.__json-pretty-error__ {
  line-height: 1.3rem;
  color: var(--rootkit__palette--green300);
  background: var(--rootkit__palette--gray700);
  overflow: auto;
}

.json-tree {
  margin: 0.5rem 0 2rem;
  padding: 0.5rem;
  border: solid 1px $rootkit__color-blue--lighter;
  border-radius: 3px;

  background: var(--rootkit__palette--gray100);

  .__json-pretty__ {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
  }
}

.json-tree--result-artifacts {
  border: none;
}
