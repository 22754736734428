.business-context {
}

.business-context-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.business-context-info {
}

.business-context-info:not(:last-child) {
  margin-right: 0.5rem;
}

.business-context-info-content {
  .external-link {
    @include r-link;

    display: block;
    margin-top: 0.5rem;
  }
}

.business-context-desc {
}
