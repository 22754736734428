.ip {
  @include entity;

  .accordion-card-cell {
    min-width: 0;
    white-space: normal;
    word-wrap: break-word;
  }

  .accordion-card--nested {
    .accordion-card-primary {
      justify-content: flex-end;
      padding: 0 12px;
    }
  }

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
    }
  }

  .ip-version {
    color: $rootkit__color-grey--light;
    margin-left: 0.5rem;
    font-weight: 400;
  }

  .ip-thumbnail {
    width: 82px;
    border: 1px solid rgba($rootkit__color-grey--light, 0.5);
  }

  .empty-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    background-color: var(--rootkit__palette--gray200);

    .empty-image-message {
      font-size: 0.5rem;
    }
  }

  .external-link {
    flex-wrap: nowrap;
  }

  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 0;
  }
}

.ip-info-header {
  @include entity-info;

  padding: 0 40px;
  color: $rootkit__color-grey--darker;
  background-color: $rootkit__color-grey--lighter;
}

.ip-info {
  @include entity-info;

  .box-list {
    width: 100%;

    .box-list-item {
      border: none;
      padding: 10px;

      &:nth-child(odd) {
        background-color: var(--rootkit__palette--white);
      }
    }
  }
}

.ip-info-item {
  @include entity-info-item;

  .hdyft-trigger {
    .icon svg {
      stroke: $rootkit__color-grey;
    }
  }
}

.ip-tags {
  .ip-info-item {
    @include entity-info-item(2.5);
  }
}

.ip-info--desc,
.ip-info--notes {
  .ip-info-item {
    @include entity-info-item(1);
  }
}

.ip-info-item-label {
  @include entity-info-item-label;
}

.ip-info-card-label {
  display: flex;
  flex-grow: 1;

  // margin-left: 1rem;

  .ip-info-card-col {
    flex: 1;
  }
}

.ip-artifact-trigger {
  transition: opacity 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.ip-additional-details {
  display: flex;

  .ports-for-ip {
    flex: 1;

    .ip-info {
      flex-direction: column;
      padding-right: 40px;
    }
  }

  .hostnames-for-ip {
    flex: 1;

    .ip-info {
      flex-direction: column;
      padding-right: 40px;
      padding-left: 15px;
    }
  }
}
