.details-page--implant {
  .leader {
    @include ff-grid-hax;

    min-height: 170px;
    margin-bottom: 20px;
    padding: 0 20px;
    background-color: var(--rootkit__palette--white);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    @include desktopXL {
      padding: 40px calc(10% + 20px);
    }

    @extend .detail-section-header;
  }

  .follower {
    @extend %l-page;

    grid-gap: 20px;
    grid-column: full-bleed-start / full-bleed-stop;
  }

  .l-first {
    @include ff-grid-hax;

    grid-column: 2/8;
  }

  .l-second {
    @include ff-grid-hax;

    grid-column: 8/14;
  }

  .status {
    display: inline-block;
  }
}

.host-profile {
  .box-title {
    font-weight: normal;
  }

  .implant-ip-list {
    .box-list-content {
      white-space: initial;
    }
  }

  .implant-host-list {
    .box-list-content {
    }
  }
}

.implant-callbacks {
  .box-title {
    font-weight: normal;
  }
}

.host-profile-leader,
.implant-callbacks-leader {
  display: flex;
  align-items: baseline;

  h2 {
    margin-right: 10px;
  }

  .info-popper {
    margin-left: auto;
  }
}
