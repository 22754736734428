/* stylelint-disable */
@media print {
  .details-page--report .details-page {
    width: 100%;
  }

  .report-date-range {
    margin-top: 56px !important;
  }

  .report-summary-label {
    margin-bottom: map-get($spacer-map, 3);

    h1,
    h2,
    h3 {
      color: var(--rootkit__palette--black);
    }
  }

  .report-title {
    width: initial;
  }

  // header
  // -------------------------------------------------------------------------

  .detail-header {
    margin: 0;

    h1 {
      margin-bottom: 0;
    }

    h2 {
      margin: 0;
    }

    h3 {
      margin-top: 2.2rem;
    }
  }

  .details-page--report .detail-header {
    align-items: flex-start;

    padding: 0;
    margin: 0;

    background-color: var(--rootkit__palette--white);
    border-bottom: none;
  }

  // body
  // -------------------------------------------------------------------------

  .details-page--report .detail-body {
    margin: 0;
  }

  .detail-body > .accordion-card {
    display: none;
  }

  .detail-body > .accordion-card.is-expanded {
    display: block;
    border: none;
  }

  .accordion-card-expand {
    display: none;
  }

  .report-summary-label {
    width: 100%;

    h3 {
      font-size: 2em;
      margin: 0;
      padding: 0 0 0.5rem 0;

      border-bottom: 1px solid $rootkit__color-blue--lighter;
    }
  }

  .report-accordion .accordion-card-primary {
    padding: 0;
    background-color: transparent;
  }

  .report-accordion .accordion-card-meta--nested {
    background-color: transparent;
    border-bottom: none;
  }

  .report-accordion .accordion-card-secondary .accordion-section {
    margin: 0;
    padding: 0;
  }

  // Executive Summary
  // -------------------------------------------------------------------------

  .exec-summary-info {
    padding: 0;
  }

  .exec-summary-counts {
    width: 100%;

    padding: 0;

    border-bottom: none;

    grid-template-columns: repeat(4, 1fr);

    .summary-count {
      height: auto;
      border: 0;
    }
  }

  .exec-summary-entity-counts {
    display: flex;

    > div {
      width: 50%;
    }
  }

  .exec-summary-targets {
    .total-targets {
      .stat-header {
        width: 100%;
      }
    }
  }

  // glossary
  // -------------------------------------------------------------------------

  .glossary-section {
    padding: 0;

    h3 {
      color: var(--rootkit__palette--black);
    }
  }

  // matrix
  // -------------------------------------------------------------------------

  .risk-matrix,
  .target-age-matrix {
    zoom: 80%;
    page-break-inside: avoid;
  }

  .risk-matrix-x-label,
  .risk-matrix-y-label,
  .target-age-matrix-x-label,
  .target-age-matrix-y-label {
    background: transparent;
  }

  // reset borders
  .risk-matrix-cell,
  .risk-matrix-cell:last-child,
  .risk-matrix-column-labels > div,
  .risk-matrix-column-labels > div:last-child,
  .risk-matrix-row-labels > div,
  .risk-matrix-row-labels,
  .risk-matrix-row:first-child,
  .risk-matrix-spacer,
  .target-age-matrix-cell,
  .target-age-matrix-cell:last-child,
  .target-age-matrix-column-labels > div,
  .target-age-matrix-column-labels > div:last-child,
  .target-age-matrix-row-labels > div,
  .target-age-matrix-row-labels,
  .target-age-matrix-row:first-child,
  .target-age-matrix-spacer {
    border: none;
  }

  .risk-matrix-cell,
  .target-age-matrix-cell {
    color: var(--rootkit__palette--black) !important;
  }

  .risk-matrix-row {
    > .risk-matrix-cell {
      border-right: 1px solid $rootkit__color-blue--lighter;
      border-bottom: 1px solid $rootkit__color-blue--lighter;

      &:last-child {
        border-right: none;
      }
    }

    &:last-child {
      > .risk-matrix-cell {
        border-bottom: none;
      }
    }
  }

  .target-age-matrix-row {
    > .target-age-matrix-cell {
      border-right: 1px solid $rootkit__color-blue--lighter;
      border-bottom: 1px solid $rootkit__color-blue--lighter;

      &:last-child {
        border-right: none;
      }
    }

    &:last-child {
      > .target-age-matrix-cell {
        border-bottom: none;
      }
    }
  }

  // target-rows
  // -------------------------------------------------------------------------

  .high-priority-target-rows {
    zoom: 70%;
    page-break-inside: avoid;

    position: relative;

    margin: 2rem 0 !important;
    padding: 0;

    overflow-x: initial !important;
  }

  // etc
  // -------------------------------------------------------------------------

  .block-title {
    h3 {
      color: var(--rootkit__palette--black);
    }
  }

  .report-download {
    display: none;
  }

  .high-priority-target-info {
    position: relative;
    @include clearfix;

    display: initial;
    .accordion-card-cell:last-child {
      display: none;
    }
  }

  .high-priority-target-info .high-priority-stats {
    @include clearfix;
    display: initial;

    .high-priority-targets {
      float: left;
      width: calc(100% / 3);

      margin-right: 24px;
    }
  }

  .target-age-desc {
    @include clearfix;

    width: 100%;
  }

  .stats-summary {
    display: initial;

    .stats-pie-chart {
      float: left;
      width: calc(100% / 5);
    }
  }

  .stat-header {
    color: var(--rootkit__palette--black);
  }

  p.accordion-section {
    margin-bottom: 1rem !important;
  }

  .target-age-desc > h3 {
    color: var(--rootkit__palette--black);
  }

  .stat-box {
    padding: map-get($spacer-map, 2);
  }

  .active-entities-container,
  .active-entity-counts,
  .peer-stats-container,
  .stat-box,
  .risk-matrix,
  .risk-matrix-legend,
  .target-age-matrix-data,
  .target-age-matrix-legend,
  .top-characteristics-counts {
    /* Keeps background color */
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    print-color-adjust: exact !important; /* Firefox */
  }

  .active-entity-stat {
    padding: map-get($spacer-map, 2);
  }

  .attack-surface-info .active-entities-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .attack-surface-description {
    padding-top: 0 !important;
  }

  .prioritization-matrix-info {
    padding-top: 0 !important;
  }

  .exec-summary-addl--flex {
    display: block;

    > div {
      width: 100%;
    }

    > div:first-child {
      margin-right: 0;
    }
  }

  .peer-stats,
  .prioritization-matrix {
    break-before: page;
    page-break-before: always;

    .peer-stats-container {
      height: 310px !important;
    }
  }

  //Firefox specific formatting
  @-moz-document url-prefix() {
    .exec-summary-disclaimer {
      margin-top: 100px;
    }
    .prioritization-matrix {
      margin-top: 0px;
    }
    .active-entities,
    .top-characteristics {
      margin-top: 300px;
    }
  }

  //Safari specific formatting
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      .exec-summary-counts {
        height: 100px;
      }
      .prioritization-matrix {
        margin-top: -300px;
      }
      .target-age-desc {
        break-before: page;
      }
    }
  }

  .accordion-card-meta {
    break-after: page;
  }

  .peer-stats-percentage-label {
    margin: 0 auto;
  }

  .info-popper {
    display: none;
  }
}
/* stylelint-enable */
