.entity-detail__meta-data__item .dc-schema-url {
  &::after {
    margin: 0 10px;
    width: 1em;
    height: 1em;
    background-position-y: 0.1em;
    background-size: 1em;
  }
}

.request-pen-test-description {
  padding-top: 17.5px;
}

.request-pen-test-header {
  display: flex;
  align-items: center;
}

.request-pen-test-header-element {
  margin: 0 5px;
}

.request-pen-test-label {
  color: var(--rootkit__palette--gray500);
  font-size: 10px;
  font-weight: 600;
  line-height: 17.5px;
  letter-spacing: 1.5px;

  padding-top: 17.5px;
}

.field-content-container {
  background-color: var(--rootkit__palette--gray100);
  padding-left: 10px;
  border-radius: 0.2rem;
  border: 1px solid var(--rootkit__palette--gray300);
}

.selected-target-content {
  display: flex;
  align-items: center;
}

.selected-target-content p {
  margin-top: 0;
  padding-top: 0;
}

.selected-target-fields {
  color: var(--rootkit__palette--gray500);
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1.5px;

  margin-right: 12px;
}

.selected-target-id {
  color: var(--rootkit__palette--gray900);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 12px;
}

.selected-target-ip-label {
  color: var(--rootkit__palette--gray900);
  font-size: 12px;
  font-weight: 400;

  margin-right: 3px;
}

.selected-target-location {
  color: var(--rootkit__palette--gray900);
  font-size: 12px;
  font-weight: 400;
  text-decoration-line: underline;

  margin-right: 6px;
}

.user-info-content {
  font-size: 12px;
  font-weight: 400px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.vendor-version-name {
  font-size: 12px;
  font-weight: 700;
}
