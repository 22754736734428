$breakpoint-xl: 1500px;
$breakpoint-l: 1224px;
$breakpoint-m: 1024px;
$breakpoint-s: 768px;

@mixin break($width) {
  @media (min-width: #{$width}) {
    @content;
  }
}

@mixin desktopXL {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

@mixin desktopL {
  @media (min-width: #{$breakpoint-l}) {
    @content;
  }
}

@mixin desktopM {
  @media (min-width: #{$breakpoint-m}) {
    @content;
  }
}

@mixin desktopSm {
  @media (max-width: #{$breakpoint-s}) {
    @content;
  }
}
