.potential-vunerabilities {
  display: flex;
}

// single-column at breakpoint
.potential-vunerabilities--single {
  display: block;
}

.potential-vunerabilities__column {
  flex: 1 0 400px;
  margin: 0 16px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  // single-column at breakpoint
  .potential-vunerabilities--single & {
    margin: 0;
  }

  .cve-table__row-grid {
    border-bottom: solid 1px $rootkit__palette--gray200;
    padding-left: 5px;
    text-transform: capitalize;
  }
}

.potential-vunerabilities_links {
  margin-top: 24px;

  .reset-margins {
    margin: 0;
  }

  .reset-margins,
  .external-link {
    margin-left: 5px;
  }

  .reset-margins--single {
    margin-top: 1rem;
  }
}

.potential-vunerabilities__header {
  display: flex;
  align-items: baseline;
}

.potential-vunerabilities__header--large {
  margin: 0 5px 0 0;
}

.potential-vunerabilities__header--small {
  padding: 16px 5px 16px 0;
  color: var(--rootkit__palette--gray500);
}
