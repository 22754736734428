.box-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.box-list-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid $rootkit__color-grey--lighter;

  &:last-child {
    border-bottom-color: transparent;
  }

  .text--muted {
    opacity: 0.6;
  }

  a {
    @include r-link($linkColor: $rootkit__color-red);

    .icon {
      margin-left: 10px;

      svg,
      svg g {
        stroke: $rootkit__color-red;
        fill: $rootkit__color-red;
      }
    }
  }

  &.box-list-item-undecorated-link {
    > a {
      @include r-link;
    }

    .external-link {
      opacity: 0.5;
    }
  }

  &.box-list-item--muted {
    background: rgb(196, 196, 196);
  }
}

.box-list-content {
  flex: 0 1 auto;
  min-width: 1.75rem;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:first-child {
    min-width: 3.5rem;
  }

  &:last-child {
    margin-right: 0;
    margin-left: auto;
  }
}

// box-list--nested
// ---------------------------------------------------------------------------

.box-list--nested {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: 0.5rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      padding-top: 0;
    }
  }
}

.box-list-item--nested {
  align-items: flex-start;
}

.box-list-content--nested {
  &:first-child {
    min-width: 9rem;
  }
}

// box-list--table
// ---------------------------------------------------------------------------

.box-list--table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  vertical-align: middle;
}

.box-list-item--table-header {
  @extend .box-list-item--table-row;

  font-weight: 700;
  color: var(--rootkit__palette--gray500);
}

.box-list-item--table-row {
  display: table-row;
  height: 4rem;
}

.box-list-content--table-cell {
  display: table-cell;
  vertical-align: middle;
  min-width: auto;
  margin-right: 0;
  padding: 1rem 20px;

  &:first-child {
    padding-left: 0;
    min-width: auto;
  }

  &:last-child {
    padding-left: 0;
  }
}
