.target-count-box {
  padding: 20px;
  text-align: center;

  &--critical,
  &--high {
    background: $rootkit__color-red;
    border-color: $rootkit__color-red;
  }

  &--medium {
    background: $rootkit__color-orange;
    border-color: $rootkit__color-orange;
  }

  &--low,
  &--inreview,
  &--in_review,
  &--in-review {
    background: $rootkit__color-grey;
    border-color: $rootkit__color-grey;
  }
}
