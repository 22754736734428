.runbook-meta-list {
  &--has-addl {
  }
}

.runbook-meta-list__addl {
  display: inline;
  margin-left: 0.5rem;
}

.runbook-meta-list__addl-trigger {
  @include rootkit__typography--body2;
}

.runbook-meta-list__addl-popper {
}
