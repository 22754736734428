.add-global-setting-modal {
  // override weird positioning of active dot
  .input-radio label::after {
    top: 3px;
    left: 3px;
  }

  .input-radio {
    opacity: 0.2;
  }

  .input-radio--is-checked {
    opacity: 1;
  }

  .warning {
    color: $rootkit__color_red;
  }

  .add-global-setting-options {
    @include rootkit__list-reset;

    > li {
    }
  }

  .add-global-setting-value {
    padding: 20px 0;
    margin: 20px 0;
  }

  .add-global-setting-form-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid $rootkit__color-blue--lighter;

    > .button:first-child {
      margin-right: 0.5rem;
    }
  }
}
