.attacker-perspective {
  display: flex;
}

.attacker-perspective p {
  line-height: 1.5rem;
  word-break: break-word;
}

.attacker-perspective h4 {
  margin: 0;
}

.attacker-perspective-column {
  min-width: 0;
  flex: 1 1 0;
  padding: 0 map-get($spacer-map, 2);
}

.attacker-perspective-column:not(:last-child) {
  border-right: 1px solid var(--rootkit__palette--gray200);
}

.attacker-perspective-column-item {
  margin-bottom: 36px;
}

.attacker-perspective .tt-graph-container {
  width: initial;
  margin-bottom: 2rem;
}

.target-notes {
}

.target-description-source {
  @include rootkit__typography--caption;
  color: var(--rootkit__palette--gray400);
  font-style: italic;
  margin-right: auto;
}

.target-description-source--padded {
  margin-top: 0.625rem;
}

.target-description {
}

.target-description__section {
  margin-bottom: 1.5rem;
}

.target-description-label,
.target-notes-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.target-context-description {
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  margin: 8px 0;
}

.target-context-header {
  display: flex;
  align-items: center;
  margin: 15px 0 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;

  .info-popper {
    margin-left: 0.5rem;
  }
}

.target-context-view-ext-cve {
  margin-top: 30px;
  margin-bottom: 30px;
}

.target-context-external-link {
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  word-break: break-all;

  &.external-link::after {
    background-size: 14px 14px;
    width: 14px;
    background-position-y: 0;
  }
}

.cve-table {
  margin-bottom: 20px;
}

.cve-table__row-grid {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  grid-gap: 10px;
  padding-top: 4px;
  padding-bottom: 4px;

  &:nth-child(even) {
    background-color: var(--rootkit__palette--white);
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
  }

  .target-context-sub-header {
    font-weight: 700;
    font-size: 10px;
    line-height: 36px;
  }

  .critical {
    color: var(--rootkit__palette--black);
  }

  .high {
    color: $rootkit__color-red;
  }

  .medium {
    color: $rootkit__color-orange;
  }

  .low {
    color: $rootkit__color-grey;
  }
}
