.activities-report {
}

.activities-report__inner {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
}

.activities-report__leader {
  position: relative;
}

.activities-report__summary {
}

.activities-report__summary__category {
}

.activities-report__summary__list {
  display: flex;
}

.activities-report__summary__list-item {
  flex: 1 1;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  border: 1px solid var(--rootkit__palette--gray300);
  background-color: var(--rootkit__palette--white);
}

.activities-report__summary__list-item__label {
  margin: 0;
  margin-right: auto;
}

.activities-report__summary__list-item__value {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;

  margin: 0;
}

.activities-report__box-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-gap: map-get($spacer-map, 2);
  margin: map-get($spacer-map, 2) 0;
}

.activities-report__box-stats__category {
}

.activities-report__box-stats__list {
  display: flex;
}

.activities-report__box-stats__list-item {
  flex: 1 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem;

  border: 1px solid var(--rootkit__palette--gray300);
  background-color: var(--rootkit__palette--white);
  border-top: 7px solid var(--rootkit__palette--gray300);

  &.red {
    border-top: 7px solid var(--rootkit__palette--red600);
  }

  &.green {
    border-top: 7px solid var(--rootkit__palette--green600);
  }

  &.blue {
    border-top: 7px solid var(--rootkit__palette--blue500);
  }

  &.blue2 {
    border-top: 7px solid var(--rootkit__palette--blue200);
  }

  &.orange {
    border-top: 7px solid var(--rootkit__palette--orange600);
  }
}

.activities-report__box-stats__list-item__label {
  margin: 0;
}

.activities-report__box-stats__list-item__value {
  margin: 0;
}

.activities-report__table {
  background-color: var(--rootkit__palette--white);
  border: 1px solid var(--rootkit__palette--gray200);
}

.activities-report__row-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: map-get($spacer-map, 2);
  margin-bottom: map-get($spacer-map, 3);
}

.activities-report__row-stats__category {
}

.activities-report__row-stats__category-label {
}

.activities-report__row-stats__category__rows {
  background-color: var(--rootkit__palette--white);
  border: 1px solid var(--rootkit__palette--gray200);

  .external-link::after {
    margin: 0 0 0 3px;
    background-size: 10px 10px;
    background-position-y: 2px;
    width: 10px;
  }
}

.activities-report__row-stats__category__row {
  display: flex;
  justify-content: space-between;
  padding: 0 map-get($spacer-map, 2);

  &:not(:last-child) {
    border-bottom: 1px solid var(--rootkit__palette--gray200);
  }
}

.activities-report__row-stats__category__row-label {
  max-width: calc((100% / 3) * 1.5);
  margin: 1rem 0;
}

.activities-report__row-stats__category__row-value {
  font-size: 30px;
  display: flex;
  align-items: center;

  .stat-delta {
    font-size: 14px;
    margin-left: map-get($spacer-map, 2);
  }
}

.activities-report__summary__download {
  position: absolute;
  top: 0;
  right: 0;
}
