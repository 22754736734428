.modal-dialog {
  // position: fixed;
  // z-index: $z-foreground + 2;
  // overflow: visible;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
