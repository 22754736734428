.artifact {
  @include entity;

  .org-link {
    > a {
      @include link-reset;
      @include r-link;
    }
  }

  .full-detection {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .full-detection-tabs {
    position: absolute;
    right: 0;
    bottom: -12px;
  }

  .detection-tab {
    padding: 0.5rem 1rem;
    background-color: var(--rootkit__palette--gray300);
    border-radius: 0;
    border: none;
    color: $rootkit__color-grey--light;

    &:first-child {
      margin-right: 2px;
    }

    &--is-active {
      background-color: var(--rootkit__palette--gray100);
      color: var(--rootkit__palette--gray600);
    }
  }

  .detection--rendered {
    font-family: monospace;
  }

  .detection-row {
    line-height: 1.25rem;
  }

  .detection-label {
    display: inline-block;
    width: 7rem;
  }

  .detection-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $rootkit__color-blue--lighter;

    > .button {
      margin-right: 0.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .artifact-download {
    @include link-reset;
    @include r-link;
  }

  .card-actions {
  }

  .artifact--screenshot {
    > img {
      max-width: 100%;
    }
  }

  .artifact-actual-label {
  }

  .data-hash {
    color: $rootkit__color-grey--light;
    font-weight: 400;
  }

  .http-content {
    max-height: 30vh;
    overflow: auto;
  }
}
