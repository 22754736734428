.high-priority-target-info {
  display: flex;
  flex-direction: column;
  padding: 24px 0;

  .high-priority-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: map-get($spacer-map, 2); // can I use??

    .high-priority-targets {
      grid-column: auto / span 2;
    }

    .section-description {
      grid-column: auto / span 5;
    }
  }
}

.high-priority-target-rows {
  margin-top: map-get($spacer-map, 3);
  overflow-x: hidden;
}
