// style overrides

.klaro {
  --blue1: var(--rootkit__palette--red600);
  --dark2: var(--rootkit__palette--gray300);
  --green1: var(--rootkit__palette--green600);
  --green2: var(--rootkit__palette--green600);
  --notice-max-width: calc(100vw - 8rem);

  .cookie-modal .cm-bg {
    background: var(--rootkit__modal-overlay);
    backdrop-filter: blur(1.5px);
  }

  .cookie-modal .cm-modal.cm-klaro {
    color: var(--rootkit__palette--gray700);
    background: var(--rootkit__palette--white);
    padding: 0 1.5rem;
  }

  .cookie-modal,
  .cookie-notice {
    h1,
    h2,
    li,
    p,
    strong,
    ul {
      color: var(--rootkit__palette--gray700);
    }
  }

  .cookie-notice:not(.cookie-modal-notice) {
    background: var(--rootkit__palette--white);
  }

  .cm-powered-by {
    display: none !important;
  }

  .cookie-modal .cm-modal .cm-header {
    padding: 1.5rem 0;
  }

  .cookie-modal .cm-modal .cm-body {
    padding: 1.5rem 0;
  }

  .cookie-modal .cm-modal .cm-footer {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .cookie-notice:not(.cookie-modal-notice) .cn-body p {
    margin: 0;
  }

  .cookie-notice:not(.cookie-modal-notice) .cn-body {
    padding: 1.5rem;
  }

  .cookie-modal .cm-btn,
  .cookie-notice .cm-btn {
    @extend .button;
    @extend .button--secondary;
  }

  .context-notice .cm-btn.cm-btn-info,
  .context-notice .cm-btn.cm-btn-success,
  .cookie-modal .cm-btn.cm-btn-info,
  .cookie-modal .cm-btn.cm-btn-success,
  .cookie-notice .cm-btn.cm-btn-info,
  .cookie-notice .cm-btn.cm-btn-success {
    @extend .button--primary;
  }

  .purposes {
    text-transform: capitalize;
  }

  .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
    flex-grow: initial;
    margin-right: 1rem;
  }
}

.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
  opacity: 1;
}
