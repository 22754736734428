@use '@carbon/react/scss/components/data-table';
@use '@carbon/react/scss/components/data-table/action';
@use '@carbon/react/scss/components/data-table/sort';
@use '@carbon/react/scss/components/pagination';
@use '@carbon/react/scss/components/popover';
@use '@carbon/react/scss/components/contained-list';
@use '@carbon/react/scss/components/text-area';
@use '@carbon/react/scss/components/modal';
@use '@carbon/react/scss/components/date-picker';
@use '@carbon/react/scss/components/time-picker';
@use '@carbon/react/scss/components/multiselect';
@use '@carbon/react/scss/components/select';

.p-3 {
  padding: 1rem;
}

.cds--popover-content.p-3 {
  inline-size: 16rem;
}

.entity-summary-table-container {
  overflow-x: auto;
  overflow-y: auto;
}

@mixin border($side, $color: var(--rootkit__palette--gray200)) {
  border-#{$side}: 1.5px solid $color;
  transform: scale(0.999);
}

.entity-summary-table {
  --cell-bg: var(--rootkit__palette--white);
  --focused-row-color: var(--rootkit__palette--gray200);
  --min-cell-width: 60px;

  position: relative;
  border-collapse: separate;

  .rootkit__table__row {
    border-bottom: none;
  }

  .rootkit__table__header {
    @include border(bottom);

    top: 0;
    position: sticky;
    z-index: 1;
    background: var(--cell-bg);
  }

  .rootkit__table__cell {
    @include border(bottom);

    background: var(--cell-bg);
  }

  .rootkit__table__header:first-child,
  .rootkit__table__cell:first-child {
    padding-left: 28px;
  }

  .rootkit__table__header.ui-checkbox {
    @include border(right);

    left: 0;
    position: sticky;
    z-index: 4;

    padding-left: 1rem;
  }

  .rootkit__table__header.ui-actions {
    @include border(left);

    position: sticky;
    right: 0;
    z-index: 4;
  }

  .rootkit__table__cell.ui-checkbox {
    @include border(right);

    left: 0;
    position: sticky;
    z-index: 1;

    width: var(--min-cell-width);
    padding-left: 1rem;
  }

  .rootkit__table__cell.ui-actions {
    @include border(left);

    position: sticky;
    right: 0;
    z-index: 1;

    width: var(--min-cell-width);
  }

  .rootkit__table__header.icon-only,
  .rootkit__table__cell.icon-only {
    min-width: var(--min-cell-width);
    text-align: center;
  }

  .rootkit__table__row--is-focused {
    .rootkit__table__cell {
      background-color: var(--focused-row-color);
    }

    .rootkit__table__cell:first-child {
      @include border(right, var(--rootkit__palette--gray300));
    }

    .rootkit__table__cell:last-child {
      @include border(left, var(--rootkit__palette--gray300));
    }
  }

  .is-sortable:hover {
    cursor: pointer;
  }

  .is-sortable .icon {
    margin-left: 0.5rem;
  }

  .is-sortable svg {
    fill: var(--rootkit__palette--gray300);
    stroke: var(--rootkit__palette--gray300);
  }

  .impact-trigger {
    display: flex;
    justify-content: center;
  }
}
