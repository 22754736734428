.assets-list-table {
  .is-sortable:hover {
    cursor: pointer;
  }

  .is-sortable .icon {
    margin-left: 0.5rem;
  }

  .is-sortable svg {
    fill: var(--rootkit__palette--gray300);
    stroke: var(--rootkit__palette--gray300);
  }
}

.assets-list-table--view-most-recent {
  color: var(--rootkit__palette--gray700);
}
