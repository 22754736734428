.org-navigation-mobile {
  background: var(--rootkit__palette--gray700);
  border: 0;
  position: relative;

  .org-navigation-mobile-top-list {
    @include rootkit__list-reset;

    padding: 0 30px 0 70px;
  }

  li {
    margin: 0.5rem 0;
    padding: 0;
    font-family: $rootkit__font--heading;
    letter-spacing: 0.2px;
    cursor: pointer;
    border-radius: 0;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .location-label {
    margin-left: 50px;
  }

  .state {
    display: flex;
    justify-content: space-around;
    width: 30px;
  }

  .org-navigation-mobile-header {
    display: flex;
    align-items: center;
    height: 60px;
    color: var(--rootkit__palette--white);
    font-size: 20px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.55);

    .icon svg {
      fill: var(--rootkit__palette--white);
      stroke: var(--rootkit__palette--white);
    }
  }

  .org-navigation-mobile-contents {
    display: none;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    &--is-open {
      display: block;
      padding-top: 10px;
    }
  }

  .navigation-menu-item {
    &.is-child-active {
      background: initial;
    }
  }

  .navigation-menu-item-inner {
    opacity: 1;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navigation-menu-item a {
    border-radius: 3px;
  }

  .navigation-menu-item.is-active a {
    background: $rootkit__color-grey--dark;
  }

  .navigation-submenu-item.is-active a {
    background: $rootkit__color-grey--dark;
  }
}
