$ph-bg: var(--rootkit__palette--white) !default;
$ph-border-radius: 2px !default;

$ph-gutter: 30px !default;
$ph-spacer: 15px !default;

:root {
}

a:hover {
  text-decoration: underline;
}

a.button {
  &:hover {
    text-decoration: initial;
  }
}

.ph-item {
  &,
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: $ph-gutter;
  background-color: $ph-bg;
  transition: all 0.25 ease-in-out;

  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: phAnimation 0.8s linear infinite;
    background: linear-gradient(
        to right,
        rgba($ph-bg, 0) 46%,
        rgba($ph-bg, 0.35) 50%,
        rgba($ph-bg, 0) 54%
      )
      50% 50%;
  }

  > * {
    flex: 1 1 auto;
    display: flex;
    flex-flow: column;
    padding-right: calc($ph-gutter / 2);
    padding-left: calc($ph-gutter / 2);
  }
}
