.tab-group {
}

.tab-group li {
}

.tab-group li[aria-disabled='true'] {
  &:hover {
    cursor: initial;
  }
}
