$recon-actions-dropdown-text-color: var(--rootkit__palette--gray500);

.recon-actions-dropdown-text {
  font-family: var(--rootkit__font--heading);
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  color: $recon-actions-dropdown-text-color;

  svg {
    stroke: $recon-actions-dropdown-text-color;
  }
}

.recon-actions-dropdown-selector-stage {
  @include rootkit__list-reset;
  @extend .recon-actions-dropdown-text;

  margin: 15px;
  min-width: 270px;
}

.recon-actions-dropdown-field-selector {
  @include rootkit__list-reset;
  @extend .recon-actions-dropdown-text;
}

.recon-actions-dropdown-action-comment {
  min-width: 270px;
}

.recon-actions-dropdown-action-footer {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.recon-actions-dropdown-action-footer-buttons {
  display: flex;
  flex-direction: row;

  div {
    margin-right: 5px;
  }
}

.recon-actions-dropdown-action-back {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-decoration-line: underline;
  color: $recon-actions-dropdown-text-color;
  cursor: pointer;
}

.recon-actions-dropdown-action-back-icon {
  transform: scale(0.7);
}

.recon-actions-dropdown-field-selector-item-selected {
  background-color: var(--rootkit__palette--gray200);
}

.recon-actions-icon-with-text {
  @extend .u-noselect;

  display: flex;
  align-items: center;
  flex-direction: row;
  height: 30px;
  white-space: nowrap;

  > div:first-child {
    width: 30px;
  }
}

.recon-actions-dropdown-action-stage {
  margin: 15px;
}

.recon-actions-dropdown-action-heading {
  @extend .recon-actions-icon-with-text;

  font-size: 14px;
  color: $recon-actions-dropdown-text-color;

  svg {
    stroke: $recon-actions-dropdown-text-color;
  }

  border-bottom: 1px solid var(--rootkit__palette--gray200);
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  padding-bottom: 10px;
}

.recon-actions-dropdown-action-body {
  margin: 0 0.938rem 0.938rem 0.938rem;
}

.recon-actions-dropdown-container {
  display: flex;
  flex-direction: row;
}

.recon-actions-dropdown-selector-item {
  @extend .recon-actions-icon-with-text;

  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
  cursor: pointer;
}

.recon-actions-dropdown-selector-item--disabled {
  color: var(--rootkit__palette--gray300);
  svg {
    stroke: var(--rootkit__palette--gray300);
  }
}

.recon-actions-dropdown-status-selector {
  svg {
    stroke: none;
  }
}

.recon-actions-dropdown-selector-item:hover {
  cursor: pointer;
  background: var(--rootkit__palette--gray100);
}

.recon-actions-dropdown-selector-item--disabled:hover {
  cursor: not-allowed;
  background: unset;
}
