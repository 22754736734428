@mixin btn-clear-active($border: none) {
  button {
    background-color: transparent;
    cursor: pointer;
    border: $border;

    &.button--force-active {
      .icon svg {
        path {
          stroke: $rootkit__color-grey;
        }
      }
    }
  }
}

.entity-sort-trigger {
  height: 32px;
  margin-left: 5px;

  .icon-arrow--down svg {
    fill: none;
  }
}

.entity-sorts {
  padding: 0 1.25rem 0.625rem 1.875rem;
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;

  button {
    &:focus {
      outline: 0;
    }
  }

  .entity-sorts-label {
    color: $rootkit__color-grey;
    margin: 0;
  }
}

.entity-sorts-display {
  display: flex;
  align-items: center;
  flex-flow: wrap;

  .entity-sorts-active {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    cursor: pointer;

    .entity-sorts-then {
      color: $rootkit__color-red;
      margin: 0;
    }

    .icon {
      svg {
        path,
        circle {
          stroke: $rootkit__color-grey;
        }
      }
    }

    .sort-str {
      color: $rootkit__color-grey;
      padding-right: 10px;

      .icon {
        margin-left: 5px;
      }
    }
  }

  &:hover {
    opacity: 0.75;
  }

  &.read-only {
    .entity-sorts-active {
      cursor: default;

      &:first-child {
        padding-left: 0;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
}

.entity-sorts-stacked {
  height: 95%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  .entity-sorts-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
    border: 1px solid $rootkit__color-blue--lighter;
    border-radius: 5px;

    @include btn-clear-active(1px solid $rootkit__color-grey--light);

    .drag-handle {
      border: none;
    }

    .input-checkbox {
      label .label-text {
        font-weight: 700;
        margin-left: 30px;
      }
    }

    .sort-item-title {
      display: flex;
    }

    .sort-item-actions {
      display: flex;
    }
  }

  .sorts-body {
    height: 90%;

    .sorts-active {
      display: flex;
      flex-direction: column;
      height: 50%;
      overflow: hidden;

      h3 {
        padding-bottom: 10px;
        margin-bottom: 0;
      }

      .active-scroll {
        overflow-y: auto;
      }
    }

    .sorts-inactive {
      display: flex;
      flex-direction: column;
      height: 50%;
      overflow: hidden;

      h3 {
        padding-bottom: 10px;
        margin-bottom: 0;
      }

      .inactive-scroll {
        overflow-y: auto;
      }
    }
  }

  .filter-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;

    .button-group {
      width: inherit;

      > .btn-save {
        margin-right: 0;
      }
    }

    .input-checkbox {
      font-size: 12px;
      color: $rootkit__color-grey;
    }
  }
}
