.onboarding-error-container {
  padding: 10px;
  background-color: var(--rootkit__palette--red100);
  border: solid 1px var(--rootkit__palette--red200);
  color: $rootkit__color-red--dark;
  border-radius: 5px;
  margin: 1rem auto;

  ul {
    padding-inline-start: 0;
    margin: 10px 0;
  }

  li {
    font-weight: 200;
    list-style: none;
    padding-bottom: 0.7rem;
  }
}

.auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.auth-segment-inner {
  width: 60%;

  form {
    margin: 2rem auto;
  }

  .add-otp-form {
    display: flex;
    flex-direction: row;

    .input {
      flex: 1 1;
    }

    .otp-submit-btn {
      align-self: center;
      padding: 10px 1rem;
      margin-left: 10px;
    }
  }

  .password-form {
    .input {
      margin: 1rem auto;
    }

    .sso-btn {
      margin: 1rem auto;
      color: $rootkit__color-blue;
    }
  }

  .otp-form {
    .input {
      margin: 1rem auto;
    }
  }

  .sso-recovery-form {
    .input {
      margin: 1rem auto;
    }
  }

  a {
    @include r-link(var(--rootkit__palette--white), true);
  }

  .error-message-link {
    @include r-link($rootkit__color-red--dark, true);
  }

  button > a {
    text-decoration: none;
  }

  .activate-actions {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 60px;
    width: 50%;

    .button {
      align-self: inherit;

      .button-flex {
        display: inherit;
      }
    }
  }

  .clipboard-btn {
    padding: 5px;
    font-size: 12px;
    display: inline;
  }

  .clipboard-btn-inner {
    font-family: monospace;
  }

  .clipboard-feedback {
    display: inline;
    font-size: 12px;
    padding-left: 10px;
  }

  .recovery-form-errors {
    margin: 1rem auto;
    padding: 10px;
    color: $rootkit__color-red--dark;
    background-color: var(--rootkit__palette--red100);
    border: solid 1px var(--rootkit__palette--red200);
    border-radius: 5px;

    ul {
      padding-inline-start: 0;
      margin: 10px 0;
    }

    li {
      font-weight: 200;
      list-style: none;
      padding-bottom: 1rem;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.accept-tos {
  .auth-segment-inner {
    width: 80%;
  }

  .form-checkbox--labeled {
    align-items: center;
  }
}

.auth-segment {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &.logo {
    flex-direction: column;
  }

  &:first-of-type h1 {
    width: 60%;
    color: $rootkit__color-red;
  }

  &.recover-sso {
    .help-text {
      font-weight: 200;
      font-size: 1.5rem;
      color: $rootkit__color-grey;
    }
  }
}

.auth-segment:nth-child(2) {
  background-color: var(--rootkit__palette--gray700);
  color: var(--rootkit__palette--white);
}

.auth-segment.add-otp {
  .auth-segment-inner {
    width: 80%;
    min-height: 480px;

    .manual-code {
      height: 150px;
      display: flex;
      align-items: center;
    }

    .manual-code-button {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        opacity: 0.75;
      }
    }
  }
}

.auth-logo {
  width: 80px;
  height: 80px;
}

.not-me {
  padding-top: 16px;
  @include r-link(var(--rootkit__palette--white), true);
}

.auth-password-field {
  input {
    padding-right: 2rem !important;
  }

  .input-icon {
    right: 5px !important;
    margin-top: 2px;
  }

  svg {
    opacity: 0.5;
  }

  svg:hover {
    opacity: 1;
  }
}

.redirect-sso {
  height: 100vh;
  background-color: var(--rootkit__palette--gray600);
  padding: 4rem;

  .logo {
    width: 100px;
    height: 100px;
  }

  .redirect-text {
    text-align: center;
    color: var(--rootkit__palette--white);

    .redirect-title {
      font-size: 48px;
      font-weight: 600;
    }

    .redirect-help-text {
      font-size: 24px;
      font-weight: 300;
    }
  }
}

.qr {
}

.qr-outer {
  text-align: center;
}

.qr-inner {
  display: inline-block;
  background: var(--rootkit__palette--white);
  padding: 10px;
}

.store-links {
  list-style: none;
  margin: 1em 0 3em;
  padding: 0;
  display: flex;
}

.store-links-item {
  &:first-child {
    margin-right: 20px;
  }
}

.store-links-item img {
  max-width: 100%;
}

.strength-meter {
  display: flex;
  justify-content: space-between;
  margin: 0 0 1em;
}

.strength-meter-item {
  flex: auto;
  margin: 0 0.2em;
  height: 0.5em;
  background-color: var(--rootkit__palette--white);
  opacity: 0.1;
}

.strength-meter--1 {
  > .strength-meter-item:nth-child(-n + 1) {
    background-color: $rootkit__color-red--darker;
    opacity: 0.5;
  }
}

.strength-meter--2 {
  > .strength-meter-item:nth-child(-n + 2) {
    background-color: $rootkit__color-yellow;
    opacity: 0.5;
  }
}

.strength-meter--3 {
  > .strength-meter-item:nth-child(-n + 3) {
    background-color: $rootkit__color-yellow;
    opacity: 0.5;
  }
}

.strength-meter--4 {
  > .strength-meter-item:nth-child(-n + 4) {
    background-color: $rootkit__color-green;
    opacity: 0.5;
  }
}
