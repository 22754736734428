/* applies to page header and cta button */
.settings-header {
  background-color: var(--rootkit__palette--gray100);
}

.settings-header__title {
  display: flex;
  justify-content: space-between;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--rootkit__palette--gray300);
}

.settings-header__description {
  border-bottom: 1px solid var(--rootkit__palette--gray300);
  padding-left: 1.375rem;
  padding-right: 1.375rem;

  &.settings-header__description--team-config {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.empty-state_header {
  .settings-header__description {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}

.settings-header__text {
  margin: 0.1rem;
}

.settings-header__actions {
  display: flex;
  align-items: center;

  button {
    margin-left: 16px;
  }
}
