.delete-provider,
.edit-provider {
  padding: 0.5rem;

  .icon {
    margin-right: 0 !important;
  }
}

.sso-header {
  margin-left: 20px;
}

.login-options-addl-leader {
  display: flex;
  align-items: center;

  h2 {
    font-size: 14px;
    margin-right: 0.5rem;
  }

  .addl-info {
    font-style: italic;
    color: $rootkit__color-grey--light;
  }
}

.login-options-addl-action {
}

.login-sso {
  .page-title > h1 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px;
    border-bottom: 1px solid var(--rootkit__palette--gray200);
    font-size: 36px;
    font-weight: 600;
  }
}

.login-options-section {
  background-color: var(--rootkit__palette--gray100);
  border-bottom: 1px solid var(--rootkit__palette--gray200);
}

.login-options {
  padding: 20px;
}

.login-options-config {
  padding: 12px 20px;
  border: 1px solid var(--rootkit__palette--gray300);
  background: var(--rootkit__palette--white);
  box-sizing: border-box;
  border-radius: 3px;
}

.login-options-list {
  @include rootkit__list-reset;
}

.login-options-list-item {
  display: flex;
  align-items: center;

  .addl-info {
    font-style: italic;
    color: $rootkit__color-grey--light;
  }
}

.sso-provider-section {
  padding: 20px;
}

.sso-conn-config-item {
  padding: 10px;
}

.sso-actions {
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  background-color: var(--rootkit__palette--gray100);
  border-radius: 3px;

  .add-sso {
    justify-self: flex-end;
  }
}

.sso-card-label {
  margin-left: 1rem;
}

.sso-provider {
  @include entity;

  .accordion-card--nested {
    .accordion-card-primary {
      justify-content: flex-end;
      padding: 0 12px;
    }
  }

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
    }
  }

  .service-version {
    color: $rootkit__color-grey--light;
    margin-left: 0.5rem;
    font-weight: 400;
  }
}

.add-sso-modal {
  overflow: auto !important;
  height: 80vh;
  width: 80vw;

  .button-tabs {
    background: transparent;
  }

  .accordion-card {
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-card-meta {
    box-shadow: none;
    border-bottom: none;
  }

  .accordion-card-expand {
    display: none;
  }

  .copy-service-name {
    margin-bottom: 10px;

    h2 {
      margin: 0;
    }

    .service-version {
      font-weight: 400;
      margin-left: 1rem;
    }
  }

  .copy-paste-header {
    margin: 0;
    padding-bottom: 20px;

    .icon {
      margin-right: 0.5rem;
    }
  }

  .copy-paste-leader {
    margin-bottom: 10px;
    padding: 10px 0;
    border-top: 1px solid var(--rootkit__palette--gray200);
    border-bottom: 1px solid var(--rootkit__palette--gray200);
  }

  .copy-service-info-card-label,
  .copy-service-temptation-card-label {
    display: flex;
    align-items: center;
  }

  .input-checkbox {
    display: inline-flex;
    margin-left: 0;
  }

  .input-label {
    display: inline-flex;
  }

  .label-group {
    display: flex;
    align-items: center;
  }

  .modal-dialog--default {
    min-height: 80vh;
    min-width: 80vw;
  }
}

.sso-config-top-level {
  margin: 0 24px 24px;
}

.edit-sso-form-actions {
  display: flex;
  justify-content: flex-end;
  margin: 24px;

  > button {
    margin: 0 0 0 0.5rem;
  }
}

.new-sso-form-actions {
  display: flex;
  justify-content: flex-end;
  margin: 24px 0 0;

  > button {
    margin: 0 0 0 0.5rem;
  }
}

.import-sso-xml {
  textarea {
    resize: none;
    height: 20rem;
  }
}

.sso-import-actions {
  display: flex;
  margin-bottom: 0.5rem;

  .button-tabs {
    width: inherit;
  }

  .sso-parse {
    margin-left: 0.5rem;
  }
}

.sso-field {
  margin-bottom: 2.25rem;
}

.sso-field-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: $rootkit__color-grey--darker;
  font-size: 14px;
  font-weight: bold;

  > label {
    &::after {
      display: 'inline-block';
      content: ':';
    }
  }
}

.sso-config-warning {
  padding: 0 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  border: 1px solid var(--rootkit__palette--red600);
  background-color: var(--rootkit__palette--red100);
}

.login-options-addl {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.sso-form-error-container {
  @extend .onboarding-error-container;

  margin-right: 24px;
  margin-left: 24px;

  li:last-child {
    padding-bottom: 0;
  }
}
