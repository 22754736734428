$dot-size: 5px;

.inline-loader {
  display: flex;
}

.loader {
  display: flex;

  .dot {
    display: block;
    width: $dot-size;
    height: $dot-size;
    margin: calc($dot-size / 2.5);
    border-radius: calc($dot-size / 2);
    background-color: $rootkit__color-grey;
    animation: slide 1.5s infinite ease-in-out both;
  }

  .dot1 {
    animation-delay: -0.32s;
  }

  .dot2 {
    animation-delay: -0.16s;
  }
}
