.details-page {
  position: relative;
  min-height: 100%;
}

.overlay--unaffiliated {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(196, 196, 196);
  opacity: 0.5;
  z-index: $z-foreground + 1;
}

.overlay-actions {
  position: relative;
  color: var(--rootkit__palette--white);
  font-weight: 700;
  width: 100%;
  z-index: $z-foreground + 2;
  background: $rootkit__color-red;
  text-align: center;
}

.overlay-action {
  display: inline-block;
  margin: 0 0.5rem;
}

.overlay-message {
  display: inline-block;
}

.target-overlay--unaffiliated {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgb(196, 196, 196);
  opacity: 0.5;
  z-index: $z-midground;
}

.target-overlay-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--rootkit__palette--white);
  font-weight: 700;
  width: 100%;
  z-index: $z-foreground;
  background: $rootkit__color-red;
  text-align: center;
}

.target-overlay-action {
  display: inline-block;
  margin: 0 0.5rem;
}

.target-overlay-message {
  display: inline-block;
}
