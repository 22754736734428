.service-rule {
  @include entity;

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
    }
  }

  .service-rule-version {
    color: $rootkit__color-grey--light;
    margin-left: 0.5rem;
    font-weight: 400;
  }

  .service-rule-thumbnail {
    width: 82px;
    border: 1px solid rgba($rootkit__color-grey--light, 0.5);
  }

  .external-link {
    flex-wrap: nowrap;
  }

  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 3px;
  }
}

.creator-uuid {
  cursor: pointer;
  color: var(--rootkit__palette--gray500);

  &:hover {
    color: var(--rootkit__palette--gray700);
  }
}

.creator-user-name {
  max-width: 150px;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
}

.service-rule-info {
  @include entity-info;

  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.service-rule-info--detection {
  .json-tree {
    margin-right: 2rem;
  }
}

.service-rule-info-item {
  @include entity-info-item(2);

  pre {
    margin-right: 2rem;
  }
}

.service-rule-info-item-edit {
  @include entity-info-item(2);

  color: $rootkit__color-grey--light;

  pre {
    margin-right: 2rem;
  }
}

.service-rule-info-item-label {
  @include entity-info-item-label;
}

.service-rule-edit-form {
  margin-top: 2rem;
  border-top: 1px solid var(--rootkit__palette--gray600);

  .no-match {
    margin-bottom: 0.5rem;
    color: var(--rootkit__palette--red300);
  }

  .http-content {
    max-height: 30vh;
    overflow: auto;
    padding: 1rem;
    background: hsla(0deg, 0%, 100%, 0.1);
    border-radius: 3px;
    border: 1px solid transparent;
    color: var(--rootkit__palette--white);
    white-space: pre-wrap;
  }
}

.service-rule-edit-form--error {
  color: var(--rootkit__palette--red300);
}

.service-rule-edit-form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  padding: 1rem 0 0.5rem;
  border-top: 1px solid $rootkit__color-blue--lighter;

  .service-rule-edit-form-action {
    margin-right: 1rem;

    &:first-child {
      margin-right: auto;
    }
  }

  .service-rule-edit--flags-types {
    .dropdown-new-options {
      .dropdown-section-label {
        margin: 1rem 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  > button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.service-rule-edit {
  position: relative;
  margin: 1rem 0;

  .service-rule-edit-label {
    @include entity-info-item-label;

    color: var(--rootkit__palette--gray400);
  }

  .input {
    margin-top: 0.5rem;
  }

  .input-row {
    position: relative;

    &--and {
      &::before {
        position: absolute;
        content: 'AND';
        color: var(--rootkit__palette--red300);
        top: 30%;
        left: 10px;
      }

      input {
        padding-left: 45px;
      }
    }

    &--or {
      &::before {
        position: absolute;
        content: 'OR';
        color: var(--rootkit__palette--red300);
        top: 30%;
        left: 10px;
      }

      input {
        padding-left: 45px;
      }
    }
  }

  .add-row {
    position: absolute;
    top: 0;
    right: 0;

    > .button {
      padding: 3px;
      background-color: transparent;
      border: none;
      color: var(--rootkit__palette--gray400);

      svg {
        fill: var(--rootkit__palette--gray400);
        stroke: var(--rootkit__palette--gray400);
      }
    }
  }

  .input-checkbox {
    label {
      color: var(--rootkit__palette--gray400);
    }
  }
}

.service-rule-edit--artifact {
  p {
    color: var(--rootkit__palette--gray400);
  }

  a {
    @include r-link;
  }
}

.service-rule-edit-group {
  display: flex;
  justify-content: space-between;

  > .service-rule-edit {
    margin-right: 1rem;
    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }
}
