.activity-log-list-table {
  .is-sortable:hover {
    cursor: pointer;
  }

  .is-sortable .icon {
    margin-left: 0.5rem;
  }

  .is-sortable svg {
    fill: var(--rootkit__palette--gray300);
    stroke: var(--rootkit__palette--gray300);
  }
}

.activity-log__link {
  @include link-reset;
  @include r-link;
}
