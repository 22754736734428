.activity-instance__details--link {
  cursor: pointer;
  text-decoration: underline;
}

.activity-instance__subtitle--link {
  cursor: pointer;
  color: var(--rootkit__palette--gray500);
  text-decoration: underline;
}

.mitre {
  .mitre-list {
    @include rootkit__list-reset;
  }

  .mitre-list__item {
    display: inline-block;
    margin-right: 0.75rem;
  }

  .mitre-list__item--name {
    display: inline-block;
    margin-right: 0.25rem;
  }

  .mitre-list__item--link {
    color: var(--rootkit__palette--gray500);
    text-decoration: underline;

    &::after {
      margin: 0 0 0 3px;
      background-size: 10px 10px;
      background-position-y: 2px;
      width: 10px;
    }
  }

  .mitre-list__desc {
    color: var(--rootkit__palette--gray400);
    margin-bottom: 0.875rem;
    margin-top: 0.875rem;
  }

  .mitre-list__desc-toggle {
    margin-left: 0.5rem;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  .mitre-list__desc-more {
    color: var(--rootkit__palette--gray400);
    display: none;
    margin-bottom: 0.875rem;

    &--is-expanded {
      display: block;
    }
  }
}

.mitre-summary {
  .rootkit__description-list__term {
    align-items: flex-start;
  }
}
