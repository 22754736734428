.info-tooltip-tt-bar-charts {
  padding: 0;
  background-color: var(--rootkit__palette--white);
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 var(--rootkit__palette--gray700);
  border: 1px solid rgba(34, 36, 38, 0.15);
  z-index: $z-foreground + 3;

  @mixin tt-graph-body {
    padding: 10px;
    color: $rootkit__color-grey;

    .bar-graph {
      margin-top: 10px;

      .bar-wrapper {
        height: 10px;

        .bar,
        .bar-remainder {
          height: 10px;
          margin: 0 !important; // fix in rootkit
        }

        &.empty {
          .bar {
            background-color: $rootkit__color-blue--lighter;
          }

          .tick {
            background-color: $rootkit__color-blue--lighter;
          }
        }
      }
    }
  }

  @mixin bar {
    height: 10px;
  }

  @mixin tick {
    height: 13px;
    width: 2px;

    &.end {
      margin-right: 5px;
    }

    &.start {
      margin-left: 5px;
    }
  }
}

.priority-tool-tip-container {
  display: grid;
  grid-template-columns: 120px 240 240px;
  --header-height: 35px;
  overflow: hidden;
  grid-template-rows: [header] var(--header-height) [body] 70px;
}

.priority-tool-tip-header {
  grid-row: header;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 10px;
  line-height: var(--header-height);
  text-align: center;
  border-left: 1px solid var(--rootkit__palette--gray300);
  border-bottom: 1px solid var(--rootkit__palette--gray300);

  &:nth-child(1) {
    border-left: none;
  }
}

.priority-tool-tip-value-container {
  display: grid;
  grid-gap: 1px;
  width: 120px;
  max-width: 120px;
  height: 70px;
  grid-template-rows: [header] 29px [body] 41px;
}

.priority-tool-tip-body {
  display: flex;
  border-left: 1px solid var(--rootkit__palette--gray300);
  align-items: center;
  color: $rootkit__color-grey;
  margin: 0;
  padding: 0;
}

.priority-factor-value-container-pill {
  display: grid;
  align-items: center;
  padding: 3px;
  margin: 0 auto;
  grid-row-start: 2;
}

.priority-grid-border-symbol-left {
  background: rgba(255, 255, 255, 0);
  position: relative;
  top: 15px;
  left: -5px;
  height: 12px;
  width: 0px;
  white-space: nowrap;

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 900;
    line-height: 10px;
    color: var(--rootkit__palette--black);
  }
}

.priority-grid-border-symbol-right {
  background: rgba(255, 255, 255, 0);
  position: relative;
  top: 15px;
  left: -26px;
  height: 12px;
  width: 0px;
  white-space: nowrap;

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 900;
    line-height: 10px;
    color: var(--rootkit__palette--black);
  }
}

.priority-factor-arrow {
  grid-column: 3/4;

  &.grey {
    path {
      fill: $rootkit__color-grey--light;
    }
  }

  &.orange {
    path {
      fill: $rootkit__color-orange;
    }
  }

  &.rotate {
    transform: rotate(180deg);
  }

  &.display-none {
    display: none;
  }
}
