.priority-tool-tip-container-validated {
  display: grid;
  grid-template-columns: 120px 360px 240px;
  --header-height: 35px;
  overflow: hidden;
  grid-template-rows: [header] var(--header-height) [body] 70px;
}

.priority-tool-tip-header-validated {
  grid-row: header;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 10px;
  line-height: var(--header-height);
  text-align: center;
  border-left: 1px solid var(--rootkit__palette--gray300);
  border-bottom: 1px solid var(--rootkit__palette--gray300);

  &:nth-child(1) {
    border-left: none;
  }
}

// This import doesnt' NEED to be here for these CSS classes to be available, but it is where they're being used from
@import '../priority-bar-chart/priority-bar-chart.styles';
