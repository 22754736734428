.entity-summary--savedViews {
  .accordion-card-header {
    display: grid;
    grid-template-columns: 45px 20% repeat(6, 1fr) 20px;
    column-gap: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    min-width: 750px;
  }

  .accordion-card {
    min-width: 750px;
  }

  .accordion-card-row {
    display: grid;
    grid-template-columns: 25px calc(0.2 * (20px + 100%)) repeat(6, 1fr) 20px;
    column-gap: 10px;
    text-overflow: ellipsis;
    white-space: normal;

    .button {
      width: 20px;
    }

    a:link {
      display: flex;
    }

    .default-view-label {
      font-style: italic;
      font-weight: normal;
    }
  }

  .accordion-card-cell {
    //keeps cells in 1fr columns from changing width based on content
    overflow: hidden;
  }

  .accordion-card-cell:nth-child(9) {
    //allows kebab options to appear
    overflow: visible;
  }
}

.entity-table-actions-savedViews {
  display: none;
}

.saved-views-header {
  margin-top: 10px;
}

.saved-view {
  @include entity;

  .card-actions {
    .dropdown-new-option {
      width: 220px;
    }

    .button-flex {
      width: 100%;
      display: grid;
      grid-template-columns: 25px 1fr;
    }
  }

  /* updated hex code to Carbon green-60 */
  &.is--default-view {
    background: linear-gradient(
      90deg,
      #198038 0%,
      #198038 3px,
      rgba(255, 255, 255, 0.1) 4px
    );
  }
}

.saved-view-expanded {
  border: 1px solid var(--rootkit__palette--gray300);
  border-radius: 3px;
}

.saved-view-info-header {
  background: var(--rootkit__palette--gray200);
  border-bottom: 1px solid var(--rootkit__palette--gray300);
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 12px 12px 40px;
}

.saved-view-info {
  padding: 24px 40px;
}

.saved-view-item {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 28px;

  .hostname-info-item-label {
    font-size: 14px;
    line-height: 28px;
    padding-right: 10px;
  }

  .entity-sorts-active {
    font-size: 14px;

    &:first-child {
      padding-left: 0;
    }
  }
}

.saved-view__name {
  color: var(--rootkit__palette--gray700);
  text-decoration: none;
}

.randori-author {
  display: flex;
  justify-content: flex-start;
  padding: 5px 0;
  align-items: center;
  color: $rootkit__color-red;

  .short-favicon {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    margin-right: 5px;
    background-image: url('../static/images/svg-out/randori-short-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}
