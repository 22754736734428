.pane-trigger {
  padding: 0;

  .button-flex {
    .button-children {
      display: flex;

      .pane-trigger-title {
        margin: 0.5rem 1rem;
      }

      .pane-trigger-caret {
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid var(--rootkit__palette--gray200);
      }
    }

    .icon-drawer {
      margin: 0.5rem 1rem;

      svg {
        fill: none;
      }
    }
  }
}

.pane-tabs {
  width: 100%;
  display: inline-flex;
  position: relative;
  padding-left: 15px;

  @include rootkit__list-reset;

  li {
    z-index: 0;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    padding: 10px 20px;
    border: 1px solid var(--rootkit__palette--gray200);
    border-bottom: none;

    &:focus {
      outline: none;
    }

    &[aria-selected='true'] {
      z-index: 2;
      border-bottom: 1px solid var(--rootkit__palette--white);
      font-weight: 600;
    }

    &:first-child {
      margin-left: 15px;
    }
  }

  &::before {
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid var(--rootkit__palette--gray200);
    z-index: 1;
  }
}
