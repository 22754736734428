.dash-graph-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $rootkit__font--heading;
  font-size: 24px;
  line-height: 36px;
  border-bottom: 1px solid var(--rootkit__palette--gray200);
  padding-bottom: 10px;

  .dropdown-new {
    position: relative;
    top: -4px;
  }
}

.attack-legend {
  margin-right: 20px;
  flex: 0 1 200px;
}

.attack-legend-item {
  @include rootkit__typography--table;

  display: flex;
  align-items: flex-start;
  width: auto;

  svg {
    flex: 0 0 12px;
    width: 12px;
    margin: 2px 5px 0 0;
  }

  span {
    width: clamp(90px, 100%, 200px);
  }
}

.dash-graph-title-info-container {
  display: flex;
  align-items: center;
}

.dash-graph-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: map-get($spacer-map, 2);
}

.dash-attack-stats {
  padding: 0;
  padding-bottom: 10px;
  width: 60%;
  min-width: 500px;

  .stat-block {
    text-align: left;
    margin-right: 1rem;
    border-bottom: solid 5px transparent;
    transition: border-bottom-color 0.2s;

    .stat-label {
      font-size: 12px;
      line-height: 16px;
      white-space: nowrap;
      font-family: $rootkit__font--body;
    }

    .stat {
      font-size: 36px;
      line-height: 54px;
      font-weight: normal;
      font-family: $rootkit__font--heading;
    }

    .stat-block-children {
      font-family: $rootkit__font--body;
      font-size: 12px;
      line-height: 16px;
    }

    &.is-trending--up .stat-block-children {
      color: $rootkit__color-red;
    }

    &.is-trending--down .stat-block-children {
      color: var(--rootkit__palette--green600);
    }

    &.is-trending--no-difference .stat-block-children {
      color: var(--rootkit__palette--gray300);
    }

    .stat-block-children > .icon {
      margin-right: 5px;

      &.icon-trend-arrow--no-difference svg,
      &.icon-trend-arrow--down svg {
        transform: rotate(180deg);
      }

      &.icon-trend-arrow--no-difference svg > path {
        stroke: var(--rootkit__palette--gray300);
        fill: var(--rootkit__palette--gray300);
      }

      &.icon-trend-arrow--up svg > path {
        fill: $rootkit__color-red;
        stroke: $rootkit__color-red;
      }

      &.icon-trend-arrow--down svg > path {
        fill: $rootkit__color-green;
        stroke: $rootkit__color-green;
      }
    }

    &.is-selected {
      border-bottom-color: $rootkit__color-red;

      &:hover {
        border-bottom-color: $rootkit__color-grey;
      }

      .stat-block-children {
        text-decoration: underline;
      }
    }

    &:hover {
      border-bottom-color: $rootkit__color-grey;
    }
  }
}

.dash-graph-dropdown {
  .dropdown-toggle button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dash-graph-dropdown-content {
    padding: 0;

    .dash-graph-dropdown-content-item {
      list-style: none;
      padding: 5px;
      margin: 5px;
      width: 100%;

      &:hover {
        cursor: pointer;
        background: var(--rootkit__palette--gray200);
      }
    }
  }
}

.dash-graph-button-filters {
  width: auto;

  .button-tabs {
    justify-content: flex-end;
  }
}
