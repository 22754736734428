// @TODO: Resolve CSS grid issues in Firefox
//
// Grid items will overflow their grid boundaries in Firefox.
// @see: https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items

@mixin ff-grid-hax {
  min-width: 0;
  min-height: 0;
}

// ---------------------------------------------------------------------------

*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

:root {
  --scrollbar-opacity: 0.1;
  scrollbar-color: var(--rootkit__palette--gray200) transparent;
  scrollbar-color: rgba(0, 0, 0, var(--scrollbar-opacity)) transparent;
}

::-webkit-scrollbar {
  position: absolute;
  height: 0.5rem;
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: var(--rootkit__palette--gray200);
  background-color: rgba(0, 0, 0, var(--scrollbar-opacity));
}

// App
// ---------------------------------------------------------------------------

.l-panel {
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;
  background: var(--rootkit__palette--gray100);

  &.bg-white {
    background: var(--rootkit__palette--white);
  }
}

.l-panel--relative {
  position: relative;
}

.l-center {
  grid-column: full-bleed;
}

.l-root {
  display: flex;
  height: 100vh;

  // sidebar etc
  .navigation {
    width: 275px;
  }

  @include desktopSm {
    flex-direction: column;

    .navigation {
      width: 100%;
    }
  }
}

// Page
// ---------------------------------------------------------------------------

%l-page {
  @include ff-grid-hax;

  display: grid;
  grid-template-columns:
    [full-bleed-start] 0 [no-bleed-start secondary-start] repeat(4, 1fr)
    [secondary-end primary-start] repeat(8, 1fr)
    [primary-end no-bleed-end] 0 [full-bleed-end];
}

.l-summary {
  @extend %l-page;

  // grid-template-rows: [leader-start] auto [leader-end primary-start] 1fr [primary-end footer-start] auto [footer-end];
  grid-template-rows: [leader-start] auto [leader-end primary-start] 1fr [primary-end];

  height: 100vh;
}

.l-page {
  @extend %l-page;
}

.l-page--fh {
  min-height: 100vh;
}

.l-page--pad {
  padding: 20px 0;
}

.l-page--dashboard {
  @extend %l-page;

  grid-template-columns:
    [full-bleed-start] 0 [no-bleed-start secondary-start] repeat(
      4,
      minmax(65px, 1fr)
    )
    [secondary-end primary-start] repeat(8, minmax(65px, 1fr))
    [primary-end no-bleed-end] 0 [full-bleed-end];

  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 20px 0 0 15px;

  @include break(1800px) {
    grid-template-columns:
      [full-bleed-start] 10% [no-bleed-start secondary-start] repeat(4, 1fr)
      [secondary-end primary-start] repeat(8, 1fr)
      [primary-end no-bleed-end] 10% [full-bleed-end];
  }
}

.l-page--header {
  @extend %l-page;
}

.l-page--attack-cart {
  @extend %l-page;

  grid-template-rows: auto 80px;
  height: 100vh;
  overflow-y: hidden;

  @include desktopXL {
    grid-template-columns:
      [full-bleed-start] 10% [no-bleed-start secondary-start] repeat(4, 1fr)
      [secondary-end primary-start] repeat(8, 1fr)
      [primary-end no-bleed-end] 10% [full-bleed-end];
  }
}

.l-page--settings {
  @extend %l-page;

  grid-template-columns: 20px [content-start] auto [content-end] 20px;

  @include desktopXL {
    grid-template-columns: 220px [content-start] auto [content-end] 220px;
  }
}

// Columns
// ---------------------------------------------------------------------------

.l-col--fullbleed {
  @include ff-grid-hax;

  grid-column: full-bleed;
}

.l-col--fullwidth {
  @include ff-grid-hax;

  grid-column: full-bleed;
}

// Rows
// ---------------------------------------------------------------------------

.l-row--header {
  grid-row: 1/2;

  .l-page--settings & {
    grid-column: 2;
  }
}
