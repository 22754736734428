.comment-accordion-label {
  display: flex;
  flex-grow: 1;

  .ip-info-card-col {
    flex: 1;
  }
}

.comment-root {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(211, 215, 219, 0.5);
  margin-bottom: 30px;
}

.comment-avatar-placeholder {
  background-color: rgb(20, 149, 235);
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.comment-left-column {
  margin-right: 20px;
}

.comment-right-column {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  input {
    border: 1px solid var(--rootkit__palette--gray400);
  }
}

.comment-input-right-column {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  input {
    border: 1px solid var(--rootkit__palette--gray400);
  }
}

.comment-heading {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 10px;
}

.comment-heading-spacer {
  display: flex;
  flex-grow: 1;
}

.comment-toolbar {
  display: flex;
  flex-direction: row;

  button {
    margin-left: 7px;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 0;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;

    span {
      transform: translateX(4px);

      path {
        fill: none;
      }
    }
  }
}

.comment-author {
  margin: 0 15px 0 0;
}

.comment-date {
  margin: 0;
}

.comment-italicized {
  font-style: italic;
}

.comment-group-loading {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.comment-delete-modal-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid $rootkit__color-blue--lighter;

  > .button:first-child {
    margin-right: 0.5rem;
  }
}

.comment-delete-modal-heading {
  border-top: 1px solid $rootkit__color-blue--lighter;
}

.comment-load-more-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.comment-edit-toolbar {
  margin-top: 3px;
  display: flex;
  justify-content: flex-end;

  > .button:first-child {
    margin-right: 0.5rem;
  }
}

.comment-meta-messaging {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  margin-bottom: 15px;

  > div {
    margin-right: 20px;
  }
}

.comment-meta-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment-text-italic {
  font-style: italic;
}

.comment-text-bold {
  font-weight: bold;
}

.comment-textarea-input {
  textarea {
    border: 1px solid var(--rootkit__palette--gray400);
  }

  textarea::placeholder {
    color: var(--rootkit__palette--gray400);
    opacity: 0.7;
  }
}

.comment-text-input {
  input {
    border: 1px solid var(--rootkit__palette--gray400);
  }

  input::placeholder {
    color: var(--rootkit__palette--gray400);
    opacity: 0.7;
  }
}

// ---------------------------------------------------------------------------
// Styles for the comment thread

.comment-thread {
  .comment-root {
    margin: 0 32px;
    padding: 32px 8px;
  }

  .comment-root:first-child {
    margin: 0;
    padding-left: 40px;
    padding-right: 40px;
  }

  .comment-author,
  .comment-date {
    color: var(--rootkit__palette--gray400);
  }

  .comment-text {
    color: var(--rootkit__palette--gray500);
  }
}
