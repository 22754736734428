@mixin entity() {
  &-header {
    &:hover {
      cursor: pointer;
    }

    svg {
      fill: $rootkit__color-grey--light;
      stroke: $rootkit__color-grey--light;
    }
  }

  .accordion-card-meta {
    // @TODO: in rootkit
    box-shadow: none;
  }

  &--unaffiliated {
    background: $rootkit__color-grey--lighter;

    .accordion-card-meta {
      background: var(--rootkit__palette--white);
      box-shadow: none;
    }
  }

  .entity-name {
    color: var(--rootkit__palette--black);
    font-weight: 700;
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      @include link-reset;
    }
  }

  .card-actions {
    display: flex;
    justify-content: flex-end;

    > * {
      margin-left: 0.5rem;
    }

    .more-actions {
      padding: 0.5rem 0.75rem;
    }

    .icon-more {
      margin: 0;

      svg {
        fill: $rootkit__color-grey--darker;
        stroke: none;
      }
    }

    .icon-customer {
      margin: 0;

      svg {
        fill: $rootkit__color-grey--darker;
        stroke: $rootkit__color-grey--darker;
      }
    }

    .dropdown-new-content--is-open {
      right: 0;
    }

    .dropdown-new-option {
      display: inline-flex;
      align-items: center;

      .button {
        color: $rootkit__color-grey;

        .button-flex {
          width: 140px;

          .icon svg {
            fill: none;
            stroke: $rootkit__color-grey;
          }
        }
      }

      > .icon {
        margin-right: 0.75rem;
      }
    }
  }
}

@mixin entity-info {
  display: flex;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  color: $rootkit__color-grey;
  border-bottom: 1px solid var(--rootkit__palette--gray200);

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom-color: transparent;
  }
}

@mixin entity-info-item($column-number: 5) {
  width: percentage(calc(1 / $column-number));

  a {
    @include link-reset;
    @include r-link;
  }
}

@mixin entity-info-item-label {
  @include rootkit__typography--h6;

  margin-bottom: 1rem;
}

.dropdown-empty-option {
  cursor: default;
  padding: 0.5em;
  line-height: 1.5em;
  font-size: 12px;
  color: $rootkit__color-grey;
  white-space: nowrap;

  &:hover {
    background: var(--rootkit__palette--white);
  }
}
