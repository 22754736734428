.activity {
  position: relative;
}

.activity-header {
  display: flex;
  top: 15px;
  left: 15px;
  padding-bottom: 1rem;
  position: absolute;
  z-index: $rootkit__z-midground;

  > h2 {
    margin: 0;
  }

  .info-popper {
    margin: 0 6px;
  }
}

.activity-header--no-tabs {
  position: initial;
  top: initial;
  left: initial;
  margin: 15px;
  border-bottom: 1px solid $rootkit__color-blue--lighter;
}

ul.activity-tabs {
  padding: 15px 30px 0 15px;
  justify-content: flex-end;

  &::after {
    left: 15px;
    right: 15px;
    width: initial;
  }

  > li {
    height: 36px;
    overflow: hidden;
    padding: 0 10px;
    background: var(--rootkit__palette--gray100);
    color: $rootkit__color-grey--darker;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > li[aria-selected='true'] {
    background: var(--rootkit__palette--white);
  }
}

.activity-block {
  flex: 1;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px;
  display: flex;
  text-align: center;
}

.activity-block--runbooks {
  color: var(--rootkit__palette--white);
  background: $rootkit__color-red;

  a {
    @include link-reset;
    @include r-link(var(--rootkit__palette--white), false);
  }
}

.activity-block--implants {
  a {
    @include link-reset;

    text-decoration: none;
  }

  .activity-block-secondary {
    color: $rootkit__color-grey--light;
  }
}

.activity-block--red {
  color: $rootkit__color-red;

  a {
    @include link-reset;
    @include r-link($linkColor: $rootkit__color-red, $underline: false);
  }

  // @TODO: refactor IconArrowThick
  //
  // IconArrowThick can not have `fill` overwritten right now
  svg path {
    fill: $rootkit__color-red !important;
  }
}

.activity-block--green {
  color: $rootkit__color-green;

  a {
    @include link-reset;
    @include r-link($linkColor: $rootkit__color-green, $underline: false);
  }

  svg path {
    fill: $rootkit__color-green !important;
  }
}

.activity-block--orange {
  color: $rootkit__color-orange;

  a {
    @include link-reset;
    @include r-link($linkColor: $rootkit__color-orange, $underline: false);
  }

  svg path {
    fill: $rootkit__color-orange !important;
  }
}

.activity-blocks {
  display: flex;

  > .activity-block {
    border-right: 1px solid transparent;
    border-image: linear-gradient(
        to top,
        transparent 10%,
        $rootkit__color-blue--lighter 10% 90%,
        transparent 90%
      )
      1;
  }

  > .activity-block:last-child {
    border-right: none;
  }
}

.activity-block-primary {
  margin-bottom: 1rem;
}

.activity-block-stat {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
  font-size: 24px;
  font-weight: 700;
}

.activity-block-label {
  font-size: 16px;
  font-weight: 700;
}

.activity-value {
}

.activity-symbol {
  margin-top: 0.2rem;
  font-size: 16px;
}

.activity-block-secondary .activity-sub-link {
  @include link-reset;
  @include r-link($linkColor: $rootkit__color-grey--darker);
}

.carbon-colors {
  .activity-block-secondary .activity-sub-link {
    @include link-reset;
    @include r-link($linkColor: $rootkit__palette--blue500);
  }
}

.stat-trend {
  margin-left: 0.5rem;
}

.stat-trend--down {
  transform: rotate(180deg);
}
