@use '@randori/rootkit/src/styles/functions/color' as rcolor;

@mixin cell {
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.target-age-matrix {
  color: $rootkit__color-grey--darker;
  background: var(--rootkit__palette--white);
  display: grid;
  grid-template-columns: 30px repeat(4, 1fr);
  grid-auto-rows: minmax(60px, auto);
  grid-template-areas:
    'xx xx xx xx xx xx'
    'yy z  x  x  x  x'
    'yy y  cc cc cc cc'
    'yy y  cc cc cc cc'
    'yy y  cc cc cc cc';
}

.target-age-matrix-spacer {
  grid-area: z;
  border-top: 1px solid var(--rootkit__palette--gray200);
  border-left: 1px solid var(--rootkit__palette--gray200);
}

.target-age-matrix-row-labels {
  grid-area: y;
  display: flex;
  flex-flow: column;
  border-top: 1px solid var(--rootkit__palette--gray200);

  > div {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    border-bottom: 1px solid var(--rootkit__palette--gray200);
    border-left: 1px solid var(--rootkit__palette--gray200);
  }
}

.target-age-matrix-column-labels {
  grid-area: x;
  display: flex;

  > div {
    @include cell;

    border-top: 1px solid var(--rootkit__palette--gray200);
    border-left: 1px solid var(--rootkit__palette--gray200);

    &:last-child {
      border-right: 1px solid var(--rootkit__palette--gray200);
    }
  }
}

.target-age-matrix-data {
  grid-area: cc;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

.target-age-matrix-x-label {
  @include rootkit__typography--overline;

  grid-area: xx;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(237, 239, 241, 0.5);
  line-height: 40px;
}

.target-age-matrix-y-label {
  @include rootkit__typography--overline;

  grid-area: yy;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  background-color: rgba(237, 239, 241, 0.5);
  line-height: 40px;
}

.target-age-matrix-row {
  display: flex;
  height: 100%;

  &:first-child {
    border-top: 1px solid var(--rootkit__palette--gray200);
  }
}

.target-age-matrix-cell {
  @include cell;
  @include rootkit__typography--h2;

  border-bottom: 1px solid var(--rootkit__palette--gray200);
  border-left: 1px solid var(--rootkit__palette--gray200);

  &:last-child {
    border-right: 1px solid var(--rootkit__palette--gray200);
  }

  // cell colors
  // -------------------------------------------------------------------------

  &.age--gt-now.priority--low {
    background-color: rcolor.alpha(var(--rootkit__palette--gray500), 0.3);

    a {
      @include r-link(var(--rootkit__palette--black), false);
    }
  }

  &.age--gt-now.priority--medium {
    background-color: rcolor.alpha(var(--rootkit__palette--orange600), 0.3);

    a {
      @include r-link(var(--rootkit__palette--black), false);
    }
  }

  &.age--gt-now.priority--high {
    background-color: rcolor.alpha(var(--rootkit__palette--red600), 0.3);

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.age--gt-31.priority--low {
    background-color: rcolor.alpha(var(--rootkit__palette--gray500), 0.5);

    a {
      @include r-link(var(--rootkit__palette--black), false);
    }
  }

  &.age--gt-31.priority--medium {
    background-color: rcolor.alpha(var(--rootkit__palette--orange600), 0.5);

    a {
      @include r-link(var(--rootkit__palette--black), false);
    }
  }

  &.age--gt-31.priority--high {
    background-color: rcolor.alpha(var(--rootkit__palette--red600), 0.5);

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.age--gt-61.priority--low {
    background-color: rcolor.alpha(var(--rootkit__palette--gray500), 0.8);

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.age--gt-61.priority--medium {
    background-color: rcolor.alpha(var(--rootkit__palette--orange600), 0.8);

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.age--gt-61.priority--high {
    background-color: rcolor.alpha(var(--rootkit__palette--red600), 0.8);

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.age--gt-91.priority--low {
    background-color: rcolor.alpha(var(--rootkit__palette--gray500), 1);

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.age--gt-91.priority--medium {
    background-color: rcolor.alpha(var(--rootkit__palette--orange600), 1);

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.age--gt-91.priority--high {
    background-color: rcolor.alpha(var(--rootkit__palette--red600), 1);

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }
}

.target-age-matrix-legend {
  @include rootkit__typography--caption;

  display: flex;
  margin-left: 24px;
  padding: 12px 0;
}

.target-age-matrix-legend-label {
  margin-right: 1rem;
  color: var(--rootkit__palette--gray500);
}

.target-age-matrix-legend-values {
  @include rootkit__list-reset;

  display: flex;

  .matrix-low {
    color: $rootkit__color-grey--light;

    &::before {
      background-color: $rootkit__color-grey--light;
    }
  }

  .matrix-medium {
    color: var(--rootkit__palette--orange500);

    &::before {
      background-color: var(--rootkit__palette--orange500);
    }
  }

  .matrix-high {
    color: $rootkit__color-red;

    &::before {
      background-color: $rootkit__color-red;
    }
  }

  > li {
    display: flex;
    margin-right: 1rem;

    &::before {
      content: '';
      margin-right: 0.35rem;
      width: 1rem;
      height: 1rem;
    }
  }
}
