.characteristics {
}

.characteristics-label {
  display: flex;
  align-items: center;
  color: var(--rootkit__palette--gray500);
  margin-bottom: 1rem;
}

.characteristics-info {
  color: var(--rootkit__palette--gray700);
}

.characteristics-info:not(:last-child) {
  margin-right: 0.5rem;
}

.characteristics-info-factor {
  path {
    fill: $rootkit__color-orange;
  }
}

.characteristics-info-content-count {
  color: $rootkit__color-orange;
}

.characteristics-count {
}

.characteristics-info-content {
  .external-link {
    @include r-link;

    display: block;
    margin-top: 0.5rem;
  }
}

.characteristics-list {
}

.characteristic-meta {
}

.characteristic-trigger {
  display: inline-block;
  transition: all 0.3s ease 0s;

  &--is-collapsed {
    transform: rotate(-90deg);
    margin-bottom: 0.33rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.characteristic-label {
  display: inline-block;
  margin: 0;
  margin-right: 0.5rem;
  color: var(--rootkit__palette--gray500);
}

.characteristic-desc {
  margin-bottom: 1rem;
  color: var(--rootkit__palette--gray500);
}
