@media print {
  * {
    transition: none !important;
  }

  body {
    overflow: initial;

    // zoom: 85%;
  }

  .l-root {
    display: initial;
  }

  .global-navigation-mobile,
  .global-navigation-desktop,
  .navigation {
    display: none;
  }

  .l-panel {
    max-height: initial;
    overflow: initial;
  }
}
