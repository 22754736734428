$heading-margin: 15px;

.global-search-page {
  background-color: var(--rootkit__palette--white);
  padding-bottom: 1px;
}

.global-search-header {
  min-width: 840px;
  z-index: 10;
  position: sticky;
  background-color: var(--rootkit__palette--gray100);
  padding: 0 22px;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 15px;
  padding-bottom: 15px;

  div {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  input {
    font-size: 0.875rem;
    width: 18rem;
  }
}

.global-search-header-floating {
  @extend .global-search-header;

  -webkit-box-shadow: 0 7px 7px -5px rgba(0, 0, 0, 0.65);
  box-shadow: 0 7px 7px -5px rgba(0, 0, 0, 0.65);
}

.global-search-page-input {
  .input {
    height: 40px;
  }

  input {
    border-color: $rootkit__color-grey--light;
  }
}

.global-search-grow {
  display: flex;
  flex-grow: 1;
}

.global-search-heading {
  display: flex;
  flex-direction: row;
}

.global-search-entity-heading {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  cursor: pointer;
}

.global-search-total {
  margin-left: 20px;
  font-family: var(--rootkit__font--heading);
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0.5px;
  align-self: flex-end;
}

.global-search-contents {
  margin: 20px;
}

.global-search-num-entities {
  font-family: var(--rootkit__font--heading);
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0;
  text-align: left;
  color: var(--rootkit__palette--gray700);
  margin-left: $heading-margin;
}

.global-search-entity-type {
  margin-left: 10px;
  font-family: var(--rootkit__font--heading);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: var(--rootkit__palette--gray700);
}

.entity-search-result-title {
  font-family: var(--rootkit__font--heading);
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  color: var(--rootkit__palette--gray700);
}

.global-search-entity-view-all {
  @include r-link;

  display: inline-block;
  margin-top: 1rem;
  margin-left: $heading-margin;
}

.global-search-entity-divider {
  margin-top: 50px;
  border: 1px solid var(--rootkit__palette--gray400);
  opacity: 0.3;
  margin-bottom: 20px;
}

.global-search-page--table {
  .global-search-result {
    grid-column: full-bleed;
    grid-row: primary;
  }

  .global-search-result_table-container {
    overflow-x: auto;
  }
}
