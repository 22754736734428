.saved-view-modal {
}

.saved-view-modal__title {
  display: flex;
  align-items: center;
  margin: 0;

  .icon-ribbon,
  .icon-cascade,
  .icon-trash {
    margin-right: 10px;

    svg path {
      stroke: $rootkit__color-grey--darker;
    }
  }

  .entity-type {
    font-weight: 300;
  }
}

.saved-view-modal__content {
}

.saved-view-modal__content__section {
  border-bottom: 1px solid var(--rootkit__palette--gray200);
  padding: 1.5rem 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: 1px solid transparent;
  }
}

.saved-view-modal__content__subsection {
  margin-bottom: 1rem;
}

.saved-view__title {
  @include rootkit__typography--h6;
  margin: 0 0 0.5rem;
}

.saved-view__set-global {
  margin: 0.5rem 0;
  color: var(--rootkit__palette--gray700);

  label {
    @include rootkit__typography--h6;
  }
}
