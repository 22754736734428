.recipe-configuration--modal {
  position: relative;
  height: 80vh;

  a {
    padding-left: 0.3em;
  }
}

.recipe-configuration--growler {
  a {
    padding-left: 0.3em;
  }
}

.recipe-configuration--header {
  height: 45px;
  margin: 0;
  margin-right: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

.recipe-configuration--connections,
.recipe-configuration--account-properties,
.recipe-configuration--recipe-activation {
  // takes height of modal and substracts header height with padding
  height: calc(80vh - 55px);

  iframe {
    width: 100%;
    min-height: 50vh;
    max-height: 70vh;
    border: none;
  }
}

.recipe-configuration--error {
  display: flex;
  align-items: center;
  margin: 0 16px 5px;
  padding: 10px 15px;
  border-radius: 3px;

  background-color: var(--rootkit__palette--red100);

  svg {
    stroke: var(--rootkit__palette--red600);
  }
}

.recipe-configuration--recipe-activation-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-left: -32px;
  width: calc(100% + 64px);
  height: 90%;
  padding: 0 32px;
  border-top: 1px solid $rootkit__color-grey--lighter;
  text-align: center;

  .red-stroke {
    svg {
      stroke: var(--rootkit__palette--red600);
    }
  }

  .red-text {
    color: var(--rootkit__palette--red600);
  }

  .green-text {
    color: var(--rootkit__palette--green600);
  }

  p {
    width: 300px;
  }
}

.recipe-configuration--step-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-left: -32px;
  width: calc(100% + 64px);
  padding: 8px 32px 0;
  border-top: 1px solid $rootkit__color-grey--lighter;

  h4,
  p {
    margin: 15px 0;
  }
}

.recipe-configuration--error-text {
  // TODO: update to red 600
  color: var(--rootkit__palette--red600);
  margin: 0;
  padding-left: 15px;
}

.recipe-configuration--account-properties-input {
  margin: 5px 0 15px;

  .red-text {
    // TODO: update to red 600
    color: var(--rootkit__palette--red600);
  }
}

.recipe-configuration--button-container {
  position: absolute;
  bottom: 30px;
  margin-left: -32px;
  width: calc(100% + 64px);
  padding: 24px 32px 12px;
  border-top: 1px solid $rootkit__color-grey--lighter;
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 8px;
  }

  .previous-step {
    margin-right: auto;
    cursor: pointer;
  }

  .underline-text {
    span {
      text-decoration: underline;
      padding-left: 5px;
    }
  }
}

.recipe-configuration--button-text {
  position: absolute;
  left: 35%;
  width: 30%;
  text-align: center;
  margin: 0;
}
