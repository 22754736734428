.top-tags {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.top-tags-header {
  display: flex;
  align-items: center;
  height: 48px;
  padding-bottom: 12px;

  > h2 {
    margin: 0;
  }

  .icon {
    margin: 0 6px;
  }
}

.top-tags-list {
  @include rootkit__list-reset;
}

.top-tags-list--empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: $rootkit__color-grey--light;
  padding-top: 20px;
  border-top: 1px solid $rootkit__color-blue--lighter;

  > .top-tags-list--empty-icon {
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid $rootkit__color-blue--lighter;
    text-align: center;
  }

  > p {
    font-weight: normal;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
    max-width: 260px;
  }
}

.top-tags-list-item {
  display: flex;
  align-items: flex-start;
  padding: 20px 0;
  border-top: 1px solid $rootkit__color-blue--lighter;

  .pill {
    display: inline-block;
    margin: 0 0.3rem;
    max-width: 10em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.top-tags-cell {
  display: flex;
  align-items: center;

  &:last-child {
    margin-left: auto;
  }
}

.top-tags-row-total {
}

.top-tags-content {
}

.top-tags-links {
  flex-direction: column;
  align-items: flex-end;
}

.top-tags-subcell {
  display: inline-block;
  padding-left: 6px;
}

.top-tags-entity-link {
  @include link-reset;
}

.top-tags-entity-link--none {
  color: $rootkit__color-grey--light;

  &:visited,
  &:link {
    color: $rootkit__color-grey--light;
  }
}

.top-tags-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto -20px -20px;
  height: 60px;
  min-height: 60px;
  border-top: 1px solid $rootkit__color-blue--lighter;
}

.top-tag-total-count {
  padding: 0 12px;
  font-size: 16px;
  font-weight: 700;
}

.top-tags-view-all {
  // @include link-reset;
  // font-size: 16px;
  // font-weight: 700;
  // border: 0;
  // outline: none;
}

// wip
.info-popper {
  cursor: pointer;
}
