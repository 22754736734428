.user-invite {
  h1 {
  }

  p {
    line-height: 1.5em;
  }

  form {
    border-top: 1px solid $rootkit__color-blue--lighter;
  }

  .modal-dialog {
    width: initial;
    height: initial;
    min-width: 666px;
    overflow-y: initial;
  }
}

.user-invite-error-container {
  color: $rootkit__color-red;

  ul {
    @include rootkit__list-reset;
  }
}

.user-invite-step1 {
}

.step1-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;

  > div {
    width: 49.5%;
  }
}

.user-invite-step2 {
}

.step2-inputs {
  display: flex;
  align-items: center;
  padding: 30px 0;
  color: $rootkit__color-grey--light;

  .step2-inputs-first {
    width: 50%;
  }

  .step2-inputs-second {
    width: 50%;
  }

  .step2-name {
    margin-right: 5px;

    &::after {
      content: ':';
      display: 'inline-block';
    }
  }

  .step2-email {
  }
}

.user-invite-form-actions {
  display: flex;
  align-items: center;
}

.user-invite-form-stepper {
  color: $rootkit__color-grey--light;

  .active {
  }

  .rest {
    opacity: 0.4;
  }

  .stepper {
    margin-bottom: 2px;
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}

.user-invite-form-next {
  margin-left: auto;

  .button {
    display: inline-block;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.user-invite-allowed-domains {
  @include rootkit__list-reset;
}
