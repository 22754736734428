// @TODO: These colors seem backwards...

.runbook-status {
  &--failed {
    color: rgb(77, 99, 116);
  }

  &--successful {
    color: $rootkit__color-red;
  }
}

.attack-action-status {
  &--failed {
    color: rgb(77, 99, 116);
  }

  &--successful {
    color: $rootkit__color-red;
  }
}
