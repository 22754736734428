.org-action-bar-label {
  display: flex;
  align-items: center;

  svg {
    fill: $rootkit__color-grey--darker;
    stroke: $rootkit__color-grey--darker;
  }

  h3 {
    margin: 0 0 0 0.5rem;
    color: $rootkit__color-grey--darker;
    font-weight: 300;
    font-size: 16px;
  }
}

.org {
  @include entity;
}

.org-expanded {
  padding-top: 24px;
  border-top: 1px solid $rootkit__color-blue--lighter;

  .json-tree {
    margin: 24px;
  }
}

.org-id {
  cursor: pointer;
  color: var(--rootkit__palette--gray500);

  &:hover {
    color: var(--rootkit__palette--gray700);
  }
}

.create-org-modal,
.disable-org-modal,
.re-enable-org-modal {
  padding: 2rem 0 0;

  .modal-close {
    right: 1rem;
  }

  .create-org-header,
  .disable-org-header,
  .re-enable-org-header {
    display: flex;
    align-items: center;
    padding: 2rem;
    padding-top: 0;
    border-bottom: 1px solid $rootkit__color-blue--lighter;

    h2 {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }

  .create-org-footer,
  .disable-org-footer,
  .re-enable-org-footer {
    display: flex;
    padding: 2rem;
    border-top: 1px solid $rootkit__color-blue--lighter;

    .create-org-form.submit,
    .disable-org-form.submit,
    .re-enable-org-form.submit {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      .create-org-submit,
      .disable-org-submit,
      .re-enable-org-submit {
        align-self: flex-end;
        margin-left: 10px;
      }
    }
  }

  .create-org-body,
  .disable-org-body,
  .re-enable-org-body {
    padding: 2rem;

    .org-form-divider {
      width: 100%;
      height: 2px;
      border-top: 1px solid $rootkit__color-blue--lighter;
      margin-top: 2rem;
    }

    .create-org-form {
      display: flex;
      flex-direction: column;
    }
  }
}

.create-org-form-row {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .create-org-form-field {
    width: 48%;

    &.full {
      width: 100%;
    }
  }
}

// edit-org
// ---------------------------------------------------------------------------

.edit-org {
  padding: 0.5rem;

  .icon {
    margin: 0;
  }
}

.org-action-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  margin: 0 24px 9px;
  padding: 12px 24px;
  background-color: $rootkit__color-grey--lighter;
}

.org-action-bar-actions {
  > button {
    margin-left: 0.5rem;
  }
}

// edit-org-info
// ---------------------------------------------------------------------------

.edit-org-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  // margin: 2rem 0;
  // padding: 1rem;

  // background: rgba(237, 239, 241, 0.5);
  // border-bottom: 1px solid var(--rootkit__palette--gray400);
  // border-bottom-right-radius: 3px;
  // border-bottom-left-radius: 3px;
}

.edit-org-info-section {
  margin-bottom: 1.5rem;

  &--name {
    width: 48%;
  }

  &--contact {
    width: 48%;
  }

  &--address {
    width: 48%;
  }
}
