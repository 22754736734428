.filter-pane-title {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 5px !important;
  }
}

.dropdown-new-actions {
  justify-content: flex-end;
}

.entity-filter {
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }

  .input-radio {
    label::after {
      top: initial;
      left: 3px;
    }
  }

  .icon svg {
    fill: none;
  }
}

.entity-filter-trigger {
  .icon-filter svg {
    fill: none;
  }
}

.entity-filter-no-value {
  border-color: var(--rootkit__palette--red600);
  color: var(--rootkit__palette--red600);

  .icon {
    svg {
      stroke: var(--rootkit__palette--red600);
    }
  }

  .icon.entity-filter-no-value__icon {
    svg {
      padding-top: 2px;
      stroke-width: 2;
    }
  }
}

.filter-caret {
  .icon {
    margin-right: 0;
  }
}

.dropdown--is-open {
  .entity-filter-no-value {
    border-color: var(--rootkit__palette--gray300);
    color: var(--rootkit__palette--gray500);

    .icon {
      svg {
        stroke: var(--rootkit__palette--gray500);
      }
    }

    .icon.entity-filter-no-value__icon {
      svg {
        padding-top: 2px;
        stroke-width: 2;
      }
    }
  }
}

.edit-saved-view-pane {
  height: 90%;

  .base-block {
    overflow-y: auto;
  }

  .block-controls.base-block {
    height: 85%;
  }

  .block-controls {
    .icon-trash {
      margin: 0;
    }

    .filter-caret {
      margin-left: 20px;

      .icon {
        margin-right: 0;
      }
    }
  }

  .matching_entity__operators-label,
  .matching_entity__operators-section {
    margin-top: 0.25rem;
  }

  .rule-block {
    z-index: initial;
  }

  .filter-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    border-top: 1px solid $rootkit__color-blue--lighter;

    .button-group {
      width: inherit;

      > .btn-save {
        margin-right: 0;
      }
    }

    .input-checkbox {
      font-size: 12px;
      color: $rootkit__color-grey;
    }
  }

  // prevent filter overflow
  .button {
    max-width: 400px;
  }

  .button-children {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.filter-display {
  display: flex;
  align-items: center;
  padding: 0 1.25rem 0.625rem 1.875rem;

  .filter-display-text-label {
    // font-size: 16px;
    color: $rootkit__color-grey;
    margin: 0 10px 0 0;
  }
}

.filter-display-text {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $rootkit__color-grey;
  cursor: pointer;

  // font-size: 14px;

  &:hover {
    opacity: 0.75;
  }

  .condition {
    color: $rootkit__color-red;
    margin: 0 5px;
  }

  .rule-group-parens {
    margin: 0 5px;
  }

  &.read-only {
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
}

.filter-tabs {
  display: flex;
  padding: 1rem;

  .filter-tab {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }

    &--is-active {
      border-color: var(--rootkit__palette--gray100);
      background-color: var(--rootkit__palette--gray100);
    }
  }
}

.filter-origination-ips,
.filter-origination-hosts {
  .dropdown-new-option {
    .input-label {
      font-size: 10px;
      font-style: italic;
      margin-left: 10px;
    }
  }
}

.filter-nested-group {
  margin-left: 28px;
  max-width: 170px;
}

.filter-score-nested-range-group {
  display: flex;
  flex-direction: row;
}

.filter-heading {
  text-transform: uppercase;
  font-family: var(--rootkit__font--heading);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 1px;
  color: var(--rootkit__palette--gray500);
}

.filter-panel-no-hover {
  font-family: var(--rootkit__font--heading);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}

.filter-divider {
  margin-top: 20px;
  margin-bottom: 20px;
  opacity: 0.3;
  border-bottom: 1px solid var(--rootkit__palette--gray400);
}

.filter-score-between-and {
  font-family: var(--rootkit__font--heading);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 1px;
  color: var(--rootkit__palette--gray500);
  text-transform: uppercase;
  margin-left: 10px;
  margin-top: 12px;
}

.filter-no-case {
  .button,
  .span {
    text-transform: capitalize;
  }
}

.filter-array-tags__input-dropdown {
  .dropdown-new,
  .dropdown-new-content {
    width: 100%;
  }
}

.filter-array-tags__dropdown {
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow: hidden;
}

.filter-array-tags__dropdown-options {
  overflow-y: auto;
}

.entity-filter__dropdown-content {
  min-width: 20rem;
}
