.entity-table-title-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}

.entity-table-title {
  display: flex;
  justify-content: space-between;
  margin: 0;

  .info-popper {
    .icon-info {
      svg path {
        fill: $rootkit__color-blue--dark;
      }
    }
  }
}

.entity-table-view-name {
  display: flex;
  align-items: flex-end;
  margin: 0;
  margin-left: 1rem;
  font-weight: 400;
}

.entity-table-title-total {
  margin-left: 2rem;
  font-weight: 300;
  opacity: 0.5;
}
