.redirector-status {
  // &--retired {
  // }

  // &--gone-dark {
  // }

  // &--delayed {
  // }

  &--active,
  &--checking-in {
    color: var(--rootkit__palette--green600);
  }

  // &--self-destructed {
  // }
}
