.validated-vulnerabilities__label {
  display: flex;
  align-items: center;
  color: var(--rootkit__palette--gray500);
  margin-bottom: 1rem;
}

.validated-vulnerabilities__info {
  color: var(--rootkit__palette--gray700);
}

.validated-vulnerabilities__info:not(:last-child) {
  margin-right: 0.5rem;
}

.validated-vulnerabilities__info-factor {
  path {
    fill: $rootkit__color-orange;
  }
}

.validated-vulnerabilities__info-content-count {
  color: $rootkit__color-orange;
}

.validated-vulnerabilities__info-content {
  .external-link {
    @include r-link;

    display: block;
    margin-top: 0.5rem;
  }
}

.vulnerability__label {
  display: block;
  margin: 0;
  margin-right: 0.5rem;
  font-weight: 700;
  color: var(--rootkit__palette--gray500);
}
