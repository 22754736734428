.stats-summary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  grid-gap: map-get($spacer-map, 2); // can I use??
  width: 100%;
  min-height: 200px;
  margin-top: map-get($spacer-map, 3);
  margin-bottom: map-get($spacer-map, 3);

  .stats-pie-chart {
    grid-column: auto / span 1;
  }

  .stats-blocks {
    grid-column: auto / span 6;
    display: flex;
    flex-direction: column;

    .blocks-container {
      width: 100%;
      height: 100%;
      display: flex;
      margin-top: map-get($spacer-map, 2);

      .block-title {
        h2 {
          padding: 0;
          margin: 0;
        }
      }

      .block {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        border: 1px solid $rootkit__color-grey--lighter;
        background-color: var(--rootkit__palette--white);

        &.high-block {
          border-top: 7px solid $rootkit__color-red;
        }

        &.medium-block {
          border-top: 7px solid $rootkit__color-orange;
        }

        &.low-block {
          border-top: 7px solid $rootkit__color-grey--light;
        }

        &:not(:first-child) {
          border-left: 0;
        }

        .prio-stat {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h2 {
            font-weight: normal;
            font-size: 36px;
            margin: 0.5rem;
          }

          .percentage {
            background-color: var(--rootkit__palette--gray100);
            border: 1px solid $rootkit__color-grey--lighter;
            border-radius: 4px;
            padding: map-get($spacer-map, 0) map-get($spacer-map, 1);
          }
        }

        .label {
          margin: 0 0 1rem;
        }
      }
    }
  }
}
