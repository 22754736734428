.priority-factor-container {
  font-size: 8px;
  line-height: 8px;
  text-align: center;
  align-self: center;
  margin: 0 0px;

  p {
    margin: 0;
    margin-left: auto;
    @include rootkit__typography--caption;
  }
}

.priority-tool-tip-value {
  display: flex;
  margin: 0;
  font-size: 8px;
  line-height: 10px;
  align-items: center;
  text-align: center;
  padding: 5px 15px;
  background: var(--rootkit__palette--gray100);
  justify-content: center;
  border-bottom: 1px solid var(--rootkit__palette--gray300);
}

.priority-factor-value {
  grid-column: 1/3;
  color: var(--rootkit__palette--black);
  padding-right: 5px;
  padding-left: 10px;
  text-transform: capitalize;
  align-content: center;
  max-width: 120px;
}

.priority-factor-value-container {
  display: grid;
  grid-template-columns: 1fr 10px max-content;
  align-items: center;
  padding: 3px;
  margin: 0 auto;

  &.no-arrow {
    grid-template-columns: 1fr auto 1fr;

    .priority-factor-value {
      color: $rootkit__color-grey--light;
      padding-right: 0;
    }
  }
}
