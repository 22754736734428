/*********************************************************************
 * © Copyright IBM Corp. 2022
 * Copyright © 2022 Randori https://randori.com - All Rights Reserved.
 *********************************************************************/

.applicable-targets-list-table,
.applicable-targets-list-table--has-authorization {
  margin-top: 0.625rem;

  .is-sortable:hover {
    cursor: pointer;
  }

  .is-sortable .icon {
    margin-left: 0.5rem;
  }

  .is-sortable svg {
    fill: var(--rootkit__palette--gray300);
    stroke: var(--rootkit__palette--gray300);
  }
}

.applicable-targets-list-table {
  .rootkit__table__cell:nth-child(1) {
    min-width: var(--min-cell-width);
    text-align: center;
  }
}

.applicable-targets-list-table--has-authorization {
  .rootkit__table__cell:nth-child(2) {
    min-width: var(--min-cell-width);
    text-align: center;
  }
}
