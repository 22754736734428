.u-unstyled-button {
  border: none;
}

.full-size-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: '100%';
}
