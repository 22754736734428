.collapse-icon {
  float: right;
  width: 3px;
  padding-left: 3px;

  button {
    background: var(--rootkit__palette--gray300);
    border-width: 0;
    height: 58px;
    width: 16px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    outline: none;
    position: absolute;
    top: 0;
  }

  span {
    vertical-align: middle;
  }

  svg {
    fill: var(--rootkit__palette--gray400);
    stroke: var(--rootkit__palette--gray400);
    transform: rotate(90deg);
  }
}

.collapse-icon-collapsed {
  position: absolute;
  z-index: 100;

  button {
    background: var(--rootkit__palette--gray300);
    border-width: 0;
    height: 58px;
    width: 16px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    outline: none;
    position: fixed;
    top: 0;
  }

  span {
    vertical-align: middle;
  }

  svg {
    fill: var(--rootkit__palette--gray400);
    stroke: var(--rootkit__palette--gray400);
    transform: rotate(-90deg);
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  z-index: $z-midground;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.55);
  background: $rootkit__color-grey--dark;
  color: var(--rootkit__palette--white);
  overflow: initial;

  a {
    @include r-link(var(--rootkit__palette--white), false);
  }

  h1,
  h2 {
    font-weight: 400;
  }

  .loader {
    svg {
      fill: rgba(255, 255, 255, 0.4);
      stroke: rgba(255, 255, 255, 0.4);
    }
  }
}

.navigation-section {
  margin-top: 10px;
}

.navigation-section--fill {
  flex: auto;
  overflow-y: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.navigation-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--rootkit__palette--white);
}

.navigation-menu-item--disabled,
.navigation-submenu-item--disabled {
  color: rgba(255, 255, 255, 0.2);

  .icon {
    svg {
      fill: rgba(255, 255, 255, 0.2);
      stroke: rgba(255, 255, 255, 0.2);
    }
  }
}

.navigation-submenu {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  color: var(--rootkit__palette--white);
}

.navigation-submenu-item {
  font-family: $rootkit__font--heading;
  letter-spacing: 0.2px;

  > a {
    opacity: 0.5;
  }

  &:hover {
    > a {
      opacity: 0.6;
    }

    background: rgba($rootkit__color-grey--darker, 0.25);
  }

  &.is-active {
    > a {
      opacity: 1;
    }

    &:hover {
      a {
        opacity: 1;
      }
    }
  }
}

.navigation-menu-item {
  font-family: $rootkit__font--heading;
  letter-spacing: 0.2px;
  cursor: pointer;
  padding: 0;
  margin-bottom: 12px;
  border-radius: 0;

  &.is-child-active {
    background: $rootkit__color-grey--darker;
    cursor: initial;
  }
}

.navigation-menu-item--slideout {
  position: relative;
}

.notifications-slideout-trigger {
  &--is-selected {
    &::before {
      width: 0;
      height: 0;
      border: 10px solid transparent;
      position: absolute;
    }
  }
}

.navigation-submenu-item--disabled {
  color: rgba(255, 255, 255, 0.2);

  &:hover {
    color: rgba(255, 255, 255, 0.2);
  }
}

.navigation-menu-item-inner {
  display: flex;
  align-items: center;
  height: 2.5rem;
  justify-content: flex-start;
  padding: 0;
  border-radius: 0;
  opacity: 0.5;
  background: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  &:hover {
    opacity: 0.6;
    background: rgba($rootkit__color-grey--darker, 0.4);
  }

  &.active {
    opacity: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    &:hover {
      opacity: 1;
    }
  }

  .is-child-active & {
    opacity: 1;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &:hover {
      opacity: 1;
    }
  }
}

.navigation-menu-item-label {
  display: inline-block;
  margin-left: 16px;
  margin-right: auto;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation-menu-item-label--child {
  display: inline-block;
  margin-left: 16px;
  margin-right: auto;
  font-size: 12px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation-menu-item-kind {
  display: inline-block;
  margin-right: 14px;
  padding-right: 2px;
  font-size: 10px;
  font-style: italic;
  white-space: nowrap;
}

.navigation-submenu-item-inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.5rem;

  .navigation-menu-item-label {
    font-size: 12px;
  }
}

.navigation-menu-item-icon {
  &.collapse,
  &.expand {
    margin-left: 10px;
    margin-right: 16px;
  }

  &.expand {
    transform-origin: center;
  }

  &.icon-recon {
    svg {
      stroke: none;
    }
  }
}

.navigation-total-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 20px;
  margin-right: 16px;
  color: rgba(255, 255, 255, 1);

  p {
    margin: 0;
  }
}

.navigation-menu-item-label--nested {
  display: inline-block;
  margin-left: 10px;
  margin-right: auto;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: initial;
}

.navigation-submenu--nested {
  @include rootkit__list-reset;
}

.navigation-submenu--nested-inner {
  display: block;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  list-style: none;
  color: var(--rootkit__palette--white);
}

.navigation-menu--primary {
  padding: 12px 0;
  text-transform: uppercase;
}

.navigation-submenu-item--child {
  text-transform: capitalize;
}

.navigation-menu--secondary {
  margin-bottom: 10px;
}

.navigation-header {
  display: flex;
  align-items: center;
  padding: 0 1rem;

  .navigation-logo {
    max-width: 50px;
    max-height: 50px;
  }

  .navigation-recon {
    position: absolute;
    font-size: 20px;
    margin: 0 0 0 20px;
  }
}

.navigation-menu-item--saved-views {
  .navigation-submenu-item--child {
    text-transform: initial;
  }
}

.navigation-search {
  margin: 15px 15px 0;

  input {
    font-size: 0.875rem;
    background-color: var(--rootkit__palette--gray700);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgb(211, 211, 211);
  }

  input::placeholder {
    color: var(--rootkit__palette--gray500);
    opacity: 0.7;
  }

  g > path {
    opacity: 0.5;
    stroke: var(--rootkit__palette--gray500);
    fill: var(--rootkit__palette--gray500);
  }
}

.navigation-menu-item-icon--hide {
  display: none;
}

.hidden-nav {
  width: 0;
}
