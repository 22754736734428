.attack-surface-info {
  .attack-surface-description {
    margin: 0;
    padding-top: 24px;
  }

  .target-age-desc {
    margin: map-get($spacer-map, 4) 0;
  }

  .active-entities-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    margin-bottom: map-get($spacer-map, 4);

    .active-entity-stat {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--rootkit__palette--gray200);
      padding: map-get($spacer-map, 2) 0;
      background-color: var(--rootkit__palette--white);

      .active-entity {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
