@use '@carbon/react/scss/colors';

.entity-detail__artifacts-container--detection {
  margin-bottom: 1rem;

  .entity-detail__artifacts__buttons {
    margin-top: 1rem;
    width: min-content;
  }

  .entity-detail__artifacts__details {
    margin: 0;
  }

  .entity-detail__artifacts__item {
    overflow: auto;
    height: 100%;

    &.entity-detail__artifacts__item--background {
      background: var(--rootkit__palette--gray100);
    }

    pre {
      white-space: pre-wrap;
      font-size: 0.75rem;
      line-height: 1.5rem;
      padding: 10px 16px;
      margin: 0;
    }

    img {
      width: 100%;
    }
  }

  .target-artifact-image {
    border: 1px solid colors.$gray-20;
  }
}
