.slideout {
  width: 0;
}

.detections-slideout {
  .slideout-body {
    overflow-y: hidden;
  }
}

.slideout-header {
  display: none;

  h2 {
    font-weight: bold;
  }

  .slideout-close-trigger {
    cursor: pointer;
    margin: auto 0 auto auto;
    width: 15px;
    height: 15px;

    &::before,
    &::after {
      position: absolute;
      content: ' ';
      height: 15px;
      width: 2px;
      background-color: $rootkit__color-grey--darker;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.slideout--is-open {
  display: flex;
  flex-flow: column;
  background: var(--rootkit__palette--white);
  bottom: 0;
  box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1),
    inset -1px 0 0 0 var(--rootkit__palette--gray300);
  height: 100%;
  position: absolute;
  top: 0;
  width: 400px;
  z-index: $z-midground;

  @include desktopSm {
    z-index: $z-foreground + 4;
  }

  .slideout-header {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;

    span {
      margin: auto;
      margin-right: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .slideout-header--secondary {
    width: 100%;
  }

  .slideout-pager {
    .pager-list {
      justify-content: space-around;
    }
  }

  .slideout-body {
    height: 100%;
    padding-top: 20px;
    overflow-y: auto;
  }

  &.filter-sort-pane {
    width: 600px;
    overflow-y: hidden;

    .slideout-title {
      margin-bottom: 0;
    }

    .slideout-body {
      overflow-y: hidden;
    }
  }

  a {
    @include r-link($rootkit__color-grey--darker, true);
  }
}

.slideout--right.slideout--is-open {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  min-width: 600px;
  max-width: 800px;
  height: 100vh;
  z-index: $z-slideout;
  background: var(--rootkit__palette--white);
  box-shadow: 0 4px 8px 0 rgba(12, 0, 51, 0.1),
    inset -1px 0 0 0 $rootkit__color-blue--lighter;
  border-left: solid 1px $rootkit__color-blue--lighter;
  overflow: hidden;

  > .slideout-header {
    padding: 15px 15px 0;

    .slideout-header-main,
    .slideout-header-row {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      padding-left: 15px;
      width: 100%;
    }
  }

  > .slideout-body {
    padding: 0 15px;
  }

  .collapsible-list {
    border: none;
  }

  &.entity-table-pane {
    width: 600px;
    overflow-y: hidden;

    .slideout-header {
      padding: 30px 15px 0;

      // border-bottom: 0;
    }

    .slideout-title {
      margin-bottom: 0;
    }

    .slideout-body {
      margin-top: 30px;
      padding: 0 0 15px !important;
      overflow-y: hidden;

      .pane-title-row {
        position: absolute;
        top: 10px;
        width: 540px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 15px 15px;

        .saved-views-dropdown-title {
          display: inline-block;
          margin-right: 10px;
        }

        .dropdown-new-content--is-open {
          right: 0;
        }
      }
    }
  }
}
