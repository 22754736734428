.r-popper {
  @include rootkit__popper-tooltip;

  max-width: 400px;

  a {
    @include r-link;
  }

  p {
    @include rootkit__typography--caption;
  }
}

.r-popper--small {
  @include rootkit__popper-tooltip--small;
}

.info-popper {
  display: inline-flex;
}

.info-popper-trigger {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.info-tooltip {
  @include rootkit__popper-tooltip;

  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 0;
  }
}

.info-popper-tooltip {
  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 0;
  }
}

.render-popper-tooltip {
  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 0;
  }
}

.render-effect-popper-tooltip {
  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 0;
  }
}

.text-overflow-popper-trigger {
}

.text-overflow-popper-tooltip {
  max-width: initial;
}

.path-tooltip {
  max-width: initial;
}
