.corner-ribbon {
  // parent should be overflow hidden
  width: 200px;
  background-color: $rootkit__color-grey--lighter;
  position: relative;
  top: 10px;
  left: -60px;
  text-align: center;
  line-height: 50px;
  transform: rotate(-45deg);
}
