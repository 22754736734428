.edit-saved-view-pane {
  .pane-tabs {
    margin-bottom: 15px;
  }

  .entity-sorts-stacked {
    padding-bottom: 100px;
  }

  .dropdown-content--is-open {
    // position: unset;
    // margin-bottom: 100px;
  }
}

.edit-saved-view-header {
  margin-top: 32px;

  .input-label,
  .edit-saved-view-subtitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $rootkit__color-grey--darker;
    margin: 14px 0 3px;
  }

  .edit-saved-view-subtitle {
    margin-top: 30px;
  }

  input {
    font-size: 14px;
    line-height: 24px;
  }
}

.edit-saved-view-content {
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 23px;
}

.edit-saved-view-title {
  position: absolute;
  top: 20px;
  width: 540px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--rootkit__palette--white);
  padding-bottom: 5px;
}

.edit-view-dropdown {
  display: flex;
  align-items: center;

  button {
    width: 200px;
  }

  .button-flex {
    justify-content: space-between;
  }
}

.edit-saved-views-dropdown-title {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: $rootkit__color-grey;
  margin: 0 5px 0 0;
}

.edit-saved-view-footer {
  .default-checkbox {
    width: 200px;
    font-size: 14px;
    line-height: 28px;
    color: $rootkit__color-grey;
  }

  .btn-clear {
    margin-right: 8px;
  }
}

.global-checkbox-container {
  border-top: 1px solid $rootkit__color-grey--lighter;
  border-bottom: 1px solid $rootkit__color-grey--lighter;
  padding: 27px 0;
  margin: 30px 0 20px;

  .global-checkbox {
    width: 200px;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: $rootkit__color-grey--darker;
  }
}

.saved-view-helper-text {
  color: $rootkit__color-grey;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 5px 0 30px;
}

.saved-view-error-text-placement {
  margin-left: 0;
  position: absolute;
  top: 53px;
}

.edit-saved-view-header .saved-view-error-text-placement {
  top: 150px;
}

.nvd-filter-tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  transform: translateY(-3px);
}

.nvd-info-text {
  @include rootkit__typography--caption;
  color: var(--rootkit__palette--gray400);
  mix-blend-mode: normal;

  .external-link::after {
    background-size: 5px 5px;
    transform: scale(2.5);
    left: 10px;
    top: -4px;
  }
}

.inline-fts-and {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
}

.inline-fts-and-heading {
  color: $rootkit__color-red;
}

.modal-slideout--edit-saved-view {
  .modal-slideout__header__inner {
    padding-bottom: 0;
    border-bottom: none;
  }
}
