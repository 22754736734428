.service-action-bar-label {
  display: flex;
  align-items: center;

  svg {
    fill: $rootkit__color-grey--darker;
    stroke: $rootkit__color-grey--darker;
  }

  h3 {
    margin: 0 0 0 0.5rem;
    color: $rootkit__color-grey--darker;
    font-weight: 300;
    font-size: 16px;
  }
}

.services-headers .accordion-card-cell {
  min-width: 0;
  padding-right: 0;
}

.service {
  @include entity;

  .accordion-card-cell {
    min-width: 0;
    white-space: normal;
    word-wrap: break-word;
  }

  .accordion-card--nested {
    .accordion-card-primary {
      padding: 0 12px;
    }
  }

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
    }
  }

  .service-version {
    color: $rootkit__color-grey--light;
    margin-left: 0.5rem;
    font-weight: 400;
  }
}

.service-info {
  @include entity-info;
}

.service-info-item {
  @include entity-info-item;

  .shodan {
    text-decoration: none !important;
    font-weight: 700;

    &::after {
      width: 10px;
      background-size: 10px 10px;
      background-position-y: 0;
    }
  }
}

.service-tags {
  .service-info-item {
    @include entity-info-item(2.5);
  }
}

.service-info-item--desc {
  width: 40%;
}

.service-info-item-label {
  @include entity-info-item-label;
}

.service-info-card {
  &-label {
    // margin-left: 1rem;
  }
}

.service-temptation-card {
  &-label {
    // margin-left: 1rem;
  }

  .pill {
    margin-left: 1rem;
  }
}

.service-temptation-details {
  display: flex;
  justify-content: space-between;
}

.service-temptation-section {
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  margin-right: 24px;
  border-right: 1px solid var(--rootkit__palette--gray200);

  &:last-child {
    border-right: none;
  }
}

.service-temptation-section--temptation {
  padding: 0 24px;
}

.service-temptation-section--notes {
  padding: 0 24px;
}

.service-temptation-section--nvd {
  .target-context-header {
    margin: 0;
    line-height: 24px;
  }
}

.service-temptation-section-title {
  margin: 0 0 24px;
  font-size: 14px;
  font-weight: 700;
}

// edit-service
// ---------------------------------------------------------------------------

.edit-service {
  padding: 0.5rem;

  .icon {
    margin: 0;
  }
}

.service-action-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  margin: 0 24px 9px;
  padding: 12px 24px;
  background-color: $rootkit__color-grey--lighter;
}

.service-action-bar-actions {
  > button {
    margin-left: 0.5rem;
  }
}

// edit-service-info
// ---------------------------------------------------------------------------

.edit-service-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.edit-service-info-section {
  margin-bottom: 1.5rem;

  &--name {
    width: 30%;
  }

  &--vendor {
    width: 30%;
  }

  &--version {
    width: 30%;
  }

  &--description {
    width: 100%;
  }

  &--reference {
    margin-bottom: 2rem;
    width: 100%;
  }

  &--attack-note {
    margin-bottom: 2rem;
    width: 100%;
  }

  &--cpe {
    margin-bottom: 2rem;
    width: 100%;
  }
}

// edit-service-temptation
// ---------------------------------------------------------------------------

.edit-service-temptation {
  display: flex;
  justify-content: space-between;
}

.edit-service-temptation-section {
  &--temptation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 49%;
  }

  &--notes {
    width: 49%;
  }
}

.edit-service-temptation-field {
  width: 48%;
}

.edit-service-temptation-textarea {
  width: 100%;
  height: 100%;

  .textarea {
    height: 100%;
  }

  textarea {
    height: 100%;
    resize: none;
  }
}
