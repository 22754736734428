.tpl-copy-paste {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background: var(--rootkit__palette--white);

  &:hover {
    cursor: pointer;
  }

  .icon {
    margin-right: 0.5rem;
  }
}

.tpl-form {
  margin: 0 2px;
}

.tpl-field {
  margin-bottom: 1rem;

  .input-label {
    font-weight: 700;
  }

  .input {
  }
}

.paste-service-actions {
  display: flex;
  justify-content: flex-end;

  > .button {
    margin-left: 0.75rem;
  }
}

.tpl-form-actions {
  .tpl-form-action {
    margin-bottom: 1rem;
  }

  .action-find {
    width: 100%;

    .button {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }

  .action-clear {
    width: 100%;

    .button-flex {
      justify-content: space-around;
    }
  }
}

.paste-service-modal {
  .label-group {
    height: 1.5rem;
    display: flex;
    align-items: center;
  }

  .icon {
    margin-right: 0.5rem;
  }
}

.copy-paste-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tpl-paste-form-errors {
  padding: 10px;
  background-color: var(--rootkit__palette--red100);
  border: 1px solid var(--rootkit__palette--red400);
  color: $rootkit__color-red--dark;
  border-radius: 5px;
  margin: 1rem auto;

  ul {
    @include rootkit__list-reset;
  }

  li {
    font-weight: 200;
    list-style: none;
    padding-bottom: 0.7rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
