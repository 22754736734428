$randori-no-impact: var(--rootkit__palette--gray200);
$randori-low-impact: var(--rootkit__palette--gray400);
$randori-medium-impact: var(--rootkit__palette--orange600);
$randori-high-impact: var(--rootkit__palette--red600);

$rootkit__color-light-green: var(--rootkit__palette--green100);
$rootkit__color-light-red: var(--rootkit__palette--red100);
$rootkit__color-black: var(--rootkit__palette--gray700);

// ---------------------------------------------------------------------------

$z-background: 1;
$z-midground: 100;
$z-slideout: 999;
$z-foreground: 1001;
$z-overlay: 5000;

// ---------------------------------------------------------------------------

$spacer: 16px;
$spacer-map: (
  0: calc($spacer / 4),
  1: calc($spacer / 2),
  2: calc($spacer),
  3: calc($spacer * 2),
  4: calc($spacer * 3),
  5: calc($spacer * 4),
);
