.hdyft {
  margin-top: 1rem;
}

.hdyft-list {
  @include rootkit__list-reset;
}

.hdyft-list-item {
  position: relative;

  &::after {
    display: list-item;
    content: '';
    height: 20px;
    margin: 4px 0 4px 16px;
    border-left: 3px dotted var(--rootkit__palette--gray300);
  }
}

.hdyft-list-item:last-child::after {
  display: none;
}

.hdyft-crumb-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border: 1px solid $rootkit__color-grey--lighter;
  border-radius: 3px;
}

.hdyft-crumb {
  &--grey {
    .hdyft-crumb-inner {
      // border-color: adjust-color($rootkit__color-grey,  $alpha: -0.80);
      border-color: $rootkit__color-grey;
    }
  }

  &--red {
    .hdyft-crumb-inner {
      // border-color: adjust-color($rootkit__color-red, $alpha: -0.80);
      border-color: $rootkit__color-red;
    }
  }

  &--orange {
    .hdyft-crumb-inner {
      // border-color: adjust-color($rootkit__color-orange, $alpha: -0.80);
      border-color: $rootkit__color-orange;
    }
  }

  &--crimson {
    .hdyft-crumb-inner {
      // border-color: adjust-color($rootkit__color-red, $alpha: -0.80);
      border-color: $rootkit__color-red--dark;
    }
  }
}

.hdyft-crumb-title-wrapper {
  // display: inline-block;
  display: flex;
  align-items: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .hdyft-crumb-title {
    display: inline-block;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon {
    margin-left: 4px;
    margin-top: 6px;
  }
}

.hdyft-crumb-links {
  display: inline-block;
  white-space: nowrap;

  a {
    cursor: pointer;
  }
}

.hdyft-trigger-disabled {
  display: flex;
  align-items: center;

  .hdyft-trigger-label,
  .hdyft-trigger-icon {
    opacity: 0.25;
  }

  .hdyft-count-trigger {
    display: flex;
    align-items: center;
  }

  .info-tooltip {
    opacity: 1;
  }
}

.hdyft-trigger {
  display: flex;
  align-items: center;

  .hdyft-trigger-label {
    margin-left: 0.5em;
    transition: opacity 0.25s ease-in-out;
  }

  svg {
    transition: opacity 0.25s ease-in-out;
  }

  &:hover {
    cursor: pointer;

    .hdyft-trigger-label {
      opacity: 0.5;
    }

    svg {
      opacity: 0.5;
    }
  }
}

.hdyft-helptext {
  font-size: 12px;
  color: $rootkit__color-grey;
  line-height: 1.5;
}

// wip

.discovery-path-trigger {
  padding: 0.5rem;

  .icon,
  .icon:first-child,
  .icon:last-child {
    margin: 0;
  }

  .icon svg {
    fill: none;
  }
}

.recon-target-card-actions {
  .hdyft-trigger {
    color: $rootkit__color-grey--lighter;
    text-decoration: underline;

    svg {
      fill: $rootkit__color-grey--lighter;
    }

    &:hover {
      .hdyft-trigger-label {
        opacity: 1;
        fill: $rootkit__color-grey--lighter;
      }

      svg {
        opacity: 1;
        fill: $rootkit__color-grey--lighter;
      }
    }
  }
}

.r-popper--foreground {
  z-index: $z-foreground;
}
