.status-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.status-info:not(:last-child) {
  margin-right: 0.5rem;
}

.status-info-factor--needs-resolution,
.status-info-factor--needs-review {
  path {
    fill: $rootkit__color-orange;
  }
}

.status-info-factor--needs-investigation {
  path {
    fill: $rootkit__color-red;
  }
}

.status-info-factor--accepted,
.status-info-factor--mitigated {
  transform: rotate(-180deg);

  path {
    fill: $rootkit__color-grey;
  }
}

.status-info-content-status--needs-investigation {
  color: $rootkit__color-red;
}

.status-info-content-status--needs-resolution,
.status-info-content-status--needs-review {
  color: $rootkit__color-orange;
}

.status-info-content-status--none,
.status-info-content-status--accepted,
.status-info-content-status--mitigated {
  color: $rootkit__color-grey;
}

.status-dropdown-trigger {
  line-height: initial;
}

.status-dropdown-content {
  padding: 16px;
}

.status-dropdown-trigger .button-children {
  display: flex;
  align-items: center;

  .icon-spinner {
    width: 16px !important;
    height: 16px !important;
  }
}

.status-dropdown-trigger-label {
  height: 16px;
}

.status-options {
  @include rootkit__list-reset;
}

.status-option {
  padding: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &.is-active {
    background-color: $rootkit__color-grey--lighter;
  }
}

.status-option-indicator {
  display: inline-block;
  margin-right: 5px;
}

.status-options-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.status-options-actions > button:not(:last-child) {
  margin-right: 0.5rem;
}

.status-option--accepted {
  svg {
    fill: $rootkit__color-grey;
  }
}

.status-option-label {
  display: inline-block;
}
