.artifact-detail-slideout {
  width: 500px;
}

.artifact-detail-container {
  border-radius: 2px;
  border: solid 1px $rootkit__color-blue--lighter;
  background-color: var(--rootkit__palette--white);
  color: var(--rootkit__palette--gray500);
  padding: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  width: 100%;

  > pre {
    font-size: 12px;
    overflow: auto;
  }
}

.artifact-detail-container-screenshot {
  background-color: inherit;
  cursor: pointer;
  border: none;
  padding: 20px 0;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.detections-slideout-body {
  max-height: 90%;
  padding-top: 20px;
  overflow-y: auto;
}

.target-artifact-image {
  max-width: 100%;
  max-height: 100%;
}
