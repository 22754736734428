.hoc-summary-stats {
  display: flex;
  padding: 20px 0 10px;
  color: var(--rootkit__palette--gray400);
  border-top: 1px solid $rootkit__color-grey;
}

.hoc-summary-stat {
  flex: 0 1 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border: 1px solid $rootkit__color-grey;
  text-align: center;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.hoc-summary-stat-label {
  margin: 0;
  @include rootkit__typography--body2;
}

.hoc-summary-stat-data {
  font: $rootkit__font--heading;
  font-size: 40px;
  line-height: 54px;
}
