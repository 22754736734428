.workato-header {
  padding: 0 1.25rem 0.625rem 1.875rem;

  .entity-summary-leader-col {
    margin: 0.625rem 0;
  }

  p {
    white-space: nowrap;
  }
}

.l-page.integrations {
  .entity-summary-leader--head {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

    .entity-summary-leader-col {
      .connection-filter {
        width: 50%;
        float: right;

        svg {
          fill: $rootkit__color-grey--light;
          stroke: $rootkit__color-grey--light;
        }
      }
    }
  }
}

.l-page.recipes {
  min-width: 850px;

  .entity-summary-leader--head {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

    .entity-summary-leader-col {
      .recipe-filter {
        width: 50%;
        float: right;

        svg {
          fill: $rootkit__color-grey--light;
          stroke: $rootkit__color-grey--light;
        }
      }
    }
  }
}

.workato-no-access {
  width: 100%;
  height: 100%;
  padding: 48px;
  background-color: var(--rootkit__palette--white);

  .no-access-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-image: url('../static/images/svg-out/workato-splash-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--rootkit__palette--gray100);

    .external-link {
      color: $rootkit__color-red;

      &::after {
        display: none;
      }
    }
  }
}

.workato-marketplace {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-gap: 24px;
  padding: 24px;
  background-color: var(--rootkit__palette--gray100);
}

.entity-summary-container {
  .workato-marketplace {
    width: 100%;
    min-height: 80%;
    margin-top: 36px;
  }
}

.tile--marketplace {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--rootkit__palette--white);
  height: 350px;
  padding: 24px;
}

.connection-title--marketplace {
  font-size: 24px;
  text-align: center;
}

.connection-logo--marketplace {
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connection-description--markteplace {
  display: flex;
  align-items: center;

  span {
    margin: 0;
    text-align: center;
  }
}

.cta__button {
  margin-top: auto;
  text-align: center;
  width: 100%;
}
.cta__button .button-flex {
  justify-content: center;
}

.workato-disclaimer {
  width: 100%;
  text-align: right;
  color: $rootkit__color-grey;
}

.embedded-connection--marketplace {
  position: relative;
  top: -25px;
  left: -20px;
  width: 50vw;

  h2 {
    margin: 0;
    padding: 0 20px 15px;
    width: 100%;
  }

  div {
    width: calc(100% + 40px);
    border-bottom: 1px solid $rootkit__color-grey--lighter;
  }

  iframe {
    width: 100%;
    height: 60vh;
    border: none;
    margin-left: 20px;
  }
}

.authorization--recipe {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
}

.authorization-status--marketplace {
  height: 40px;

  .authorization-status {
    color: var(--rootkit__palette--green600);
    text-align: center;
  }
}

.workato-recipes {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.workato-recipe {
  display: flex;
  min-width: 800px;
  margin-bottom: 24px;
  background-color: var(--rootkit__palette--gray100);
  border: 1px solid var(--rootkit__palette--gray200);
  border-radius: 3px;
}

.workato-recipe > * {
  padding: 24px;
}

.recipe-title-row {
  display: flex;
}

.workato-recipe--header {
  display: flex;
  flex: 1;
  padding: 12px;
  border-right: 2px solid var(--rootkit__palette--gray200);
}

.recipe-image {
  flex: 1;
}

.recipe-status {
  .recipe-detail-item {
    border: none;
  }
}

.recipe-name--recipe {
  margin: 0;
  padding: 0;
}

.recipe-logo--recipe,
.recipe-logo--placeholder {
  width: 100px;
  height: auto;
  align-self: baseline;
}

.recipe-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  // @TODO: update color to new variables
  border-left: solid 1px var(--rootkit__palette--gray200);

  p {
    margin: 0;
  }
}

.authorization-details--recipe {
  display: flex;
  flex: 2;
  margin: 0;
  line-height: 18px;
  max-height: 42px;
  justify-content: flex-start;

  .recipe-detail-item {
    display: flex;
    margin-top: 24px;
    white-space: nowrap;
    color: $rootkit__color-grey--light;

    &:not(:last-child)::after {
      content: '    |   ';
      white-space: pre;
    }
  }

  &.recipe-details-inactive {
    .recipe-detail-item {
      opacity: 60%;
      font-style: italic;
    }
  }

  .recipe-detail-label {
    flex: 1;
    margin: 0;
    font-weight: bold;
  }

  .recipe-detail-value {
    flex: 1;
    margin: 0;
    display: flex;

    &::before {
      content: ' ';
      white-space: pre;
    }
  }
}

.recipe-active {
  color: $rootkit__color-green;
  font-weight: bold;
}

.recipe-inactive {
  color: $rootkit__color-red;
  font-weight: bold;

  &::after {
    content: '  ';
    white-space: pre;
  }
}

.workato-connections {
  width: 100%;
  min-height: 80%;
  margin-top: 36px;
}

.workato-connections-list {
  @include rootkit__list-reset;

  padding: 24px;

  .accordion-card-primary {
    min-height: 80px;
  }
}

.workato-connections-list-item {
  .accordion-card {
    min-width: 800px;
    background-color: var(--rootkit__palette--gray100);
    padding: 0 12px;
  }

  .accordion-card-cell:first-child {
    flex: 0 1 100px;
  }

  .accordion-card-cell:last-child {
    flex: 0;
  }
}

.connection-logo {
  position: relative;

  > img {
    max-height: 50px;
    max-width: 80px;
  }
}

.connection-name-container {
  min-width: 0;
  white-space: normal;
  word-wrap: break-word;
}

.connection-name {
  font-weight: 700;
  font-size: 16px;
}

.connection-setup {
  color: var(--rootkit__palette--gray500); // new color
}

.connection-setup-label {
  font-weight: 700;
}

.connection-recipe-count {
  color: var(--rootkit__palette--gray500); // new color
}

.workato-header-filter {
  max-width: 300px;
  margin-left: auto;

  input {
    border: 1px solid var(--rootkit__palette--gray400);
  }
}

.connections-empty-state {
  max-width: 500px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  p {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}

.marketplace__connection-name {
  text-align: center;
  margin-bottom: 1rem;
}
