.network {
  @include entity;

  .accordion-card-cell {
    min-width: 0;
    white-space: normal;
    word-wrap: break-word;
  }

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
    }
  }

  .network-version {
    color: $rootkit__color-grey--light;
    margin-left: 0.5rem;
    font-weight: 400;
  }

  .network-thumbnail {
    width: 82px;
    border: 1px solid rgba($rootkit__color-grey--light, 0.5);
  }

  .empty-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    background-color: var(--rootkit__palette--gray200);

    .empty-image-message {
      font-size: 0.5rem;
    }
  }

  .external-link {
    flex-wrap: nowrap;
  }

  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 0;
  }
}

.network-info {
  @include entity-info;
}

.network-info-item {
  @include entity-info-item;
}

.network-tags {
  .network-info-item {
    @include entity-info-item(2.5);
  }
}

.network-info--desc,
.network-info--notes {
  .network-info-item {
    @include entity-info-item(1);
  }
}

.network-info-item-label {
  @include entity-info-item-label;
}

.network-artifact-trigger {
  transition: opacity 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.network-info-item-contents {
  width: min-content;
}
