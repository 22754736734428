@use '@randori/rootkit/src/styles/mixins/link';
@use '@randori/rootkit/src/styles/mixins/list';
@use '@randori/rootkit/src/styles/mixins/typography';
@use '@randori/rootkit/src/styles/variables';

.settings-container {
  margin-top: 5rem;

  @include ff-grid-hax;

  grid-column: content;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 20px [primary-start] auto [primary-end] 20px;
}

.settings-nav {
  content: '';
}

.settings-content {
  grid-column: primary;
}

.team-panel,
.api-tokens-panel,
.panel--missed-affiliation {
  margin: 5rem 60px 0;

  @include desktopXL {
    margin: 5rem 220px 0;
  }
}

// ---------------------------------------------------------------------------

.settings-nav-header {
  margin-top: 0;
}

.settings-nav-list {
  list-style: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.settings-nav-list-item {
  > a {
    @include link.rootkit__link-reset;

    display: flex;
    align-items: center;
    padding-left: 15px;
    height: 40px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 2px;
  }

  &.is-selected,
  &:hover {
    background-color: variables.$rootkit__color-grey--light;
    cursor: pointer;
    color: variables.$rootkit__color-grey--darker;
  }
}

.team-panel-box {
  border: 1px solid variables.$rootkit__color-blue--lighter;
  border-radius: 3px;
}

.domain-list,
.user-list {
  @extend .box-list;

  justify-content: space-between;

  .button.u-unstyled-button {
    color: variables.$rootkit__color-red;
  }
}

.domain-list-item,
.user-list-item {
  @extend .box-list-item;

  padding: 1.5rem 0;
}

.user-list-item--current-user {
  background: variables.$rootkit__color-grey--lighter;
}

.domain-list-header,
.user-list-header {
  padding: 1.5rem 0;
}

.domain-list-content,
.user-list-content {
  flex: 0 1 auto;
  flex-basis: 40%;
  margin-right: 0.5rem;
  width: 30%;

  &:first-child {
    min-width: 3.5rem;
    margin-left: 1rem;
  }

  &:last-child {
    margin-right: 1rem;
  }
}

.user-locked-container {
  .lock-reason,
  .lock-expiry {
    font-size: 12px;
    margin: 0;
    font-style: italic;
  }

  .lock-reason {
    margin-top: 10px;
  }
}

.user-actions-list-container {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;

  .dropdown-content {
    right: 0;
  }
}

.user-list-content--is-editing {
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-basis: 80%;
  flex-direction: column;
}

.user-actions-list .dropdown-content {
  min-width: fit-content;
}

.user-actions-help-text {
  font-style: italic;
}

.user-actions-list-item {
  white-space: nowrap;
}

.user-roles {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.user-roles-list {
  @include list.rootkit__list-reset;
}

.user-roles-select-list {
  @include list.rootkit__list-reset;

  margin-bottom: 5px;
}

.user-roles-select-list-item {
  .input-checkbox {
    margin-bottom: 10px;
  }
}

.overflow-managed {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-reset-password {
  .modal-content {
    .input {
      margin-bottom: 10px;
    }
  }
}

.settings-info__modal-content {
  min-width: 300px;

  .error {
    margin-top: 0.5rem;
    color: variables.$rootkit__color_red;
  }
}

.edit-permissions__list-item {
  label {
    margin: 16px;
  }

  p {
    margin: 8px 0 16px 32px;
  }
}

.settings-content-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px variables.$rootkit__color-blue--lighter;
  min-height: 80px;
  padding: 0 40px;
  width: 100%;

  > label {
    font-weight: bold;
    width: 30%;
    margin: 0;
  }

  &--stacked-input {
    align-items: baseline;

    > label {
      margin-top: 30px;
    }
  }

  .form-item {
    margin-top: 20px;
    width: 60%;

    &--stacked-input {
      margin: 20px 0;
    }

    &--sub-label-container {
      display: flex;
      flex-flow: column;
      width: 30%;

      > label {
        font-weight: bold;
      }

      > span {
        font-size: 12px;
        opacity: 0.7;
        margin-top: 5px;
      }
    }

    .form-item--inline-label {
      display: flex;
      align-items: center;

      > .input {
        width: 80%;
        border-color: green;
      }
    }

    .error {
      align-self: flex-end;
      color: variables.$rootkit__color-red;
      margin: 0;
      min-height: 20px;
      width: 100%;
    }
  }
}

.team-users {
  .user-filter {
    margin-bottom: 10px;
    width: 50%;
  }
}

.team-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-reset-password,
.user-reset-otp {
  .modal-dialog {
    width: initial;
    height: initial;
    min-width: 666px;
    overflow-y: initial;
  }
}

.settings-confirm-password {
  .settings-content-item {
    display: block;
    padding: 0;
    border: 0;
  }

  .form-item {
    margin-top: 0;
  }

  .button {
    margin-top: 1rem;
  }
}

.security-label {
  @include typography.rootkit__typography--overline;

  padding-bottom: 4px;
  text-align: left;
  color: var(--rootkit__palette--gray400);
  font-size: small;

  &--required-field::after {
    content: '*';
    color: red;
    margin-left: 4px;
  }
}

.security-panel {
  margin: 2rem 30px 0;
  max-width: 400px;

  .button-group {
    width: auto;
    float: right;

    .button {
      margin-left: 0.5rem;
      margin-right: 0rem;
    }
  }
}

.cookie-info-panel {
  padding: 2rem;

  .rootkit__link {
    @include link.rootkit__link-reset;
  }
}

.settings-table {
  margin: auto;
  margin-top: 16px;
  /* 2.75rem comes from 1.375rem on either side of header and description */
  width: calc(100% - 2.75rem);
}

/* align edit button with right edge of table */
.settings-table__edit-button {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.settings-table__cell--bold {
  font-weight: 700;
}

.settings-info__input--error {
  color: var(--rootkit__palette--red600);
}

.settings-info__input {
  margin-bottom: 1.5rem;
}

.settings-info__select {
  margin-top: 0.25rem;
}

.settings-section__subtitle,
.settings-table__pager {
  margin-left: 22px;
  margin-right: 22px;
}

.settings-section__subtitle {
  margin-top: 5rem;
}

.edit-permissions__subtitle {
  margin-top: 0;
}

.settings-table__action-list {
  margin: 16px;
  padding: 0;
}

.settings-table__action-item,
.edit-permissions__list {
  @include list.rootkit__list-reset;
}

.settings-table__action-item {
  padding: 8px 16px;
  color: var(--rootkit__palette--gray600);

  &:hover {
    cursor: pointer;
    background-color: var(--rootkit__palette--gray100);
  }
}

.account-utilization {
  background-color: var(--rootkit__palette--gray100);
  padding-bottom: 20px;
  padding-top: 5px;
  margin-top: 30px;
}

.account-utilization-content {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 10px;
}

.account-utilization-text {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  max-width: 1000px;
  padding-left: 1.375rem;
  padding-right: 1.375rem;
}

.account-utilization-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  text-align: left;
}

.account-utilization-header {
  padding-top: 15px;
  padding-bottom: 15px;
}

.account-utilization-divider {
  border-bottom: 2px solid var(--rootkit__palette--gray100);
}

.account-utilization-warning {
  padding-left: 10px;
  padding-top: 12px;
}

.entitlement-container {
  background-color: white;
}

@import './pane-api/api-tokens.styles';
@import './pane-org-info/org-info.styles';
@import './pane-team/user-invite-form/user-invite-form.styles';
