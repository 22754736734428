.panel-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.org-info-panel {
  margin: 5rem 60px 0;
}

.org-info-form {
  border: 1px solid $rootkit__color-blue--lighter;
  border-radius: 3px;
}

.org-info-form-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  border-bottom: 1px solid $rootkit__color-blue--lighter;

  label {
    font-weight: 700;
  }
}

.org-info-form-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;

  > .button:first-child {
    margin-right: 0.5rem;
  }
}

.org-info-options {
  min-width: 300px;

  .error {
    margin-top: 0.5rem;
    color: $rootkit__color_red;
  }
}
