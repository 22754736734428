@use 'sass:list';

.cells__generic {
  color: var(--rootkit__palette--gray700);
}

.cells__generic--string-list {
  white-space: nowrap;
}

.cells__date-time {
  white-space: nowrap;
}

.cells__detection-criteria {
  .external-link {
    display: inline-flex;
    color: var(--rootkit__palette--gray700);
    align-items: center;

    &:link,
    &:visited {
      color: var(--rootkit__palette--gray700);
    }

    &:hover {
      text-decoration: underline;
    }

    &::after {
      background-position-y: 0;
      background-size: 10px 10px;
      width: 10px;
    }
  }

  a {
    display: block;
  }
}

.cells__entity-name {
  .entity-name {
    white-space: nowrap;
    line-height: 1.75rem;
  }

  .entity-name:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .entity-id-subline {
    cursor: pointer;
    color: var(--rootkit__palette--gray500);

    &:hover {
      color: var(--rootkit__palette--gray700);
    }
  }
}

.cells__entity-name--runbook {
  display: flex;
  flex-direction: column;

  .cells__entity-name--subtitle {
    color: var(--rootkit__palette--gray500);
  }
}

.cells__names-subtitle {
  color: var(--rootkit__palette--gray500);
  text-transform: capitalize;

  .cells__names-subtitle--no-transform {
    text-transform: none;
  }
}

.rootkit__description-list__desc {
  .cells__names-subtitle {
    color: inherit;
  }

  a .cells__names-subtitle .rootkit__typography--overline {
    color: var(--rootkit__palette--gray700);
  }
}

.cells__objective-status {
  line-height: 1.75rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.cells__objective-status-text {
  display: flex;
}

.cells__objective-status-icon {
  display: flex;
  margin-right: 0.375rem;
}

.cells__objective-status-icon--success,
.cells__version-match-icon--success {
  fill: var(--rootkit__palette--green500);
}

.cells__objective-status-icon--failure,
.cells__version-match-icon--failure {
  fill: var(--rootkit__palette--red500);
}

.cells__objective-status-icon--unknown {
  fill: var(--rootkit__palette--yellow500);
}

.cells__results {
  line-height: 1.75rem;

  .cells__results__list {
    @include rootkit__list-reset;
  }
}

.cells__results--has-results {
  cursor: pointer;
  text-decoration: underline;
}

.cells__authorization-policy-name {
  cursor: pointer;
  color: var(--rootkit__palette--gray500);

  &:hover {
    color: var(--rootkit__palette--gray700);
  }
}

.cells__authorization-manual-name {
  cursor: pointer;
}

.cells__discovered_service__names {
  display: inline-flex;
  white-space: nowrap;

  .discovered_service__list {
    @include rootkit__list-reset;
  }

  .discovered_service__link {
    @include r-link();
  }
}

.cells__uuid {
  &:hover {
    cursor: pointer;
  }
}

.cells__source-status--online {
  color: var(--rootkit__palette--green600);
}

.cells__source-status--deleted,
.cells__source-status--error,
.cells__source-status--offline {
  color: var(--rootkit__palette--red600);
}

.cells__asset--source {
  display: block;
}

.source-name--link {
  @include r-link();
}

@mixin arrayCell($container, $cell) {
  .#{$container} {
    display: inline-flex;
    white-space: nowrap;

    .#{$cell}__list {
      @include rootkit__list-reset;
    }

    .#{$cell}__link {
      @include r-link();
    }

    .cells__uuid {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 15rem;
    }
  }
}

$array-cells: 'discovered_service', 'consolidated_target';
@each $cell in $array-cells {
  @include arrayCell('cells__#{$cell}__names', $cell);
  @include arrayCell('cells__#{$cell}__ids', $cell);
  @include arrayCell('popper__#{$cell}__names', $cell);
  @include arrayCell('popper__#{$cell}__ids', $cell);
}
