.peer-stats-container {
  display: flex;
}

.peer-stats-column {
  flex: 1 1 25%;
  overflow: auto;
}

.peer-stats-row {
  display: flex;
}

.peer-stats-column--primary {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.peer-stat-header {
  display: flex;
}

.peer-group-link {
  @include r-link;

  margin-left: auto;
  margin-right: auto;
}

.beta-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  min-width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid var(--rootkit__palette--black);

  &--light {
    color: $rootkit__color-grey--light;
    border-color: var(--rootkit__palette--gray200);
    background: var(--rootkit__palette--white);
  }

  &--dark {
    background-color: var(--rootkit__palette--gray100);
    border: 1px solid var(--rootkit__palette--gray200);
    color: $rootkit__color-grey;
  }
}

// peer-stats-percentage
// ---------------------------------------------------------------------------

.peer-stats-percentage {
  background: $rootkit__color-grey--lighter;
  flex: 0 1 80%;
  height: 14px;
  position: relative;
  z-index: 0;
}

.peer-stats-container--light .peer-stats-percentage {
  background: $rootkit__color-grey--lighter;
}

.peer-stats-container--dark .peer-stats-percentage {
  background: var(--rootkit__palette--white);
}

.peer-stats-container--dark .peer-stats-column--primary .peer-stats-percentage {
  background: $rootkit__color-grey--lighter;
}

.peer-stats-percentage-fill {
  background: $rootkit__color-red;
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.peer-stats-percentage-label {
  color: $rootkit__color-red;
  flex: 1 0 20%;
  font-size: 18px;
  font-weight: 500;
  padding-left: 10%;
}

// peer-stats-cell
// ---------------------------------------------------------------------------

.peer-stats-cell {
  display: flex;
  position: relative;
  flex: 1 1 0px;
  justify-content: center;
  align-items: center;
  height: 100px;
  border: 1px solid var(--rootkit__palette--gray200);
  background: var(--rootkit__palette--white);
}

.peer-stats-column--label .peer-stats-cell {
  padding-left: 20px;
  padding-right: 20px;
  justify-content: flex-start;
}

.peer-stats-cell--empty {
  line-height: 1;
  font-size: 12px;
  color: $rootkit__color-grey--light;
}

.peer-stats-cell .row-label {
  color: $rootkit__color-red;
}

.peer-stats-container--light .peer-stats-cell {
  background: var(--rootkit__palette--white);
}

.peer-stats-container--dark .peer-stats-cell {
  background: rgba(237, 239, 241, 0.5);
}

.peer-stats-column--primary .peer-stats-cell {
  background-color: var(--rootkit__palette--white) !important;
}

.peer-stats-cell:first-child {
  margin-top: 10px;
}

.peer-stats-cell:last-child {
  margin-bottom: 10px;
}

.peer-stats-column--primary .peer-stats-cell:first-child {
  margin-top: 0;
  height: 110px;
}

.peer-stats-column--primary .peer-stats-cell:last-child {
  margin-bottom: 0;
  height: 110px;
}

// peer-stats-cell-contents
// ---------------------------------------------------------------------------

.peer-stats-cell-contents {
  display: flex;
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    line-height: 1rem;
    font-style: italic;
  }
}

.peer-stats-cell--empty .peer-stats-cell-contents {
  text-align: center;
}
