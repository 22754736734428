.glossary-section {
  padding: map-get($spacer-map, 3) 40px;

  h5 {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}
