.entity-table-total {
  display: flex;
  align-items: baseline;

  // margin-right: 1rem;
}

.entity-table-total-count {
  margin: 0;
  margin-right: 3px;
  font-weight: 600;
  white-space: nowrap;
}

.entity-table-total-total {
  margin: 0;
  font-weight: normal;
  opacity: 0.5;
  white-space: nowrap;

  &--empty {
    // visibility: hidden;
    display: none;
  }
}

.entity-table-total-hidden {
  margin: 0;
  margin-left: 1.5rem;
  font-weight: normal;
  opacity: 0.5;
  white-space: nowrap;

  &--empty {
    // visibility: hidden;
    display: none;
  }
}
