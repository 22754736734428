.loader-center {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.page-loader {
  @extend .loader-center;
}

.loader-light {
  svg {
    fill: var(--rootkit__palette--white);
    stroke: var(--rootkit__palette--white);
  }
}
