$cell-colors: (
  'neutral': $rootkit__palette--gray100,
  'grey-light': $rootkit__palette--gray400,
  'light-orange': $rootkit__palette--orange400,
  'mid-orange': $rootkit__palette--orange600,
  'light-red': $rootkit__palette--red600,
  'mid-red': $rootkit__palette--red700,
  'dark-red': $rootkit__palette--red800,
);

$border-styles: (
  'thick': 4px solid var(--rootkit__palette--gray200),
  'thin': 1px solid var(--rootkit__palette--gray200),
);

@mixin cell {
  width: calc(100% / 4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.risk-matrix {
  color: $rootkit__color-grey--darker;
  background: var(--rootkit__palette--white);
  display: grid;
  grid-template-columns: 30px repeat(5, 1fr);
  grid-template-rows: auto;
  grid-auto-rows: minmax(60px, auto);
  grid-template-areas:
    'xx xx xx xx xx xx'
    'yy z  x  x  x  x'
    'yy y  cc cc cc cc'
    'yy y  cc cc cc cc'
    'yy y  cc cc cc cc';
}

.risk-matrix-spacer {
  grid-area: z;
  border-top: map-get($border-styles, 'thin');
  border-left: map-get($border-styles, 'thin');
}

.risk-matrix-row-labels {
  grid-area: y;
  display: flex;
  flex-flow: column;
  border-top: map-get($border-styles, 'thin');

  > div {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    border-bottom: map-get($border-styles, 'thin');
    border-left: map-get($border-styles, 'thin');
  }
}

.risk-matrix-column-labels {
  grid-area: x;
  display: flex;

  > div {
    @include cell;

    border-top: map-get($border-styles, 'thin');
    border-left: map-get($border-styles, 'thin');

    &:last-child {
      border-right: map-get($border-styles, 'thin');
    }
  }
}

.risk-matrix-data {
  grid-area: cc;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
}

.risk-matrix-x-label {
  @include rootkit__typography--overline;

  grid-area: xx;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(237, 239, 241, 0.5);
  line-height: 40px;

  .info-popper {
    margin-left: map-get($spacer-map, 0);
  }
}

.risk-matrix-y-label {
  @include rootkit__typography--overline;

  grid-area: yy;
  display: flex;
  align-items: center;
  justify-content: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
  background-color: rgba(237, 239, 241, 0.5);
  line-height: 40px;

  .info-popper {
    margin-top: map-get($spacer-map, 0);
  }
}

.risk-matrix-row {
  display: flex;
  height: 100%;

  &:first-child {
    border-top: map-get($border-styles, 'thin');
  }
}

.risk-matrix-cell {
  @include cell;
  @include rootkit__typography--h2;

  border-bottom: map-get($border-styles, 'thin');
  border-left: map-get($border-styles, 'thin');

  &:last-child {
    border-right: map-get($border-styles, 'thin');
  }

  // cell colors
  // -------------------------------------------------------------------------
  &.impact--none.tt--low {
    background-color: map-get($cell-colors, 'grey-light');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--none.tt--medium {
    background-color: map-get($cell-colors, 'mid-orange');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--none.tt--high {
    background-color: map-get($cell-colors, 'light-red');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--none.tt--critical {
    background-color: map-get($cell-colors, 'dark-red');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--none.tt--review {
    background-color: var(--rootkit__palette--white);
    border-left: map-get($border-styles, 'thick');

    a {
      @include r-link($rootkit__palette--gray500, false);
    }
  }

  &.impact--low.tt--low {
    background-color: map-get($cell-colors, 'grey-light');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--low.tt--medium {
    background-color: map-get($cell-colors, 'light-orange');

    a {
      @include r-link(var(--rootkit__palette--black), false);
    }
  }

  &.impact--low.tt--high {
    background-color: map-get($cell-colors, 'mid-orange');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--low.tt--critical {
    background-color: map-get($cell-colors, 'light-red');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--low.tt--review {
    background-color: var(--rootkit__palette--white);
    border-left: map-get($border-styles, 'thick');

    a {
      @include r-link($rootkit__palette--gray500, false);
    }
  }

  &.impact--medium.tt--low {
    background-color: map-get($cell-colors, 'light-orange');

    a {
      @include r-link(var(--rootkit__palette--black), false);
    }
  }

  &.impact--medium.tt--medium {
    background-color: map-get($cell-colors, 'mid-orange');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--medium.tt--high {
    background-color: map-get($cell-colors, 'light-red');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--medium.tt--critical {
    background-color: map-get($cell-colors, 'mid-red');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--medium.tt--review {
    background-color: var(--rootkit__palette--white);
    border-left: map-get($border-styles, 'thick');

    a {
      @include r-link($rootkit__palette--gray500, false);
    }
  }

  &.impact--high.tt--low {
    background-color: map-get($cell-colors, 'mid-orange');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--high.tt--medium {
    background-color: map-get($cell-colors, 'light-red');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--high.tt--high {
    background-color: map-get($cell-colors, 'mid-red');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--high.tt--critical {
    background-color: map-get($cell-colors, 'dark-red');

    a {
      @include r-link(var(--rootkit__palette--white), false);
    }
  }

  &.impact--high.tt--review {
    background-color: var(--rootkit__palette--white);
    border-left: map-get($border-styles, 'thick');

    a {
      @include r-link($rootkit__palette--gray500, false);
    }
  }
}

.risk-matrix-legend {
  @include rootkit__typography--caption;

  display: flex;
  margin-left: 24px;
  padding: 12px 0;
}

.risk-matrix-legend-label {
  margin-right: 1rem;
  color: var(--rootkit__palette--gray500);
}

.risk-matrix-legend-values {
  @include rootkit__list-reset;

  display: flex;

  .matrix-low {
    color: $rootkit__color-grey--light;

    &::before {
      background-color: $rootkit__color-grey--light;
    }
  }

  .matrix-medium {
    color: var(--rootkit__palette--orange500);

    &::before {
      background-color: var(--rootkit__palette--orange500);
    }
  }

  .matrix-high {
    color: $rootkit__color-red;

    &::before {
      background-color: $rootkit__color-red;
    }
  }

  > li {
    display: flex;
    margin-right: 1rem;

    &::before {
      content: '';
      margin-right: 0.35rem;
      width: 1rem;
      height: 1rem;
    }
  }
}

.prioritization-matrix-info {
  padding-top: 24px;
  margin-bottom: map-get($spacer-map, 4);

  p {
    margin-bottom: 3rem;
  }

  .stat-header {
    margin-bottom: 0;
  }
}

.risk-matrix--v3 {
  .risk-matrix-data > .risk-matrix-row:last-child {
    border-top: map-get($border-styles, 'thick');
  }

  .risk-matrix-column-labels > div:last-child {
    border-left: map-get($border-styles, 'thick');
  }

  .risk-matrix-row-labels > div:last-child {
    border-top: map-get($border-styles, 'thick');
  }
}
