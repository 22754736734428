.entity-table {
  display: block;
  overflow-x: auto;
}

.entity-table--empty {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: $rootkit__color-grey;
  padding: 20px;

  > div {
    text-align: center;
    flex: 1 1 auto;
  }

  .empty-icon .icon {
    width: 40px !important;
    height: 40px;
  }

  .empty-icon svg {
    stroke: var(--rootkit__palette--gray400);
    fill: var(--rootkit__palette--gray400);
  }
}

.entity-table-row {
  height: 4rem;

  &::after {
    content: '';
    display: table-cell;
    width: 100%;
  }

  .table-cell > span {
    height: 3rem;
    width: 100%;

    .pill {
      // font-size: 10px;
    }
  }

  .entity-table-ownership-col {
    display: flex;
    align-items: center;
    width: 100px;

    .icon {
      margin-right: 5px;
    }
  }

  .entity-table-cell-info-popper-trigger,
  .entity-table-cell-info-popper-trigger-no-data {
    height: 100%;
    width: 100%;
    text-align: center;
  }

  .entity-table-cell-info-popper-trigger:hover {
    color: $rootkit__color-red;
  }

  .info-tooltip {
    max-width: 300px;
    margin-top: 0.5rem;
    background-color: $rootkit__color-grey--darker;
    color: var(--rootkit__palette--white);

    ul {
      max-width: 100%;
      padding: 0;
    }

    li {
      list-style: none;
      padding-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:last-of-type {
        padding: 0;
      }

      &.additional-results-message {
        font-style: italic;
        margin-top: 5px;
        opacity: 0.7;
      }
    }

    .popper-pointer[data-placement*='bottom']::before {
      border-width: 0 0.7em 0.7em;
      border-color: transparent transparent $rootkit__color-grey--darker;
    }

    .popper-pointer::before {
      position: absolute;
      top: -0.7rem;
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: auto auto auto 0.7rem;
      border-style: solid;
    }
  }

  .input-checkbox {
    position: relative;
  }
}

.input-checkbox-disabled label::before {
  border: 1px solid var(--rootkit__palette--gray200);
}

.entity-table-row--muted {
  background: rgba(196, 196, 196, 0.5);
}

.entity-table-header .table-column-label,
.entity-table-row .table-cell {
  padding: 1rem 20px;
}

.entity-table-header {
  tr {
    &::after {
      content: '';
      display: table-cell;
      width: 100%;
    }
  }

  .table-column-label {
    min-width: 120px;
  }

  .table-column-label > .input-checkbox input {
    transform: scale(1.5);
  }
}

.entity-table-link {
  @include r-link;
}
