$cds-link: var(--rootkit__palette--blue500);

.dashboard-total-widget-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  min-height: 215px;

  a {
    text-decoration: none;
  }
}

.dashboard-total-widget-container > div {
  flex: 1;
}

@media only screen and (max-width: 1500px) {
  .dashboard-total-widget-container.implants-included {
    .implants-total-widget,
    .targets-need-investigation-widget {
      border-bottom: 1px solid var(--rootkit__palette--gray300);
      min-width: 320px;
    }
  }
}

.total-count-widget {
  display: grid;
  grid-template-rows: 55px 80px fit-content(40px);
  text-align: center;
  height: 100%;
  min-width: 150px;
  padding: 20px;
  border-left: 1px solid var(--rootkit__palette--gray200);
  border-top: 1px solid var(--rootkit__palette--gray200);

  :first-child {
    border-left: none;
  }

  &:hover {
    background-color: $rootkit__color-grey--lighter;
  }
}

.total-count-widget--count {
  margin: 0;
  cursor: pointer;
}

.total-count-widget--title {
  height: 56px;
  margin: 6px 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.one-word-per-line {
  display: inline-block;
  max-width: min-content;
}

.total-count-widget--children {
  @include rootkit__typography--caption;

  grid-row: 3/4;
  text-decoration: none;
}

.custom-error-widget {
  display: flex;
  align-items: flex-start;
}

.custom-error-widget-icon svg {
  height: 14px;
}

.targets-need-investigation-widget {
  background-color: $rootkit__color-red;
  color: var(--rootkit__palette--white);
  height: 100%;

  &:hover {
    background-color: rgba(218, 2, 27, 0.9);
  }

  a {
    color: var(--rootkit__palette--white);
    cursor: pointer;
  }

  .total-count-widget--children {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-decoration: underline;
  }
}

.implants-total-widget {
  background-color: var(--rootkit__palette--black);
  color: var(--rootkit__palette--white);

  &:hover {
    background-color: $rootkit__color-grey--darker;
  }

  .total-count-widget--children {
    display: flex;
    justify-content: center;
  }

  a {
    color: var(--rootkit__palette--white);
    cursor: pointer;
  }
}

.unknown-entities-widget {
  a {
    color: $rootkit__color-red;
    cursor: pointer;
  }
}

.top-priority-widget {
  a {
    color: $rootkit__color-red;
    cursor: pointer;
  }
}

.target-links {
  width: 100%;
}

.new-targets-widget {
  color: var(--rootkit__palette--green600);

  a {
    color: var(--rootkit__palette--green600);
  }

  .total-count-widget--children {
    color: $rootkit__color-grey--light;
  }

  .entity-specific-links {
    width: 100%;

    a {
      color: $rootkit__color-grey--darker;
      text-decoration: underline;
      white-space: nowrap;
      padding: 0 1px;
      cursor: pointer;
    }
  }
}

.top-priority-widget,
.unknown-entities-widget {
  color: $rootkit__color-red;

  .total-count-widget--children {
    color: $rootkit__color-grey--darker;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .entity-specific-links {
    width: 100%;

    a {
      color: $rootkit__color-grey--darker;
      text-decoration: underline;
      white-space: nowrap;
      padding: 0 1px;
      cursor: pointer;
    }
  }

  .empty-state {
    a {
      color: $rootkit__color-red;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.carbon-colors {
  .targets-need-investigation-widget {
    background-color: $rootkit__color-red;
    color: var(--rootkit__palette--white);
    height: 100%;

    &:hover {
      background-color: rgba(218, 2, 27, 0.9);
    }

    a {
      cursor: pointer;
    }

    .total-count-widget--children {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      text-decoration: underline;
    }
  }

  .new-targets-widget {
    color: var(--rootkit__palette--green600);

    a {
      color: var(--rootkit__palette--green600);
    }

    .total-count-widget--children {
      color: $rootkit__color-grey--light;
    }

    .entity-specific-links {
      width: 100%;

      a {
        color: $cds-link;
        text-decoration: underline;
        white-space: nowrap;
        padding: 0 1px;
        cursor: pointer;
      }
    }
  }

  .top-priority-widget,
  .unknown-entities-widget {
    color: $cds-link;

    .total-count-widget--children {
      color: var(--rootkit__palette--gray700);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .entity-specific-links {
      width: 100%;

      a {
        color: $cds-link;
        text-decoration: underline;
        white-space: nowrap;
        padding: 0 1px;
        cursor: pointer;
      }
    }

    .empty-state {
      a {
        color: $cds-link;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
