.table-field-edit__item-container {
}

// leaves an open space incase the user removes all items from the droppable container
.table-field-edit__item-container--available > div,
.table-field-edit__item-container--included > div {
  min-height: 45px;
}

.table-field-edit__item {
  background-color: var(--rootkit__palette--white);
  border: 1px solid var(--rootkit__palette--gray300);
  border-radius: 3px;
  margin: 0 20px 12px 20px;
  height: 45px;
  display: flex;
  align-items: center;

  .icon {
    margin: 0 23px;

    svg {
      stroke: var(--rootkit__palette--gray500);
    }
  }

  .button {
    margin-left: auto;
    margin-right: 3px;
    display: flex;
    align-items: center;
  }
}

.table-field-edit__item--included {
  .icon svg {
    stroke: var(--rootkit__palette--gray700);
  }
}

.table-field-edit__item--required {
  background-color: var(--rootkit__palette--gray100);
}

.table-field-edit__item--available {
}

.table-field-edit__label--available {
  color: var(--rootkit__palette--gray500);
}

.table-field-edit__label--required {
  color: var(--rootkit__palette--gray500);
}

.table-field-edit__header {
  border-bottom: 1px solid var(--rootkit__palette--gray200);
  padding-left: 44px;
  margin-bottom: 16px;
  margin-top: 45px;

  &:first-child {
    margin-top: calc(45px - 1.5rem);
  }

  span {
    line-height: 27px;
    margin-right: 0.5rem;
  }
}

.table-field-edit__header--hidden {
  color: var(--rootkit__palette--gray500);
}

.table-field-edit__buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 8px;
  }
}

.entity-table_button--reset {
  margin-left: 0;
  text-decoration: underline;
}
