.tt-renderer {
  &.crimson {
    color: $rootkit__color-red--dark;
  }

  &.grey {
    color: $rootkit__color-grey;
  }

  &.red {
    color: $rootkit__color-red;
  }

  &.orange {
    color: $rootkit__color-orange;
  }
}
