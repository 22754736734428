.growler-container {
  position: absolute;
  top: 10px;
  left: 2%;
  width: 96%;

  .growler {
    text-align: inherit;
  }
}

.r-growler-container {
  .growler-success-container {
    .icon svg {
      stroke: var(--rootkit__palette--white);
    }
  }
}
