.claimed-asset {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.claimed {
    color: $rootkit__color-blue;
  }

  &.disputed {
    color: $rootkit__color-red;
  }

  &.unverified {
    color: $rootkit__color-grey;
  }
}
