.view-guidance-button {
  margin-left: 1rem;

  .icon {
    margin-right: -0.2rem !important;
  }

  svg {
    padding-right: 0;
    margin-top: 4px;
    width: 25px;
    height: 25px;
  }
}

.guidance-pane-container {
  h4 {
    margin-top: 30px;
    margin-bottom: 5px;
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
  }

  ul {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .guidance-pane-list-header {
    margin-bottom: 2px;
  }

  .special-view-tooltip-link.external-link::after {
    content: none;
  }
}

.guidance-pane-header {
  position: absolute;
  top: -10px;
  left: 40px;
  width: 85%;

  svg {
    padding-top: 8px;
  }

  h2 {
    margin-bottom: 0;
  }
}

.guidance-pane-body {
  border-top: 1px solid $rootkit__color-grey--lighter;
  margin: 70px -15px 0;
  padding: 40px;

  p:first-child() {
    font-size: 1.15em;
  }
}

.guidance-pane-additional-resources {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
