.implant {
  @include entity;

  .accordion-card-cell {
    min-width: 0;
    white-space: normal;
    word-wrap: break-word;
  }

  .implant-version {
    color: $rootkit__color-grey--light;
    margin-left: 0.5rem;
    font-weight: 400;
  }
}

.implant-info {
  @include entity-info;
}

.implant-info-item {
  @include entity-info-item;
}

.implant-info-item-label {
  @include entity-info-item-label;
}
