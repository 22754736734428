@use '@carbon/react/scss/theme';
@use '@carbon/styles/scss/components/combo-button';

:root {
  @include theme.theme();
}

// Library CSS
@import '@randori/rootkit';
@import 'klaro/dist/klaro.css';
@import 'react-json-pretty/themes/monikai.css';

// IBM Plex
$font-prefix: '@ibm/plex';
@import '@ibm/plex/scss/ibm-plex.scss';

// Library Variables
@import '@randori/rootkit/src/styles/variables';

// Library Functions
@import '@randori/rootkit/src/styles/functions/color';
@import '@randori/rootkit/src/styles/functions/font-size';
@import '@randori/rootkit/src/styles/functions/str-replace';

// Library Mixins:
@import '@randori/rootkit/src/styles/mixins/button';
@import '@randori/rootkit/src/styles/mixins/clearfix';
@import '@randori/rootkit/src/styles/mixins/color';
@import '@randori/rootkit/src/styles/mixins/drop-shadow';
@import '@randori/rootkit/src/styles/mixins/link';
@import '@randori/rootkit/src/styles/mixins/list';
@import '@randori/rootkit/src/styles/mixins/popper';
@import '@randori/rootkit/src/styles/mixins/round-border';
@import '@randori/rootkit/src/styles/mixins/typography';

// Variables
@import './variables';

// Utilities
@import './utilities/clearfix';
@import './utilities/misc';
@import './utilities/text';

// Global
@import './breakpoints';
@import './elements';
@import './layout';

// Modules
@import './modules/link';
@import './modules/box-list';
@import './modules/corner-ribbon';
@import './modules/dropdown-action-list';
@import './modules/entity-list';
@import './modules/panel';
@import './modules/popper';
@import './modules/loader';

// Components
@import '../components/add-affiliations/add-affiliations.styles';
@import '../components/add-global-setting/add-global-settings.styles';
@import '../components/add-network-pane/add-network.styles.scss';
@import '../components/artifact-link/artifact-link.styles';
@import '../components/artifacts-list/artifacts-list.styles';
@import '../components/attack-graph/attack-graph.styles.scss';
@import '../components/attackers-perspective/attackers-perspective.styles';
@import '../components/bar-status/bar-status.styles';
@import '../components/bars-list/bars-list.styles';
@import '../components/blueprint-status/blueprint-status.styles';
@import '../components/blueprints-list/blueprints-list.styles';
@import '../components/boundary/boundary.styles';
@import '../components/business-context/business-context.styles';
@import '../components/characteristic-rules-list/characteristic-rules-list.styles';
@import '../components/characteristics-graph/characteristics-graph.styles';
@import '../components/characteristics/characteristics.styles';
@import '../components/comment/comment.styles';
@import '../components/count/count.styles';
@import '../components/dashboard-activity/dashboard-activity.styles';
@import '../components/dashboard-categories-tags/dashboard-categories-tags.styles';
@import '../components/dashboard-categories-tags/my-tags/dashboard-my-tags.styles';
@import '../components/dashboard-categories-tags/tech/dashboard-tech.styles';
@import '../components/dashboard-categories-tags/unknown-assets/unknown-assets.styles';
@import '../components/dashboard-favorite-saved-views/dashboard-favorite-saved-views.styles';
@import '../components/dashboard-total-widgets/dashboard-total-widgets.styles.scss';
@import '../components/dc-pill/dc-pill.styles';
@import '../components/edit-table-field-modal/edit-table-field-modal.styles';
@import '../components/entity-conditional-link/entity-conditonal-link.styles';
@import '../components/entity-detail-link/entity-detail-link.styles';
@import '../components/entity-filters/entity-filters.styles';
@import '../components/entity-list/cells/cells.styles';
@import '../components/entity-list/entity-list.styles';
@import '../components/entity-sorts/entity-sorts.styles';
@import '../components/entity-table-actions/entity-table-actions.styles';
@import '../components/entity-table-desc/entity-table-desc.styles';
@import '../components/entity-table-label/entity-table-label.styles';
@import '../components/entity-table-pane/entity-table-pane.styles';
@import '../components/entity-table-total/entity-table-total.styles';
@import '../components/entity-table/entity-table.styles';
@import '../components/external-link/external-link.styles';
@import '../components/form--perspective-field/form--perspective-field.styles';
@import '../components/fts-dropdown/fts-dropdown.styles';
@import '../components/global-navigation/global-navigation.styles';
@import '../components/global-services-list/global-services-list.styles';
@import '../components/growler/growler.styles';
@import '../components/guidance-pane-v2/guidance-pane.styles';
@import '../components/guidance-pane/guidance-pane.styles';
@import '../components/hdyft/hdyft.styles';
@import '../components/hoc-navigation/hoc-navigation.styles';
@import '../components/hoc-service-header/hoc-service-header.styles';
@import '../components/hostnames-list/hostnames-list.styles';
@import '../components/impact-score/impact-score.styles';
@import '../components/implants-list/implants-list.styles';
@import '../components/inline-loader/inline-loader.styles';
@import '../components/inspect-pane/inspect-pane.styles';
@import '../components/ips-list/ips-list.styles';
@import '../components/json-tree/json-tree.styles';
@import '../components/modal/modal.styles';
@import '../components/networks-list/networks-list.styles';
@import '../components/nvd/cve-cpe.styles';
@import '../components/org-list/org-list.styles';
@import '../components/org-navigation/org-navigation--mobile.styles';
@import '../components/org-navigation/org-navigation.styles';
@import '../components/org-switcher/org-switcher.styles';
@import '../components/peer-groups-list/peer-groups.styles.scss';
@import '../components/perspective/perspective.styles';
@import '../components/policy-list/policy-list.styles';
@import '../components/policy/policy-pane.styles';
@import '../components/priority-bar-chart-validated-vuln/priority-bar-chart-validated-vuln.styles';
@import '../components/priority-bar-chart/priority-bar-chart.styles';
@import '../components/priority-factor/priority-factor.styles';
@import '../components/recon-actions-dropdown/recon-actions-dropdown.styles.scss';
@import '../components/redirector-status/redirector-status.styles';
@import '../components/redirectors-list/redirectors-list.styles';
@import '../components/reports-list/reports.styles';
@import '../components/runbook-src-dst/runbook-src-dst.styles';
@import '../components/runbook-status/runbook-status.styles';
@import '../components/runbooks-list/runbooks-list.styles';
@import '../components/saved-views-edit-pane/saved-views-edit-pane.styles';
@import '../components/saved-views-list/saved-views.styles.scss';
@import '../components/saved-views/saved-views-modal.styles.scss';
@import '../components/service-rules-list/service-rules-list.styles';
@import '../components/services-list/services-list.styles';
@import '../components/slideout/slideout.styles';
@import '../components/social-entity-list/social-entity-list.styles';
@import '../components/source/source-pane.styles';
@import '../components/status-pill/status.styles';
@import '../components/status/status.styles';
@import '../components/suggestions-list/suggestions-list.styles';
@import '../components/summary-toggle/summary-toggle.styles';
@import '../components/tabs/tabs.styles';
@import '../components/tags/tag-labels.styles';
@import '../components/tags/tags.styles';
@import '../components/target-count-box/target-count-box.styles';
@import '../components/target-detection-popper/detection-schema.styles';
@import '../components/target-identifier/target-identifier.styles';
@import '../components/target-thumbnail/target-thumbnail.styles';
@import '../components/targets-link/targets-link.styles';
@import '../components/targets-list/targets-artifact.styles';
@import '../components/targets-list/targets-list.styles';
@import '../components/text-overflow/text-overflow.styles';
@import '../components/top-level-detections-list/top-level-detections-list.styles';
@import '../components/top-tags/top-tags.styles';
@import '../components/tpl-copy-paste/tpl-copy-paste.styles';
@import '../components/tt-bar-chart/tt-bar-chart.styles';
@import '../components/tt-info-box/tt-info-box.styles';
@import '../components/tt-list/tt-list.styles';
@import '../components/tt-meta/tt-meta.styles';
@import '../components/tt-renderer/tt-renderer.styles';
@import '../components/unaffiliated-overlay/unaffiliated-overlay.styles';
@import '../components/validated-vulnerabilities/validated-vulnerabilities.styles';
@import '../components/wizard-modal/wizard-modal.styles';

// Pages
@import '../guidance/landing-page.styles';
@import '../pages/auth/auth.styles';
@import '../pages/configuration/configuration.styles';
@import '../pages/dashboard/dashboard.styles';
@import '../pages/entity-detail/activity-configuration/sections/activity-log/activity-log-list-table.styles';
@import '../pages/entity-detail/applicable-activity/applicable-activities-list-table.styles.scss';
@import '../pages/entity-detail/activity-configuration/sections/applicable-targets/applicable-targets-list-table.styles.scss';
@import '../pages/entity-detail/activity-instance/sections/artifacts/artifacts-list.styles';
@import '../pages/entity-detail/activity-instance/sections/assets/assets-list-table.styles';
@import '../pages/entity-detail/activity-instance/sections/details/activity-instance--details.styles';
@import '../pages/entity-detail/entity-detail.styles';
@import '../pages/entity-summary/entity-leader-mitre/entity-leader-mitre.styles'; // move this to components
@import '../pages/entity-summary/entity-summary.styles';
@import '../pages/global-search/global-search.styles.scss';
@import '../pages/implant-detail/implant-detail.styles';
@import '../pages/randori-attack/randori-attack.styles';
@import '../pages/report-detail/report-detail.styles';
@import '../pages/settings/header.styles';
@import '../pages/settings/pane-sso/sso-pane.styles';
@import '../pages/settings/settings.styles';
@import '../pages/workato/workato.styles';

// TODO: clean up and move to components
@import '../pages/workato/recipes/recipe-configuration-flow/recipe-configuration.styles';

// Etc
@import './wip';
@import '../utilities/klaro/klaro.styles';

// Print
@import './print';
