.l-page--configuration {
  @extend %l-page;

  grid-template-columns: 0 [content-start] auto [content-end] 0;

  @include desktopXL {
    grid-template-columns: 0 [content-start] auto [content-end] 0;
  }
}

.configuration-container {
  @include ff-grid-hax;

  grid-column: content;
  display: grid;
  grid-template-columns: 0 [primary-start] auto [primary-end] 0;
}

.configuration-content {
  @extend .settings-content;
}

.configuration-nav {
  @extend .settings-nav;
}

.configuration-nav-header {
  @extend .settings-nav-header;
}

.configuration-nav-list {
  @extend .settings-nav-list;
}

.configuration-nav-list-item {
  @extend .settings-nav-list-item;
}

// ---------------------------------------------------------------------------

.missed-affiliations-form-field {
  flex-grow: 2;
}

.missed-affiliations-form-preamble,
.missed-affiliations-form-enter,
.missed-affiliations-form-upload {
  margin-bottom: 3rem;

  h4 {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.missed-affiliations-form-actions {
  display: flex;
  padding-top: 2rem;
  border-top: 1px solid $rootkit__color-blue--lighter;
}

.missed-affiliations-form-row-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.missed-affiliation-form-field-wrapper {
  display: flex;
  align-items: center;
}

.remove-row {
  svg {
    opacity: 0.25;
  }
}

.panel--missed-affiliation {
  .panel-box {
    padding: 20px;
  }
}
