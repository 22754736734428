.edit-global-service {
  padding: 0.5rem;

  .icon {
    margin: 0;

    &:first-child {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.global-service-action-bar-label {
  display: flex;
  align-items: center;

  svg {
    fill: $rootkit__color-grey--darker;
    stroke: $rootkit__color-grey--darker;
  }

  h3 {
    margin: 0 0 0 0.5rem;
    color: $rootkit__color-grey--darker;
    font-weight: 300;
    font-size: 16px;
  }
}

.global-service {
  @include entity;

  .accordion-card--nested {
    .accordion-card-primary {
      // justify-content: flex-end;
      padding: 0 12px;
    }
  }

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
    }
  }

  .global-service-version {
    color: $rootkit__color-grey--light;
    margin-left: 0.5rem;
    font-weight: 400;
  }

  .global-service-promoted {
    text-transform: capitalize;
  }

  .service-id {
    cursor: pointer;
    color: var(--rootkit__palette--gray500);

    &:hover {
      color: var(--rootkit__palette--gray700);
    }
  }

  .edit-service {
    padding: 0.5rem;

    .icon {
      margin-right: 0 !important;
    }
  }
}

.global-service-info {
  @include entity-info;
}

.global-service-info-item {
  @include entity-info-item(3);

  .shodan {
    text-decoration: none !important;
    font-weight: 700;

    &::after {
      width: 10px;
      background-size: 10px 10px;
      background-position-y: 3px;
    }
  }
}

.global-service-info--cpe {
  .global-service-info-item {
    @include entity-info-item(1);
  }
}

.global-service-info-item--desc {
  width: 40%;
}

.global-service__menu-option {
  display: inline-flex;
  align-items: center;
}

.global-service__menu-option--icon {
  margin-right: 6px;
}

.global-service-info-item-label {
  @include entity-info-item-label;

  &--description-source {
    margin-top: 1.25rem;
  }

  &--end-of-life {
    margin-top: 1.25rem;
  }
}

.global-service-temptation-card {
  .pill {
    margin-left: 1rem;
  }
}

.global-service-temptation-details {
  display: flex;
  justify-content: space-between;
}

.global-service-temptation-section {
  flex: 1;
  margin-right: 12px;
  font-size: 14px;
  line-height: 24px;
}

.global-service-temptation-section--radar {
  border-right: 1px solid $rootkit__color-blue--lighter;
  padding: 0 24px;
}

.global-service-temptation-section-title {
  margin: 0 0 24px;
  @include rootkit__typography--h6;
}

// edit-global-service
// ---------------------------------------------------------------------------

.global-service-action-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 3px;
  margin: 0 24px 9px;
  padding: 12px 24px;
  background-color: $rootkit__color-grey--lighter;
}

.global-service-action-bar-actions {
  > button {
    margin-left: 0.5rem;
  }
}

// edit-global-service-info
// ---------------------------------------------------------------------------

.edit-global-service-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.edit-global-service-info-pre {
  width: 500px;
}

.edit-global-service-info-section {
  margin-bottom: 1.5rem;

  &--name {
    width: 30%;
  }

  &--vendor {
    width: 30%;
  }

  &--version {
    width: 30%;
  }

  &--description {
    width: 100%;
  }

  &--description-source {
    width: 45%;
  }

  &--end-of-life {
    width: 45%;
  }

  &--reference {
    margin-bottom: 2rem;
    width: 100%;
  }

  &--attack-note {
    margin-bottom: 2rem;
    width: 100%;
  }

  &--cpe {
    margin-bottom: 2rem;
    width: 100%;
  }
}

.edit-global-service-select {
  margin-top: 0.25rem;
}

// edit-global-service-temptation
// ---------------------------------------------------------------------------

.edit-global-service-temptation {
  display: flex;
  justify-content: space-between;
}

.edit-global-service-temptation-section {
  &--temptation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 49%;
  }

  &--notes {
    width: 49%;
  }
}

.edit-global-service-temptation-field {
  width: 48%;
}

.edit-global-service-temptation-textarea {
  width: 100%;
  height: 100%;

  .textarea {
    height: 90%;
  }

  textarea {
    height: 100%;
    resize: none;
  }
}

// modal
// ---------------------------------------------------------------------------

.copy-service-modal,
.paste-service-modal {
  .accordion-card {
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-card-meta {
    box-shadow: none;
    border-bottom: none;
  }

  .accordion-card-expand {
    display: none;
  }

  .copy-service-name {
    margin-bottom: 10px;

    h2 {
      margin: 0;
    }

    .service-version {
      font-weight: 400;
      margin-left: 1rem;
    }
  }

  .copy-paste-header {
    margin: 0;
    padding-bottom: 20px;

    .icon {
      margin-right: 0.5rem;
    }
  }

  .copy-paste-leader {
    margin-bottom: 10px;
    padding: 10px 0;
    border-top: 1px solid var(--rootkit__palette--gray200);
    border-bottom: 1px solid var(--rootkit__palette--gray200);
  }

  .copy-service-info-card-label,
  .copy-service-temptation-card-label {
    display: flex;
    align-items: center;
  }

  .input-checkbox {
    display: inline-flex;
    margin-left: 0;
  }

  .input-label {
    display: inline-flex;
  }

  .label-group {
    display: flex;
    align-items: center;
  }
}

.copy-service-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  > .button {
    margin-left: 0.75rem;
  }
}
