.suggestion {
  @include entity;

  .accordion-card-expand {
    .icon svg {
      fill: $rootkit__color-grey--light;
    }
  }

  .suggestion-version {
    color: $rootkit__color-grey--light;
    margin-left: 0.5rem;
    font-weight: 400;
  }

  .suggestion-thumbnail {
    width: 82px;
    border: 1px solid rgba($rootkit__color-grey--light, 0.5);
  }

  .external-link {
    flex-wrap: nowrap;
  }

  .external-link::after {
    background-size: 10px 10px;
    width: 10px;
    background-position-y: 3px;
  }
}

.suggestion-id {
  cursor: pointer;
  color: var(--rootkit__palette--gray500);

  &:hover {
    color: var(--rootkit__palette--gray700);
  }
}

.suggestion-info {
  @include entity-info;

  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.suggestion-info--detection {
  .json-tree {
    margin-right: 2rem;
  }
}

.suggestion-info--orgs {
  @include rootkit__list-reset;
}

.suggestion-info-item {
  @include entity-info-item(2);
}

.suggestion-info-item-label {
  @include entity-info-item-label;
}

// edit-suggestion-info
// ---------------------------------------------------------------------------

.edit-suggestion-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.edit-suggestion-info-section {
  margin-bottom: 1.5rem;

  &--name {
    width: 30%;
  }

  &--vendor {
    width: 30%;
  }

  &--version {
    width: 30%;
  }

  &--description {
    width: 100%;
  }

  &--description-source {
    width: 45%;
  }

  &--end-of-life {
    width: 45%;
  }

  &--reference {
    margin-bottom: 2rem;
    width: 100%;
  }

  &--attack-note {
    margin-bottom: 2rem;
    width: 100%;
  }

  &--cpe {
    margin-bottom: 2rem;
    width: 100%;
  }
}

.edit-suggestion-select {
  margin-top: 0.25rem;
}

// edit-suggestion-temptation
// ---------------------------------------------------------------------------

.edit-suggestion-temptation {
  display: flex;
  justify-content: space-between;
}

.edit-suggestion-temptation-section {
  &--temptation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 49%;
  }

  &--notes {
    width: 49%;
  }
}

.edit-suggestion-temptation-field {
  width: 48%;
}

.edit-suggestion-temptation-textarea {
  width: 100%;
  height: 100%;

  .textarea {
    height: 90%;
  }

  textarea {
    height: 100%;
    resize: none;
  }
}

// modal
// ---------------------------------------------------------------------------

.edit-suggestion-modal {
  overflow: auto !important;

  .accordion-card {
    margin: 0;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-card-meta {
    box-shadow: none;
    border-bottom: none;
  }
}

.promote-suggestion-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  > .button {
    margin-left: 0.75rem;
  }
}
