.tt-info-box-header {
  display: flex;
  align-items: center;
}

.tt-info-box-trigger {
  opacity: 0.5;
  margin-left: 0.5rem;
  margin-bottom: -7px; // @TODO: Fix viewbox of icon
}

.tt-info-box-section {
  margin: 1rem 0;
}

.tt-info-box-section--temptation {
  margin: 10px 0;
}

.tt-info-box-section--bar-chart {
  .tt-graph-container {
    width: 100%;
  }
}

.tt-info-box-section-title {
  margin: 0;
}

.tt-info-box-section-content {
  p {
    margin: 0.5rem 0;
  }
}

.tt-info-box-section-trigger {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  cursor: pointer;
}

.tt-info-box-section-trigger-caret {
  display: flex;
  transform-origin: center;
  transform: rotate(0deg);
  transition: all 0.3s ease 0s;

  .icon {
    margin: auto;
  }

  .tt-info-box-section--is-collapsed & {
    transition: all 0.3s ease 0s;
    transform: rotate(-90deg);
  }
}
