.wizard-modal--header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  padding-top: 0;
  border-bottom: 1px solid $rootkit__color-blue--lighter;
}

.wizard-modal--body {
  display: flex;
  justify-content: center;
  flex: 1;
}

.wizard-modal--footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $rootkit__color-blue--lighter;
  padding: 2rem;

  .wizard-modal--actions {
    display: flex;
    justify-content: space-between;

    .button {
      margin-left: 15px;
    }
  }
}
