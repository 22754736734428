.exec-summary-info {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
}

.exec-summary-counts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  margin: map-get($spacer-map, 2) 0;
  padding: 0 40px;
  padding-bottom: map-get($spacer-map, 3);
  border-bottom: 1px solid var(--rootkit__palette--gray200);
}

.summary-count {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  background-color: var(--rootkit__palette--white);
  border: 1px solid var(--rootkit__palette--gray200);

  .summary-count-value {
    font-size: 24px;
    margin-right: 0.5rem;
  }

  > a {
    display: inline-flex;
    align-items: center;
  }
}

.exec-summary-targets {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-gap: map-get($spacer-map, 2); // can I use??

  .total-targets {
    grid-column: auto / span 1;
  }

  .prioritization-matrix {
    grid-column: auto / span 3;
  }
}

.stat-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: map-get($spacer-map, 4) map-get($spacer-map, 2);
  border: 1px solid var(--rootkit__palette--gray200);

  &.top-targets {
    background-color: var(--rootkit__palette--white);
  }

  &.high-priority {
    background-color: $rootkit__color-red;
    color: var(--rootkit__palette--white);
  }
}

.stat-box-stat {
  display: flex;
  flex-direction: column;
  align-items: center;

  .stat-value-label {
    margin: 0;
  }

  .stat-value {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.no-delta {
      justify-content: center;
    }
  }
}

.exec-summary-entity-counts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: map-get($spacer-map, 2); // can I use??
  margin-top: map-get($spacer-map, 3);

  .active-entities {
    grid-column: auto / span 1;

    .active-entity-counts {
      background-color: var(--rootkit__palette--white);
      border: 1px solid var(--rootkit__palette--gray200);

      .entity-count {
        display: flex;
        justify-content: space-between;
        padding: 0 map-get($spacer-map, 2);

        &:not(:last-child) {
          border-bottom: 1px solid var(--rootkit__palette--gray200);
        }

        .entity-type {
          text-transform: capitalize;
          max-width: calc((100% / 3) * 1.5);
          margin: 1rem 0;
        }

        .entity-stat {
          font-size: 30px;
          display: flex;
          align-items: center;

          .stat-delta {
            font-size: 14px;
            margin-left: map-get($spacer-map, 2);
          }
        }
      }
    }
  }

  .top-characteristics {
    grid-column: auto / span 1;

    .top-characteristics-counts {
      background-color: var(--rootkit__palette--white);
      border: 1px solid var(--rootkit__palette--gray200);

      .characteristics-count {
        display: flex;
        justify-content: space-between;
        padding: 0 map-get($spacer-map, 2);

        &:not(:last-child) {
          border-bottom: 1px solid var(--rootkit__palette--gray200);
        }

        .characteristics-type {
          max-width: calc((100% / 3) * 1.5);
          margin: 1rem 0;
        }

        .characteristics-stat {
          font-size: 30px;
          display: flex;
          align-items: center;

          .stat-delta {
            font-size: 14px;
            margin-left: map-get($spacer-map, 2);
          }
        }
      }
    }
  }
}

.exec-summary-disclaimer {
  margin-top: map-get($spacer-map, 3);
}

.contact-support-link {
  color: $rootkit__color-red !important; // need to rearrange util styles in main
  font-weight: bold;
}
