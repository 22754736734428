// @TODO: update logic to not be based on view port when we move to the pane
.entity-detail__artifacts__item--modal {
  overflow: auto;

  pre {
    margin: 1rem 0;
    padding: 10px 16px;

    background-color: var(--rootkit__palette--gray100);
    font-size: 0.75rem;
    line-height: 1.5rem;
    white-space: pre-wrap;
  }
}

.entity-detail__artifacts-container {
  display: grid;
  grid-template-areas:
    'artifact details'
    'artifact buttons';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 288px 48px;
  grid-gap: 24px;
  margin: 24px 16px 64px;

  &:last-child {
    margin-bottom: 0;
  }

  .entity-detail__artifacts__buttons {
    grid-area: buttons;
    width: min-content;
  }

  .entity-detail__artifacts__details {
    grid-area: details;
    margin: 0;
  }

  .entity-detail__artifacts__item {
    grid-area: artifact;
    overflow: auto;
    height: 100%;

    &.entity-detail__artifacts__item--background {
      background: var(--rootkit__palette--gray100);
    }

    pre {
      white-space: pre-wrap;
      font-size: 0.75rem;
      line-height: 1.5rem;
      padding: 10px 16px;
      margin: 0;
    }

    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1200px) {
    grid-template-areas:
      'artifact'
      'buttons'
      'details';
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0, 288px) 64px 288px;

    &.entity-detail__artifacts-container--screenshot {
      grid-template-rows: auto 64px 288px;
    }
  }
}

.modal-inspect-pane--l-right {
  .entity-detail__artifacts-container {
    @media only screen and (max-width: 1500px) {
      grid-template-areas:
        'artifact'
        'buttons'
        'details';
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, 288px) 64px 288px;

      &.entity-detail__artifacts-container--screenshot {
        grid-template-rows: auto 64px 288px;
      }
    }
  }
}
