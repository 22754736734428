@use '@carbon/react/scss/type';

.dc-schema__heading {
  @include type.type-style('productive-heading-01');

  display: inline-flex;
  white-space: nowrap;
}

.dc-schema__heading__label {
  display: inline-block;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.dc-schema__heading__link {
  display: inline-block;
  margin-left: 0.5rem;

  @include r-link();
}

.dc-schema__subheading {
  @include type.type-style('productive-heading-01');

  display: block;
  white-space: nowrap;
}

.dc-schema__subheading__link {
  @include type.type-style('label-01');
  @include r-link(
    $linkColor: var(--rootkit__palette--black),
    $underline: false
  );
}

.dc-schema__id {
  @include type.type-style('label-01');

  display: block;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
}
