.table-summary-toggle {
  @include rootkit__typography--body1;

  display: flex;
  align-items: center;

  color: var(--rootkit__palette--gray500);
  margin: 1rem 0 0.5rem auto;
  padding: 0 1.25rem 0 1.875rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.table-summary-toggle__icon {
  margin-left: 0.5rem;

  svg {
    fill: var(--rootkit__palette--gray500);
  }

  &--is-expanded {
    transform: rotate(180deg);
  }
}
