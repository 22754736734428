.blueprint-expanded {
  margin: 0 24px 24px;
  padding-top: 24px;
  border-top: 1px solid $rootkit__color-blue--lighter;
}

.blueprint-desc-section-label {
  font-weight: 700;
  color: $rootkit__color-grey;
}

.blueprint-id {
  cursor: pointer;
  color: var(--rootkit__palette--gray500);

  &:hover {
    color: var(--rootkit__palette--gray700);
  }
}

.blueprint-meta {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $rootkit__color-blue--lighter;

  .icon svg {
    fill: $rootkit__color-grey--light;
    stroke: $rootkit__color-grey--light;
  }
}

.blueprint-links-label {
  @extend .blueprint-desc-section-label;

  width: 100%;
}

.blueprint-links {
  display: flex;
  margin-bottom: 12px;

  > div:first-child {
    width: 32px;
    margin-right: 8px;
  }

  > div:last-child {
    display: flex;
    flex-direction: column;
  }
}

.blueprint-desc-section-copy {
  width: 100%;
}

.mitre-techs {
  .mitre-techs-list {
    @include rootkit__list-reset;
  }

  .mitre-techs-list-item {
    display: inline-block;
    margin-right: 1rem;
  }

  .mitre-tech-link {
    color: $rootkit__color-grey;

    &::after {
      margin: 0 0 0 3px;
      background-size: 10px 10px;
      background-position-y: 2px;
      opacity: 0.5;
    }
  }
}

.blueprint {
  .accordion-card-cell {
    overflow-wrap: break-word;
  }

  &--approved {
    border-left: 1px solid $rootkit__color-green;
    background: linear-gradient(
      90deg,
      $rootkit__color-green,
      $rootkit__color-green 2px,
      transparent 2px
    );
  }
}

// origin
// ---------------------------------------------------------------------------

.blueprint-origin {
  display: flex;
}

.blueprint-origin-section {
  padding-right: 32px;
  color: $rootkit__color-grey--light;

  &:last-child {
    padding-right: 0;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.blueprint-label--origin {
  color: var(--rootkit__palette--black);
}

.blueprint-origin-section-label {
  font-weight: 700;
  margin-bottom: 1rem;
}

// actions
// ---------------------------------------------------------------------------

.blueprint-actions {
  justify-content: flex-end;
  width: inherit !important;
  margin: 0 24px 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

// pre
// ---------------------------------------------------------------------------

.blueprint-pre {
  padding: 1rem;
  white-space: pre-wrap;
  border: solid 1px $rootkit__color-blue--lighter;
  border-radius: 3px;
  background: rgba($rootkit__color-grey--lighter, 0.3);
}

// pre
// ---------------------------------------------------------------------------

.blueprint-desc-form {
  margin: 24px;

  .input,
  .textarea {
    margin-bottom: 1em;
  }

  .input-label {
    font-weight: 700;
    color: $rootkit__color-grey;
  }
}

.blueprint-desc-form-errors {
  margin: 1rem auto;
  padding: 10px;
  color: $rootkit__color-red--dark;
  background-color: var(--rootkit__palette--red100);
  border: solid 1px var(--rootkit__palette--red200);
  border-radius: 5px;

  ul {
    padding-inline-start: 0;
    margin: 10px 0;
  }

  li {
    font-weight: 200;
    list-style: none;
    padding-bottom: 1rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
