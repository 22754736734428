.characteristics-graph {
  position: relative;

  .bar-graph {
    --bar-graph-padding: 0;

    padding: 0;
  }
}

.characteristics-graph-tag-label {
  display: flex;
  align-items: center;
  padding: 8px;
}

.characteristics-graph-header {
  display: flex;
  padding-bottom: 1rem;

  > h2 {
    margin: 0;
  }

  .info-popper {
    margin: 0 6px;
  }
}

.characteristics-graph-bars {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
}

.characteristics-graph-axis {
  height: 410px;
}

.characteristics-graph-bars .high-total {
  background-color: $rootkit__color-red;
}

.characteristics-graph-bars .medium-total {
  background-color: $rootkit__color-orange;
}

.characteristics-graph-bars .low-total {
  background-color: $rootkit__color-blue--lighter;
}

.characteristic-tag {
  display: inline-block;

  a {
    color: var(--rootkit__palette--black);
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    margin-right: 0.5rem;
    cursor: pointer;
  }
}

.characteristic-count {
  display: inline-block;
  margin-right: 0.2rem;
  font-size: 16px;
  font-weight: 300;
}

.characteristics-graph-bar-container {
  margin-bottom: 1.5rem;
}

.characteristic-total {
  &:hover {
    cursor: pointer;
  }
}

.empty-characteristics {
  text-align: center;

  p {
    font-size: 21px;
    font-weight: 700;
  }
}

.characteristics-graph-pager {
  .pager-list {
    justify-content: flex-end;
  }
}

ul.characteristics-graph-tabs {
  margin-bottom: 2rem;
  padding-right: 15px;

  > li {
    flex: 2;
    height: 36px;
    overflow: hidden;
    padding: 0 10px;
    background: var(--rootkit__palette--gray100);
    color: $rootkit__color-grey--darker;
    font-size: 14px;
    line-height: 36px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > li[aria-selected='true'] {
    background: var(--rootkit__palette--white);
  }
}

.characteristics-tooltip {
  @include rootkit__typography--tooltip;

  width: max-content;
  max-width: 175px;
  word-wrap: break-word;
}

.characteristics-tooltip-value {
  text-decoration: underline;
}
