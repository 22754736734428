.entity-detail__desc-list--reset {
  overflow: unset;
  white-space: unset;
  font-size: 0.875rem;
}

.entity-detail__top-level-action--no-fill {
  path {
    fill: none;
  }
}

h3.entity-detail__title__id {
  margin: 0;
  margin-bottom: 5px;
}

.entity-detail__title--saved-view {
  span {
    padding-right: 16px;
  }
}
