.panel {
}

.panel-leader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-box {
  border: 1px solid $rootkit__color-blue--lighter;
  border-radius: 3px;
}
